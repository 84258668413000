import { Avatar, Card } from "antd";
import "./UpToDateLinks.css"; // Import CSS file for styling
import Meta from "antd/es/card/Meta";
import "./UpToDateLinks.css";
import { useLoadLinks } from "../../../../Utils/customHooks/useLoadLinks";
import { Link, useLocation } from "react-router-dom";
import React from "react";
import track, {
  TRACKING_TYPES,
} from "../../../../Utils/SupabaseServices/Track";
import MyLinksSkeleton from "../../MyLinksSkeleton";
import { BsLink45Deg } from "react-icons/bs";
const UpToDateLinks = (props: any) => {
  const location = useLocation();
  const { user_id, username, title, brand_color, isHandleNavigate, details } = props;
  let {
    isLoading,

    data,

    refetch,
  } = useLoadLinks(user_id);
  function linkAnalytics(link: any, url: any) {
    track(`my links link click`, TRACKING_TYPES.EVENT, {
      pathname: location.pathname,
      user_id: user_id,
      details: { link: link, url: url },
      title: "Stay Up To Date",
    });
  }

  return (
    <>
      {isLoading ? (
        <div className="creator-card-grid-wrp mt-8">
          <MyLinksSkeleton />
        </div>
      ) : (
        <>
          <div
            className="mainDiv"
          >
            <p
              className="component-name"
            >
              {title}

            </p>
          </div>
          <div className="creator-card-grid-wrp" style={details?.includes("line") ? { gridTemplateColumns: "repeat(1, 1fr)", gridGap: "12px" } : {}}>
            {data &&
              data.length > 0 &&
              data.split(",").map((link: any, index: number) => {
                return (
                  <React.Fragment key={index}>
                    <CardGridItem
                      link={link}
                      username={username}
                      linkAnalytics={linkAnalytics}
                      brand_color={brand_color}
                      isGridView={details?.includes("line") ? false : true}
                    />
                  </React.Fragment>
                )
              })}
          </div>
        </>
      )}
    </>
  );
};

const CardGridItem = ({ link, username, linkAnalytics, brand_color, isGridView }: any) => (
  <a
    href={
      link?.split(":-")[1]?.startsWith("http")
        ? link?.split(":-")[1]
        : `http://${link?.split(":-")[1]}`
    }
    target="_blank"
    onClick={() => linkAnalytics(link?.split(":-")[0], link?.split(":-")[1])}
    className={isGridView ? "card-link" : "card-link card-line"}
  >
    {link?.split(":-")[2] && isGridView ? (
      <>
        <div
          className={isGridView ? "creator-card" : "creator-card-line"}
          style={isGridView ? { backgroundColor: brand_color && brand_color } : {}}
        >
          <div className={isGridView ? "creator-card-cover" : "d-flex"}>
            <img
              className={isGridView ? "creator-card-img" : "creator-card-img-line"}
              style={!isGridView ? { backgroundColor: brand_color && brand_color } : {}}
              alt="example"
              src={link?.split(":-")[2]}
            />

            <div className="creator-card-meta">{link?.split(":-")[0]}</div>
          </div>
        </div>
      </>
    ) : (
      <div
        className="creator-card"
        style={{ backgroundColor: brand_color && brand_color, height: "100%" }}
      >
        {" "}
        <div className="creator-card-meta h-100" style={!isGridView ? { minHeight: "75px" } : {}}>
          {link?.split(":-")[0]}
        </div>
      </div>
    )}
  </a>
);

export default UpToDateLinks;
