import { useMutation, useQueryClient } from "react-query";
import { supabase } from "../../config/supabaseClient";

export default function useUpdateLinkTags() {
  const queryClient = useQueryClient();

  const submitLink = async ({
    tagString,
    user_id,
    pageIndex,
    index,
  }: {
    tagString: string;
    user_id: string;
    pageIndex: number;
    index: number;
  }) => {
    const { error } = await supabase
      .from("user")
      .update({ instagram_links: tagString })
      .eq("user_id", user_id);

    if (error) {
      throw new Error(error.message);
    } else {
      return true;
    }
  };

  return useMutation(submitLink, {
    onMutate: async ({ tagString, user_id }) => {
      await queryClient.cancelQueries(["getInstagramAddedLinks", user_id]);
      const previousData = queryClient.getQueryData([
        "getInstagramAddedLinks",
        user_id,
      ]);

      const modifiedPreviousData = tagString;
      return { previousData: modifiedPreviousData };
    },
    onError: (error, variables, context) => {
      if (context?.previousData) {
        queryClient.setQueryData(
          ["getInstagramAddedLinks", variables.user_id],
          context.previousData
        );
      }
    },
    onSuccess: (data, variables) => {
      queryClient.invalidateQueries([
        "getInstagramAddedLinks",
        variables.user_id,
      ]);
    },
  });
}
