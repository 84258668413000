import React, { useState } from "react";
import "../SubComponents.css";
import track, {
  TRACKING_TYPES,
} from "../../../../../Utils/SupabaseServices/Track";
import { useLocation } from "react-router-dom";
import VideoPostTag from "../../../../../components/GeneralPostTag/VideoPostTag";
import ImagePostCarouselTag from "../../../../../components/GeneralPostTag/ImagePostCarouselTag";
import ImagePostTag from "../../../../../components/GeneralPostTag/ImagePostTag";
import { useLoadQueryFeed } from "../../../../../Utils/customHooks/UpToDateContent/useLoadQueryFeed";
import { StaticVariables } from "../../../../../Utils/StaticVariables";
import { getPostTitle } from "../../../../../Utils/HelperFunction/HelperFunction";
import DataSkeleton from "../../../../../components/StayUpToDateComponent/UpToDateComponents/DataSkeleton";
import { useDomLoaded } from "../../../../../hooks/DomLoadedContext";
const QueryContentWrapper = (props: any) => {
  const {
    user_id,
    username,
    numberOfDays,
    title,
    isLinks,
    // handleShop,
    platform,
    type,
    postIds,
    brand_color,
    query,
    removedPostIds,
  } = props;
  const location = useLocation();
  const domLoaded = useDomLoaded();
  const isShopLinks = false;
  const showSocials = StaticVariables().PLATFORMS;
  const contentOrder = "Default";
  function handleShop(postId: string, media_url: string) {
    track("Redirect to shop link", TRACKING_TYPES.EVENT, {
      pathname: location.pathname,
      details: { post_id: postId, media_url: media_url },
      user_id: user_id,
      title: "Stay Up To Date",
    });
    // updateRefClick(postId, user_id);
  }
  const {
    isLoading,
    data,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage,
    refetch,
  }: any = useLoadQueryFeed(
    username,
    user_id,
    query,

    showSocials,
    contentOrder,
    isShopLinks,
    removedPostIds,
    title,
    
  );
  return (
    <>
      <div>
        {isLoading || !domLoaded ? (
          <div className="outer-container-subcomponents">
            <DataSkeleton dashboard={true}/>{" "}
          </div>
        ) : (
          <div className="outer-container-subcomponents">
            {data && data.length ? (
              <div className="links-grid-container-subcomponents">
                {data &&
                  data.map((link: any, index: any) => {
                    const productTitle = getPostTitle(link, type);
                    return (
                      <React.Fragment key={index}>
                        {link.media_url ? (
                          link.media_url.split(",").length > 1 &&
                          link.media_url
                            .split(",")
                            .every((url: any) => url.trim() !== "") ? (
                            <>
                              <ImagePostCarouselTag
                                post={link}
                                handleRedirect={handleShop}
                                title={title}
                                brand_color={brand_color}
                                isLinks={false}
                                isDashboard={true}
                                type={type}
                                isHasMediaUrl={
                                  link.type === "FEED" ? true : false
                                }
                                productName={type ? productTitle : ""}
                              />
                            </>
                          ) : (
                            <>
                              {link.media_url.includes("video") ? (
                                <>
                                  <VideoPostTag
                                    post={link}
                                    handleRedirect={handleShop}
                                    title={title}
                                    brand_color={brand_color}
                                    isLinks={false}
                                    isDashboard={false}
                                    thumbnail={link.thumbnail}
                                    type={type}
                                    productName={type ? productTitle : ""}
                                    isHasMediaUrl={
                                      link.type === "FEED" ? true : false
                                    }
                                  />
                                </>
                              ) : (
                                <>
                                  <ImagePostTag
                                    data={link}
                                    handleRedirect={handleShop}
                                    title={title}
                                    brand_color={brand_color}
                                    isLinks={false}
                                    isDashboard={false}
                                    type={type}
                                    productName={type ? productTitle : ""}
                                    isHasMediaUrl={
                                      link.type === "FEED" ? true : false
                                    }
                                  />
                                </>
                              )}
                              {/* {link.product_image && link.product_image !== "" ? (
                              <>
                                <ImagePostTag
                                  data={link}
                                  handleRedirect={handleShop}
                                  title={title}
                                  brand_color={brand_color}
                                  isLinks={false}
                                  isDashboard={false}
                                />
                              </>
                            ) : (
                              <>
                                {link.product_image &&
                                link.product_image !== "" ? (
                                  <>
                                    <ImagePostTag
                                      data={link}
                                      handleRedirect={handleShop}
                                      title={title}
                                      brand_color={brand_color}
                                      isLinks={false}
                                      isDashboard={false}
                                    />
                                  </>
                                ) : (
                                  <>
                                    {link.media_url.includes("video") ? (
                                      <>
                                        <VideoPostTag
                                          post={link}
                                          handleRedirect={handleShop}
                                          title={title}
                                          brand_color={brand_color}
                                          isLinks={false}
                                          isDashboard={false}
                                        />
                                      </>
                                    ) : (
                                      <>
                                        <ImagePostTag
                                          data={link}
                                          handleRedirect={handleShop}
                                          title={title}
                                          brand_color={brand_color}
                                          isLinks={false}
                                          isDashboard={false}
                                        />
                                      </>
                                    )}
                                  </>
                                )}
                              </>
                            )} */}
                            </>
                          )
                        ) : (
                          <>
                            <ImagePostTag
                              data={link}
                              handleRedirect={handleShop}
                              title={title}
                              brand_color={brand_color}
                              isLinks={false}
                              isDashboard={true}
                              type={type}
                              productName={type ? productTitle : ""}
                              isHasMediaUrl={
                                link.type === "FEED" ? true : false
                              }
                            />
                          </>
                        )}
                      </React.Fragment>
                    );
                  })}
              </div>
            ) : (
              <div className="outer-container-subcomponents">
                <p>No content at this time</p>
              </div>
            )}
          </div>
        )}
      </div>
    </>
  );
};

export default QueryContentWrapper;
