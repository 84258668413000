import React, { Fragment, useEffect, useState } from "react";
import "../../../../../components/CheckboxComponent/FeedCheckboxComponent.css";
import { useLoadProductBankPages } from "../../../../../Utils/customHooks/UpToDateContent/useLoadProductBankCount";
import { useLoadProductBankContent } from "../../../../../Utils/customHooks/UpToDateContent/useLoadProductBankConent";
import SearchInput from "../../../../../components/SearchInput/SearchInput";
import AllContentTable from "../../../../../components/EditTemplates/AllContentTable";
import AddedContentTable from "../../../../../components/EditTemplates/AddedContentTable";
import { Pagination } from "antd";

const POSTS_PER_PAGE = 10;

const EditProductBankCheckboxComponent = (props: any) => {
  const {
    username,
    user_id,
    isShopLinks,
    componentName,
    post_ids,
    defaultQuery,
    setDefaultQuery,
    defaultAllPosts,
    // refetchCustomProduct,
    setRemovePosts,
    removePosts,
    isChange,
    setIsChange,
    initial,setInitial
  } = props;
  const [allPosts, setAllPosts] = useState([]);
  const [query, setQuery] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [postIds, setPostIds] = useState<any>();

  useEffect(() => {
    // setPostIds(post_ids);
    setAllPosts(defaultAllPosts);
  }, [defaultAllPosts,initial]);




  const { data: pages, refetch: refetchCount } = useLoadProductBankPages(
    user_id || "",
    POSTS_PER_PAGE,
    query,
    username || "",
    "All",
    "AllLinks"
  );
  const { data, isLoading, isError, error, refetch } =
    useLoadProductBankContent(
      user_id || "",
      username || "",
      currentPage,
      POSTS_PER_PAGE,
      pages?.totalPages,
      query,
      "All",
      "AllLinks"
    );

  function handleFeed(postIds: any) {
    setPostIds(postIds);
    props.handlePostIds(postIds);
  }

  useEffect(() => {
    setQuery(defaultQuery);
    if (defaultQuery === "") {
      refetch();
    }
  }, [defaultQuery]);
  return (
    <>
      <div className="margin-added-content">
        <AddedContentTable
          // defaultPosts={defaultAllPosts}
          posts={allPosts}
          username={username}
          user_id={user_id}
          setAllPosts={setAllPosts}
          componentName={componentName}
          handleFeed={handleFeed}
          refetch={refetch}
          setPostIds={setPostIds}
          setRemovePosts={setRemovePosts}
          setIsChange={setIsChange}
          initial={initial}
          setInitial={setInitial}
        />
        <SearchInput
          placeholder="Search your product bank"
          id="search-feed"
          value={query}
          onChange={(e: any) => {
            e.preventDefault();
            const elem: any = document.getElementById("search-feed");
            const searchVal = e.target.value;
            setQuery(e.target.value);
            setDefaultQuery(e.target.value);
            setCurrentPage(1);
          }}
          handleKeyUp={(e: any) => {
            e.preventDefault();
            if (e.keyCode === 13) {
              const elem: any = document.getElementById("search-feed");
              const searchVal = e.target.value;
              if (searchVal.length === 0) {
                elem.blur();
                setQuery("");
                return;
              }
              elem.blur();
              setQuery(searchVal);
              setDefaultQuery(searchVal);
              setCurrentPage(1);
            }
          }}
          isActiveFilter={Boolean(query)}
          onSearchClick={() => {
            const elem: any = document.getElementById("search-feed");
            const searchVal = elem.value;
            if (searchVal.length === 0) {
              return;
            }
            elem.blur();
            setQuery(searchVal);
            setDefaultQuery(searchVal);
            setCurrentPage(1);
          }}
          onClearClick={() => {
            const elem: any = document.getElementById("search-feed");
            elem.value = "";
            setCurrentPage(1);
            setQuery("");
            setDefaultQuery("");
            refetch();
            setCurrentPage(1);
          }}
        />
      </div>

      {/* Wrap the ContentTable component with InfiniteScroll */}

      <AllContentTable
        username={username}
        user_id={user_id}
        data={data}
        handleFeed={handleFeed}
        posts={allPosts}
        query={query}
        isLoading={isLoading}
        setAllPosts={setAllPosts}
        componentName={componentName}
        setRemovePosts={setRemovePosts}
        refetch={refetch}
      />

      {/* Render the Pagination component */}
      {pages?.feedCount > POSTS_PER_PAGE && (
        <Pagination
          className="pagination"
          current={currentPage}
          total={pages?.feedCount}
          size="small"
          showSizeChanger={false}
          showQuickJumper
          pageSize={POSTS_PER_PAGE}
          onChange={(page: number) => setCurrentPage(page)}
        />
      )}
    </>
  );
};

export default EditProductBankCheckboxComponent;
