import { useState } from "react";
import { useQuery } from "react-query";
import { getEmbedding } from "../../../Utils/SupabaseServices/ContentDatabase";
import { supabase } from "../../../config/supabaseClient";
import { StaticVariables } from "../../../Utils/StaticVariables";

// import { useSearchData } from "./useLoadSearchbarData";

/**
 *  Use this hook to control feed pagination
 * @param userId
 * @param index - "active" || "archive"
 * @param postsPerPage
 * @returns
 */
export function useLoadFirstEmbedding(
  username: any,
  user_id: any,

  filter: any,

  showSocials: any[],
  fts_total: any
) {
  async function getCount() {
    let threshold: any = 0.8;
    let postIds: any = [];
    if (user_id) {
      if (filter) {
        const elementsCount = 5000;
        // const embedding = await getEmbedding(queryTerm);
        const connectingWords = StaticVariables().connectingWords
 

        // Split the sentence into words
        const words = filter.split(" ");

        // Filter out connecting words
        const filteredWords = words.filter(
          (word: any) => !connectingWords.includes(word.toLowerCase())
        );

        // Join the filtered words back into a sentence
        const result = filteredWords.join(" ");
        const pgQuery = result.toLowerCase().replace(/\s+/g, " & ");

        const embedding = await getEmbedding(pgQuery);
        const { data: countResult, error: countError } = await supabase.rpc(
          // "semantic_searchfiltercount",
          // "semantic_ssearchcounthybrid",
          // "semantic_ssearchcountembed",
          // "semantic_ssearchcountembedpostid",
          "semantic_ssearchcountembedpostidsimfirst",
          {
            query_string: pgQuery,
            query_embedding: embedding,
            similarity_threshold: StaticVariables().THRESHOLD,

            // match_count: elementsCount,
            userid: user_id,
            username: username,
            socials: showSocials,
            // filter:filter
          }
        );
        // let resCount=countResult.length;

        // postIds = countResult.map((item:any)=> item.post_id);

        // if (countResult?.[0]?.count === 0) {
        //   resCount = 0;
        // } else {
        //   resCount = countResult?.[0]?.count ;
        // }
        // feedCount = resCount;

        // if (feedCount) {
        if (countResult && countResult[0]) {
          if (fts_total > 30) {
            threshold = countResult?.[0].similarity - 0.01;
          } else {
            if (countResult?.[0].similarity > 0.9) {
              threshold = countResult?.[0].similarity - 0.06;
            } else if (countResult?.[0].similarity <= 0.845) {
              threshold = countResult?.[0].similarity - 0.01;
            } else {
              threshold = countResult?.[0].similarity - 0.025;
            }
          }
          threshold = threshold.toString().split(".");
          threshold = parseFloat(
            threshold[0] + "." + threshold[1].substring(0, 4)
          );
        }

        return {
          // totalPages: Math.ceil(feedCount / postsPerPage),
          first:
            countResult && countResult[0] ? countResult?.[0].similarity : 0.9,
          threshold: threshold ? threshold : 0.8,
        };
        // } else {
        //   return 0;
        // }
      }
    }
  }

  return useQuery(["getFirstEmbeddding", filter, filter, user_id], getCount, {
    enabled: Boolean(fts_total >= 0),
    // refetchOnWindowFocus: true,
  });
}
