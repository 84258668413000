import { useInfiniteQuery, useQuery } from "react-query";
import { supabase } from "../../../config/supabaseClient";
import dayjs from "dayjs";

export function useLoadTrendingProducts(
  user_id: string,
  username: any,
  title: any,
  numberOfClicks: any,
  removedPostIds: any,
  startDate: any,
  endDate: any,
  days: any,
  isChanged?: boolean
) {
  const fetchTrendingProducts = async () => {
    if (removedPostIds && removedPostIds !== "") {
      removedPostIds = Array.from(
        new Set(removedPostIds?.split(",").filter((elem: any) => elem !== ""))
      );
    }
    const isNotDateFormat = (dateString: any) => {
      const dateFormatRegex = /^\d{4}-\d{2}-\d{2}$/;
      return !dateFormatRegex.test(dateString);
    };

    if (days === "7" || days === "30" || days === "90") {
      startDate = dayjs().subtract(days, "day").format("YYYY-MM-DD");
      endDate = dayjs().format("YYYY-MM-DD");
    }

    if (
      startDate &&
      endDate &&
      !isNotDateFormat(startDate) &&
      !isNotDateFormat(endDate)
    ) {
      const { data, error } = await supabase.rpc(
        "get_post_clicks_analyticss_trending_date_created",
        {
          route_name: `/${username}`,
          userid: user_id,
          start_date: startDate,
          end_date: endDate,
        }
      );
      if (data) {
        const transformedData = data.reduce((acc: any, item: any) => {
          const post = JSON.parse(item.post);
          // if (!post.title) {
          //   return acc;
          // }
          if (!acc[post.title]) {
            acc[post.title] = [];
          }

          acc[post.title].push({
            post_id: post.post_id,
            media_url: post.media_url,
            count: item.count,
            name: post.name,
          });

          return acc;
        }, {});

        // .filter((key) => key !== undefined); // Remove undefined keys

        // const postIds = Array.from(
        //   new Set(
        //     Object.keys(transformedData)
        //       .flatMap((key: any) =>
        //         transformedData[key].filter(
        //           (post: any) =>
        //             post.count >= numberOfClicks &&
        //             typeof post.post_id === "string"
        //         )
        //       )
        //       .sort((a: any, b: any) => b.count - a.count)
        //       .map((post: any) => post.post_id)
        //   )
        // );
        // const mergedData = Object.keys(transformedData)
        //   .flatMap((key) => transformedData[key])
        //   .reduce((acc: any, post: any) => {
        //     if (!acc[post.post_id]) {
        //       acc[post.post_id] = { count: post.count, post_id: post.post_id };
        //     } else {
        //       acc[post.post_id].count += post.count;
        //     }
        //     return acc;
        //   }, {});
        //   const postIds = Object.entries(mergedData)
        //   .sort(([, a]:any, [, b]:any) => b.count - a.count)
        //   .reduce((acc: any, [key, value]) => {
        //     acc[key] = value;
        //     return acc;
        //   }, {});
        // .sort((a:any, b:any) => b.count - a.count); // Sort by count in descending order
        const mergedData = Object.keys(transformedData)
          .flatMap((key) => transformedData[key])
          .reduce((acc: any, post: any) => {
            if (!acc[post.post_id]) {
              acc[post.post_id] = { count: post.count, post_id: post.post_id };
            } else {
              acc[post.post_id].count += post.count;
            }
            return acc;
          }, {});
          const postIdsCount = Object.entries(mergedData)
          .sort(([keyA, valueA]: any, [keyB, valueB]: any) => {
            return valueB.count - valueA.count; // Sort by count (descending order)
          })
          .filter(([key, value]: any) => {
            return (
              value.count >= numberOfClicks && typeof value.post_id === "string"
            );
          })
        const postIds = Object.entries(mergedData)
          .sort(([keyA, valueA]: any, [keyB, valueB]: any) => {
            return valueB.count - valueA.count; // Sort by count (descending order)
          })
          .filter(([key, value]: any) => {
            return (
              value.count >= numberOfClicks && typeof value.post_id === "string"
            );
          })
          .map(([key, value]: any) => value.post_id);
        // .map(([key, value]: any) => {
        //   return { key, value }; // Optionally return the pair if needed
        // });;

        // .map(([key, value]: any) => {
        //   return { key, value }; // Optionally return the pair if needed
        // });

        // .sort(([, a]: any, [, b]: any) => Number(b.count) - Number(a.count)) // Ensure sorting by count
        // .reduce((acc: any, [key, value]) => {
        //   acc[key] = value; // Rebuild the object from sorted entries
        //   return acc;
        // }, {});


        const [
          { data: searchResult = [], error: searchError },
          // { data: com = [], error },
        ] = await Promise.all([
          supabase.rpc("searchresulltt", {
            userid: user_id,
            username: username,
            postids: postIds,
          }),
          // supabase.rpc("productbankkresult", {
          //   userid: user_id,
          //   postids: postIds,
          // }),
        ]);
        const result = [...searchResult]
        // const result = [...searchResult, ...com];
      //   const result = [
      //     ...searchResult,
      //     ...com.filter(
      //         (comPost:any) => !searchResult.some((searchPost:any) => searchPost.post_id === comPost.post_id)
      //     )
      // ];
        let filteredArray = result;

        if (removedPostIds) {
          filteredArray = result.filter(
            (obj: any) => !removedPostIds.includes(obj.post_id)
          );
        }

        filteredArray = Array.from(
          new Map(
            filteredArray.map((post: any) => [post.post_id, post])
          ).values()
        );

        // Return the first 25 items after filtering
        return filteredArray.slice(0, 25);
        // return result.slice(0, 25);
      }
    }
  };
  return useQuery(
    [
      "TrendingProducts",
      user_id,
      numberOfClicks,
      removedPostIds,
      startDate,
      endDate,
      // isChanged
    ],
    fetchTrendingProducts,
    {
      enabled:
        Boolean(title) &&
        Boolean(numberOfClicks) &&
        Boolean(
          Array.from(
            new Set(
              removedPostIds
                ?.split(",")
                .filter((elem: any) => elem.trim() !== "")
            )
          ).length >= 0
        ),
    }
  );
}
