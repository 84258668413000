import { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import validator from "validator";
import AuthTerm from "../../../components/AuthTerm/authTerm";
import CreatorQuote from "../../../components/CreatorQuote/creatorQuote";
import ErrorMsg from "../../../components/ErrorMsg/errorMsg";
import { loginEmail } from "../../../Utils/SupabaseServices/UserDatabase";
import "./EmailLogin.css";

import track, { TRACKING_TYPES } from "../../../Utils/SupabaseServices/Track";
const EmailLogin = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState(false);
  const [error, setError] = useState<any>("");
  const [emailError, setEmailError] = useState<any>("");
  const location=useLocation()
  useEffect(() => {
    track(location.pathname, TRACKING_TYPES.PAGE, {
      pathname: location.pathname,
      user_id: "",
    });
  }, []);

  async function signInWithEmail(e: any) {
    e.preventDefault();
    const signInData = await loginEmail(email, password);
   
    if (!signInData) {
      navigate("/404");
    } else if (signInData === "error") {
      setError("Invalid login credentials!");
    } else if (signInData) {
      navigate(`/dashboard/${signInData}`);

    }
  }
  const validateEmail = (email: any) => {
    if (validator.isEmail(email)) {
      setEmailError("");
      return true;
    } else {
      setEmailError("Enter valid Email!");
    }
  };
  function validateCredentials(e: any) {
    e.preventDefault();
    if (validateEmail(email)) {
      signInWithEmail(e);
    }
  }
  function SubmitButton(props: any) {
    if (email && password) {
      return <button className="submit-btn">{props.name}</button>;
    } else {
      return (
        <button className="submit-btn">
          {props.name}
        </button>
      );
    }
  }
 
  return (
    <div className="auth-container">
    <div
      className="login-auth"
    >
      <div className="auth-phone-signup-container-flex">

        <div className='navbar_container'>
        <a href="/" className='navbar_logo-link w-nav-brand' aria-label='home'>
          <div className='logo-text'>
            <img
              className='homepage-icon'
              src='/assets/output-onlinepngtools-edited.png'
              alt='thesearchbubble logo'
            />
          </div>
        </a>
          <nav className='navbar_menu w-nav-menu'>
            <div className='navbar_menu-link-wrapper'>
          
              <a href="/subscribe" className="navbar_link w-nav-link sign-up">sign up for free</a>
            </div>
          </nav>
        </div>

        <div className="auth-form-wrapper">
          <div className="auth-title-div">
            <p className="auth-title-div-p">Welcome back</p>
          </div>

          <form className="input-form" onSubmit={validateCredentials}>
              <div className="input-div email">
                {" "}
                <input
                  type="email"
                  placeholder="Email"
                  onChange={(e: any) => setEmail(e.target.value)}
                  required
                />
              </div>
              {emailError ? <ErrorMsg error={emailError} /> : ""}
              <div className="input-div">
                {" "}
                <input
                  type="password"
                  placeholder="Password"
                  onChange={(e: any) => setPassword(e.target.value)}
                  required
                />
              </div>{" "}
              {error ? <ErrorMsg error={error} /> : ""}
              <SubmitButton name="Sign in" />
            </form>

        
        </div>
        <AuthTerm />
      </div>
      <CreatorQuote />
    </div>
  </div>
   
  );
};

export default EmailLogin;
