import Feed from "../Feed/Feed";
import CreatorProfile from "../../CreatorProfile/creatorProfile";
import { useState, useEffect } from "react";
import FeedPosts from "../../skeleton/Views/skeletonComponents/feedPosts";
import Skeleton from "react-loading-skeleton";
import "../../../styles/skeleton.css";
import {  useLocation, useNavigate, useParams } from "react-router-dom";
import track, { TRACKING_TYPES } from "../../../Utils/SupabaseServices/Track";
import { updateRefClick } from "../../../Utils/SupabaseServices/ContentDatabase";
import AdditionalCarousels from "../../StayUpToDateComponent/StayUpToDateRenderComponent/AdditionalCarousels";
import { useLoadStayUpToDateComponents } from "../../../Utils/customHooks/UpToDateContent/useLoadStayUpToDateComponents";
import { IndividualComponent } from "../../StayUpToDateComponent/StayUpToDateIndividuelComponent/IndividualComponent";
import ContactUs from "../../../Screens/FollowerView/ContactUs";


const FeedControls = ({
  indexPages,
  userDetails,
  username,
  page,
  name,
  userId,
  data,
  hashtagFilters,
  avgWords,
  liveView,
}: {
  indexPages?: any;
  userDetails?: any;
  username?: string;
  page: string;
  name?: string;
  userId?: any;
  data?: any;
  hashtagFilters?: any;
  avgWords: any;
  liveView: any;
}) => {
  const [showSearch, setShowSearch] = useState(false);
  const [showSearchLoading, setShowSearchLoading] = useState(false);
  const location = useLocation();
  const [hashtagFilterArray, setHashtagFilterArray] =
    useState<any[]>(hashtagFilters);
  const param = useParams();
  const navigate = useNavigate();
  const [showFeedData, setShowFeedData] = useState(false);
  const [isLocalLoading, setIsLocalLoading] = useState(true);
  useEffect(() => {
    // Simulate loading
    const timer = setTimeout(() => {
      setIsLocalLoading(false); // Set loading to false after some time
    }, 1500); // Adjust time as needed

    return () => clearTimeout(timer); // Cleanup timer on unmount
  }, []);
  useEffect(() => {
    setShowFeedData(param?.id ? false : true);
  }, [param?.id]);
  useEffect(() => {
    const updateSearchBar = () => {
      if (data?.feedControls?.checkedType === "Landing Page") {
        const urlSearchParams = new URLSearchParams(window.location.search);
        const params = Object.fromEntries(urlSearchParams.entries());

        if (
          urlSearchParams?.size > 0 &&
          (params.hasOwnProperty("term") || params.hasOwnProperty("from"))
        ) {
          setShowSearch(true);
        } else {
        }
      } else {
        setShowSearch(true);
      }
    };

    updateSearchBar();
  }, [data?.feedControls?.checkedType, location.search]);

  function handleRedirect(
    postId: string,
    media_url: string,
    title: any,
    name: any
  ) {
    track("Post Click", TRACKING_TYPES.EVENT, {
      pathname: location.pathname,
      details: {
        post_id: postId,
        media_url: media_url,
        title: title,
        name: name,
        ...(param?.id ? { id: param?.id } : {}), // Conditionally add `id` if it exists
      },
      user_id: userId,
      title: "Stay Up To Date",
    });
    updateRefClick(postId, userId);
  }

  function handleShop(
    postId: string,
    media_url: string,
    title: any,
    name: any
  ) {
    track("Post Click", TRACKING_TYPES.EVENT, {
      pathname: location.pathname,
      details: {
        post_id: postId,
        media_url: media_url,
        title: title,
        name: name,
        ...(param?.id ? { id: param?.id } : {}), // Conditionally add `id` if it exists
      },
      user_id: userId,
      title: "Stay Up To Date",
    });
  }
  function handleNavigate(e: any) {
    e.preventDefault();
    navigate(`/${username}`); // Navigate to the main page
  }
  const {
    isLoading: StayUpToDateComponentsLoading,
    data: StayUpToDateComponents,
  }: any = useLoadStayUpToDateComponents(username, userId);

  const isContactPage = location?.pathname.includes("contact-me");
  function gotoSignUpPage() {
    const utmUrl = `/subscribe?source=${username}`;
    navigate(utmUrl);
  }

  function handleEmptyCarousel(list: any) { }

  const [isButtonVisible, setIsButtonVisible] = useState(false);

  useEffect(() => {
    if (
      StayUpToDateComponents &&
      StayUpToDateComponents.showingComponents &&
      StayUpToDateComponents.showingComponents.length > 0
    ) {
      const timer = setTimeout(() => {
        setIsButtonVisible(true);
      }, 2000);

      return () => clearTimeout(timer);
    } else {
      setIsButtonVisible(false);
    }
  }, [StayUpToDateComponents]);
  return (
    <>
      <div>
        <CreatorProfile
          userDetails={userDetails}
          showIcons={data?.feedControls?.showIcons}
          liveView={liveView}
          username={username}
          showSocialLinks={data?.feedControls.showSocialLinks}
          socialLinks={data?.userData}
          description={data?.description}
          setShowSearch={setShowSearch}
          showSearch={showSearch}
          setShowSearchLoading={setShowSearchLoading}
          desiredOrder={userDetails?.social_links_order?.split(",")}
          showSearchbar={data?.feedControls.showSearchbar}
        />
        {showSearchLoading ? (
          <>
            <div className="search-container-skeleton feed-search-skeleton">
              <Skeleton className="search-wrapper-input feed-search-wrapper" />
            </div>
            <FeedPosts />
          </>
        ) : (
          <>
            {param?.id ? (
              <>
                <Feed
                  indexPages={indexPages}
                  isNecSearch={data?.userData?.isNecSearch}
                  username={username}
                  page={page}
                  name={name}
                  controls={data?.feedControls}
                  activeFilters={data?.activeFilters}
                  highlights={data?.highlights}
                  customCategories={data?.customCategories}
                  userId={userId}
                  hashtagFilterArray={hashtagFilterArray}
                  avgWords={avgWords}
                  showSocials={data?.userData?.showSocials}
                  is_nec_present={data?.userData.is_nec_present}
                  contentType={data?.feedControls?.checkedType}
                  contentPresent={data?.userExists}
                  showSocialLinks={data?.feedControls.showSocialLinks}
                  search_bar_text={
                    data?.search_bar_text
                      ? data?.search_bar_text
                      : `Search ${name} Content`
                  }
                  search_bar_tags={userDetails?.search_bar_tags}
                  contentOrder={data?.feedControls.contentOrder}
                  isSearchPage={showSearch}
                  brand_color={userDetails?.brand_color}
                  setShowSearch={setShowSearch}
                  isFilloutOn={data?.userData?.is_fillout_on}
                  setShowFeedData={setShowFeedData}
                  showFeedData={showFeedData}
                  showSearchbar={data?.feedControls.showSearchbar}
                  showSearch={showSearch}
                  setShowSearchLoading={setShowSearchLoading}

                />
                {!showFeedData && (
                  <div id="app-wrapper" className="inex-1">
                    <div className="uptodate-wrapper">
                      <IndividualComponent
                        componentId={param?.id}
                        user_id={userId}
                        username={username}
                        brand_color={userDetails?.brand_color}
                        handleShop={handleShop}
                        handleRedirect={handleRedirect}
                        handleEmptyCarousel={handleEmptyCarousel}
                      />

                      {/* {!isLocalLoading &&  */}

                      {isButtonVisible && (
                        <>
                          {" "}
                          <AdditionalCarousels
                            components={StayUpToDateComponents?.data}
                            username={username}
                            componentId={param?.id}
                            handleEmptyCarousel={handleEmptyCarousel}
                            isLocalLoading={isLocalLoading}
                            user_id={userId}
                          />
                          <button
                            className="join-button"
                            onClick={gotoSignUpPage}
                          >
                            <span>join</span>
                            <span
                              className="join-button__placeholder"
                              style={{ color: userDetails?.brand_color }}
                            >
                              {username}
                            </span>
                            <span>on search bubble</span>
                          </button>
                        </>
                      )}
                    </div>
                  </div>
                )}
              </>
            ) : (
              <>
                {isContactPage ? (<>
=                  <ContactUs userId={userId} username={username}/></>
                 
                ) : (
                  <Feed
                    indexPages={indexPages}
                    isNecSearch={data?.userData?.isNecSearch}
                    username={username}
                    page={page}
                    name={name}
                    controls={data?.feedControls}
                    activeFilters={data?.activeFilters}
                    highlights={data?.highlights}
                    customCategories={data?.customCategories}
                    userId={userId}
                    hashtagFilterArray={hashtagFilterArray}
                    avgWords={avgWords}
                    showSocials={data?.userData?.showSocials}
                    is_nec_present={data?.userData.is_nec_present}
                    contentType={data?.feedControls?.checkedType}
                    contentPresent={data?.userExists}
                    showSocialLinks={data?.feedControls.showSocialLinks}
                    search_bar_text={
                      data?.search_bar_text
                        ? data?.search_bar_text
                        : `Search ${name} Content`
                    }
                    contentOrder={data?.feedControls.contentOrder}
                    isSearchPage={showSearch}
                    brand_color={userDetails?.brand_color}
                    setShowSearch={setShowSearch}
                    isFilloutOn={data?.userData?.is_fillout_on}
                    setShowFeedData={setShowFeedData}
                    showFeedData={showFeedData}
                    showSearchbar={data?.feedControls.showSearchbar}
                    showSearch={showSearch}
                    setShowSearchLoading={setShowSearchLoading}

                  />
                )}
              </>
            )}
          </>
        )}
      </div>
    </>
  );
};

export default FeedControls;
