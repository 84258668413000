import React, { useEffect, useState } from "react";
import PuffLoader from "react-spinners/PuffLoader";
import "./StayUpToDateComponentsWrapper.css";
import { MdOutlineDragIndicator } from "react-icons/md";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { Button, Modal } from "antd";
import {
    deleteComponent,
    updateIndexValue,
} from "../../../../Utils/SupabaseServices/StayUpToDateDatabase";
import SubComponents from "./SubComponents";
import EditCustomBanner from "./EditTemplates/EditCustomBanner";
import EditHighlightLinks from "./EditTemplates/EditHighlightLinks";
import StayUpToDateSkeleton from "./StayUpToDateSkeleton";
import EditYesterdayLinks from "./EditTemplates/EditYesterdayLinks";
import EditProductBank from "./EditTemplates/EditProductBank";
import EditQueryContent from "./EditTemplates/EditQueryContent";
import EditTrendingProducts from "./EditTemplates/EditTrendingProducts";
import StatusSelector from "../../../../components/StatusSelector/StatusSelector";
import EditMyLinks from "./EditTemplates/EditMyLinks";
import EditYesterdayContent from "./EditTemplates/EditYesterdayContent";
import EditNewContent from "./EditTemplates/EditNewContent";
import EditNewLinks from "./EditTemplates/EditNewLinks";
import CopyComponent from "../../../../components/CopyComponent/CopyComponent";
import FeedControls from "../../../../components/FeedPage/FeedControls/FeedControls";
import FollowerView from "../../../FollowerView/FollowerView";
import CreatorProfile from "../../../../components/CreatorProfile/creatorProfile";
import { useLoadCompletePage } from "../../../../Utils/customHooks/useLoadCompletePage";
import { useLocation } from "react-router-dom";
import ProfileSkeleton from "../../../../components/skeleton/Views/ProfileSkeleton";
import { IoAddCircle } from "react-icons/io5";
import CreatorLandingProfile from "../../../../components/CreatorProfile/CreatorLandingProfile";
import Skeleton from "react-loading-skeleton";

const LendingComponentsWrapper = (props: any) => {
    const {
        username,
        user_id,
        StayUpToDateComponents,
        StayUpToDateComponentsLoading,
        refetch,
        brand_color,
        refatchStayUpToDate,
        showModal,
        refetchUser,
    } = props;
    const [localData, setLocalData] = useState(StayUpToDateComponents);
    const location = useLocation();
    const {
        isLoading,
        data,
        error,
        refetch: refetchUserDetail,
    }: any = useLoadCompletePage(username, "follower");
    useEffect(() => {
        // refetch();
        setLocalData(StayUpToDateComponents);
    }, [StayUpToDateComponents]);
    const [selectedComponent, setSelectedComponent] = useState("");
    const [openDeleteModal, setOpenDeleteModal] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [initial, setInitial] = useState(false);
    const [initialContent, setInitialContent] = useState(false);
    const [initialLinks, setInitialLinks] = useState(false);

    const [isEditModalOpen, setIsEditModalOpen] = useState(false);
    const [isContentEditModalOpen, setIsContentEditModalOpen] = useState(false);
    const [isLinksEditModalOpen, setIsLinksEditModalOpen] = useState(false);
    const [isYesterdayLinksEditModalOpen, setIsYesterdayLinksEditModalOpen] =
        useState(false);
    const [isYesterdayContentEditModalOpen, setIsYesterdayContentEditModalOpen] =
        useState(false);
    const [isQueryLinksEditModalOpen, setIsQueryLinksEditModalOpen] =
        useState(false);
    const [isTrendingProductsEditModalOpen, setIsTrendingProductsEditModalOpen] =
        useState(false);

    const [isBannerEditModalOpen, setIsBannerEditModalOpen] = useState(false);
    const [isProductBankEditModalOpen, setIsProductBankEditModalOpen] =
        useState(false);

    const [isHighlightEditModalOpen, setIsHighlightEditModalOpen] =
        useState(false);

    const [isMyLinkEditModalOpen, setIsMyLinkEditModalOpen] = useState(false);
    const [isFormEditModalOpen, setIsFormEditModalOpen] = useState(false);

    const [confirmLoading, setConfirmLoading] = useState(false);

    const [status, setStatus] = useState("");

    useEffect(() => {
        refetch();
    }, [status, StayUpToDateComponents]);

    const onDragEnd = async (result: any) => {
        if (!result.destination) {
            return;
        }
        const newData = [...localData];

        const [removed] = newData.splice(result.source.index, 1);

        newData.splice(result.destination.index, 0, removed);
        setLocalData([...newData]);
        updateIndexValue(user_id, newData);
    };
    async function handleDelete(name: any) {
        setSelectedComponent(name);
        setOpenDeleteModal(true);
    }
    async function handleEdit(name: any) {
        if (name.type === "content") {
            setIsContentEditModalOpen(true);
        } else if (name.type === "links") {
            setIsLinksEditModalOpen(true);
        } else if (name.type === "my-links" || name.display_name === "My Links") {
            setIsMyLinkEditModalOpen(true);
        } else if (name.type === "form" || name.display_name === "Form") {
            setIsMyLinkEditModalOpen(true);
        } else if (name.type === "query-links" || name.type === "query-content") {
            setIsQueryLinksEditModalOpen(true);
        } else if (name.type === "trending products") {
            setIsTrendingProductsEditModalOpen(true);
        } else if (name.display_name === "This Week Content") {
            setIsYesterdayContentEditModalOpen(true);
        } else if (
            name.display_name.split(",").includes("Yesterday") ||
            name.display_name.split(",").includes("This Week") ||
            name.display_name.split(",").includes("This Week Content") ||
            name.display_name.split(",").includes("This Month") ||
            name.display_name.split(",").includes("Monday") ||
            name.display_name.split(",").includes("Tuesday") ||
            name.display_name.split(",").includes("Wednesday") ||
            name.display_name.split(",").includes("Thursday") ||
            name.display_name.split(",").includes("Friday") ||
            name.display_name.split(",").includes("Saturday") ||
            name.display_name.split(",").includes("Sunday") ||
            name.display_name.split(",").includes("This Weekend")
        ) {
            setIsYesterdayLinksEditModalOpen(true);
        } else if (name.type === "product-bank") {
            setIsProductBankEditModalOpen(true);
        } else if (name.type === "banner") {
            setIsBannerEditModalOpen(true);
        } else if (name.type === "highlight") {
            setIsHighlightEditModalOpen(true);
        }

        setSelectedComponent(name);
    }
    const handleOk = async () => {
        setConfirmLoading(true);
        const newData = [...localData];
        const filteredData = newData.filter(
            (item) => item.name !== selectedComponent
        );
        const dataToDelete = newData.filter(
            (item) => item.name === selectedComponent
        );
        await deleteComponent(
            selectedComponent,
            user_id,
            filteredData,
            dataToDelete,
            refetch,
            username
        ).then(() => {
            setTimeout(() => {
                setLocalData(filteredData);
            }, 10);
            setTimeout(() => {
                setConfirmLoading(false);
                setOpenDeleteModal(false);
            }, 50);
        });
    };

    const handleCancel = () => {
        setOpenDeleteModal(false);
    };
    return (
        <div className="stay-up-to-date-wrapper" style={{ marginTop: "53px" }}>
            {StayUpToDateComponentsLoading ||
                isLoading ?
                (
                    <div className="loader-container">
                        {/* <ProfileSkeleton /> */}
                        <div
                            className={
                                !(props.isCreator || location.pathname.includes("/follower"))
                                    ? "skeleton-creator mb-4"
                                    : "skeleton-creator-page mb-4"
                            }
                        >
                            <Skeleton circle className="creator-img-skeleton hw-100" />
                        </div>
                        <StayUpToDateSkeleton />
                    </div>
                ) : (
                    <>
                        {/* <FollowerView comingFrom="stayUpToDate" /> */}
                        {!isLoading && (
                            <CreatorLandingProfile
                                userDetails={data?.combinedRes?.userData}
                                showIcons={data?.combinedRes?.feedControls?.showIcons}
                                liveView={location.pathname.includes("/follower") ? false : true}
                                username={username}
                                showSocialLinks={data?.combinedRes?.feedControls.showSocialLinks}
                                socialLinks={data?.combinedRes?.userData}
                                description={data?.combinedRes?.description}
                                desiredOrder={data?.combinedRes?.userData?.social_links_order?.split(
                                    ","
                                )}
                                isUserDashboard={true}
                                refetch={refetchUserDetail}
                                refetchUser={refetchUser}
                            />
                        )}

                        <div id="landing-wrapper" className="component-box mt-4 landing-wrapper">
                            {<IoAddCircle className="button-icon my-2" onClick={showModal} />}
                            <DragDropContext
                                onDragStart={() => {
                                    document.documentElement.style.scrollBehavior = "unset";
                                    document.body.style.scrollBehavior = "unset";
                                }}
                                onDragEnd={(result: any) => {
                                    onDragEnd(result);
                                }}
                            >
                                <Droppable droppableId="element">
                                    {(provided, snapshot) => (
                                        <div ref={provided.innerRef} {...provided.droppableProps}>
                                            {localData &&
                                                localData.map((component: any, index: any) => (
                                                    // <div key={index}>
                                                    <Draggable
                                                        key={component.index?.toString()}
                                                        draggableId={component.index?.toString()}
                                                        index={index}
                                                    >
                                                        {(provided) => (
                                                            <div
                                                                ref={provided.innerRef}
                                                                {...provided.draggableProps}
                                                                {...provided.dragHandleProps}
                                                            >
                                                                {" "}
                                                                <div
                                                                    key={component.id}
                                                                    className="component-box"
                                                                // style={{
                                                                //   display:
                                                                //     component.display_name === "Popup Newsletter"
                                                                //       ? "none"
                                                                //       : undefined,
                                                                // }}
                                                                >
                                                                    {/* {component.display_name !==
                                "Popup Newsletter" && ( */}
                                                                    <div className="component-item">
                                                                        <div className="component-text">
                                                                            <MdOutlineDragIndicator className="outline-drag" />
                                                                            <span className="component-name">
                                                                                {component.display_name === "Form" &&
                                                                                    component.name === "Form"
                                                                                    ? ""
                                                                                    : component.name}
                                                                            </span>
                                                                        </div>
                                                                        <div className="button-container">
                                                                            <CopyComponent
                                                                                user_id={user_id}
                                                                                username={username}
                                                                                component={component}
                                                                            />

                                                                            <StatusSelector
                                                                                defaultValue={component.status}
                                                                                component={component}
                                                                                user_id={user_id}
                                                                                refetch={refetch}
                                                                                setStatus={setStatus}
                                                                                refatchStayUpToDate={refatchStayUpToDate}
                                                                            />
                                                                            {component.type === "links" ||
                                                                                component.type === "query-links" ||
                                                                                component.type === "query-content" ||
                                                                                component.type === "content" ||
                                                                                component.type === "banner" ||
                                                                                component.type === "highlight" ||
                                                                                component.type === "product-bank" ||
                                                                                component.type === "trending products" ||
                                                                                component.display_name === "My Links" ||
                                                                                component.type === "my-links" ||
                                                                                component.display_name === "Form" ||
                                                                                component.type === "form" ||
                                                                                component.display_name
                                                                                    .split(",")
                                                                                    .includes("Monday") ||
                                                                                component.display_name
                                                                                    .split(",")
                                                                                    .includes("Tuesday") ||
                                                                                component.display_name
                                                                                    .split(",")
                                                                                    .includes("Wednesday") ||
                                                                                component.display_name
                                                                                    .split(",")
                                                                                    .includes("Thursday") ||
                                                                                component.display_name
                                                                                    .split(",")
                                                                                    .includes("Friday") ||
                                                                                component.display_name
                                                                                    .split(",")
                                                                                    .includes("Saturday") ||
                                                                                component.display_name
                                                                                    .split(",")
                                                                                    .includes("Sunday") ||
                                                                                component.display_name
                                                                                    .split(",")
                                                                                    .includes("This Weekend") ||
                                                                                component.display_name
                                                                                    .split(",")
                                                                                    .includes("Yesterday") ||
                                                                                component.display_name
                                                                                    .split(",")
                                                                                    .includes("This Month") ||
                                                                                component.display_name
                                                                                    .split(",")
                                                                                    .includes("This Week") ||
                                                                                component.display_name
                                                                                    .split(",")
                                                                                    .includes("This Week Content") ? (
                                                                                <Button
                                                                                    className="edit-btn"
                                                                                    onClick={() => {
                                                                                        handleEdit(component);
                                                                                    }}
                                                                                >
                                                                                    Edit
                                                                                </Button>
                                                                            ) : (
                                                                                ""
                                                                            )}
                                                                            <Button
                                                                                className="remove-btn"
                                                                                onClick={() =>
                                                                                    handleDelete(component.name)
                                                                                }
                                                                            >
                                                                                Remove
                                                                            </Button>
                                                                        </div>
                                                                    </div>
                                                                    {/* )} */}
                                                                    <SubComponents
                                                                        user_id={user_id}
                                                                        username={username}
                                                                        component={component}
                                                                        selectedComponent={selectedComponent}
                                                                        setSelectedComponent={setSelectedComponent}
                                                                        isEdit={isEdit}
                                                                        setIsEdit={setIsEdit}
                                                                        isEditModalOpen={isEditModalOpen}
                                                                        setIsEditModalOpen={setIsEditModalOpen}
                                                                        refetch={refetch}
                                                                        brand_color={brand_color}
                                                                    />
                                                                </div>
                                                                {/* <hr style={{ width: "98%", maxWidth: "98%" }} /> */}
                                                            </div>
                                                        )}
                                                    </Draggable>
                                                ))}
                                            {provided.placeholder}
                                        </div>
                                    )}
                                </Droppable>
                            </DragDropContext>
                        </div>
                        <Modal
                            open={openDeleteModal}
                            onOk={handleOk}
                            onCancel={handleCancel}
                            okText="Yes"
                            data-testid="delete-carousel"
                            confirmLoading={confirmLoading}
                        >
                            <p className="alertText">
                                Are you sure you don't want to show{" "}
                                <span className="alertTextTitle">{selectedComponent}</span>{" "}
                                section ?{" "}
                            </p>
                        </Modal>

                        <EditNewContent
                            username={username}
                            user_id={user_id}
                            isContentEditModalOpen={isContentEditModalOpen}
                            setIsContentEditModalOpen={setIsContentEditModalOpen}
                            type="content"
                            component={selectedComponent}
                            setSelectedComponent={setSelectedComponent}
                            refetch={refetch}
                            initial={initialContent}
                            setInitial={setInitialContent}
                        />
                        <EditNewLinks
                            username={username}
                            user_id={user_id}
                            isLinksEditModalOpen={isLinksEditModalOpen}
                            setIsLinksEditModalOpen={setIsLinksEditModalOpen}
                            type="links"
                            component={selectedComponent}
                            setSelectedComponent={setSelectedComponent}
                            refetch={refetch}
                            initial={initialLinks}
                            setInitial={setInitialLinks}
                        />

                        <EditProductBank
                            username={username}
                            user_id={user_id}
                            isProductBankEditModalOpen={isProductBankEditModalOpen}
                            setIsProductBankEditModalOpen={setIsProductBankEditModalOpen}
                            type="product-bank"
                            component={selectedComponent}
                            setSelectedComponent={setSelectedComponent}
                            refetch={refetch}
                            initial={initial}
                            setInitial={setInitial}
                        />
                        <EditYesterdayLinks
                            username={username}
                            user_id={user_id}
                            isYesterdayLinksEditModalOpen={isYesterdayLinksEditModalOpen}
                            setIsYesterdayLinksEditModalOpen={setIsYesterdayLinksEditModalOpen}
                            type="links"
                            component={selectedComponent}
                            setSelectedComponent={setSelectedComponent}
                            refetch={refetch}
                        />
                        <EditYesterdayContent
                            username={username}
                            user_id={user_id}
                            isYesterdayLinksEditModalOpen={isYesterdayContentEditModalOpen}
                            setIsYesterdayLinksEditModalOpen={
                                setIsYesterdayContentEditModalOpen
                            }
                            type="links"
                            component={selectedComponent}
                            setSelectedComponent={setSelectedComponent}
                            refetch={refetch}
                        />
                        <EditQueryContent
                            username={username}
                            user_id={user_id}
                            isQueryLinksEditModalOpen={isQueryLinksEditModalOpen}
                            setIsQueryLinksEditModalOpen={setIsQueryLinksEditModalOpen}
                            type="query"
                            component={selectedComponent}
                            setSelectedComponent={setSelectedComponent}
                            refetchDashboard={refetch}
                            initial={initial}
                            setInitial={setInitial}
                        />
                        <EditTrendingProducts
                            username={username}
                            user_id={user_id}
                            isTrendingProductsEditModalOpen={isTrendingProductsEditModalOpen}
                            setIsTrendingProductsEditModalOpen={
                                setIsTrendingProductsEditModalOpen
                            }
                            type="trending products"
                            component={selectedComponent}
                            setSelectedComponent={setSelectedComponent}
                            refetchDashboard={refetch}
                        />

                        <EditCustomBanner
                            username={username}
                            user_id={user_id}
                            isBannerEditModalOpen={isBannerEditModalOpen}
                            setIsBannerEditModalOpen={setIsBannerEditModalOpen}
                            type="banner"
                            component={selectedComponent}
                            setSelectedComponent={setSelectedComponent}
                            refetch={refetch}
                        />
                        <EditHighlightLinks
                            username={username}
                            user_id={user_id}
                            isHighlightEditModalOpen={isHighlightEditModalOpen}
                            setIsHighlightEditModalOpen={setIsHighlightEditModalOpen}
                            type="highlight"
                            component={selectedComponent}
                            setSelectedComponent={setSelectedComponent}
                            refetch={refetch}
                        />

                        <EditMyLinks
                            username={username}
                            user_id={user_id}
                            isMyLinkEditModalOpen={isMyLinkEditModalOpen}
                            setIsMyLinkEditModalOpen={setIsMyLinkEditModalOpen}
                            type="my-link"
                            component={selectedComponent}
                            setSelectedComponent={setSelectedComponent}
                            refetch={refetch}
                        />

                        <EditMyLinks
                            username={username}
                            user_id={user_id}
                            isMyLinkEditModalOpen={isFormEditModalOpen}
                            setIsMyLinkEditModalOpen={setIsFormEditModalOpen}
                            type="form"
                            component={selectedComponent}
                            setSelectedComponent={setSelectedComponent}
                            refetch={refetch}
                        />
                    </>
                )}
        </div>
    );
};

export default LendingComponentsWrapper;
