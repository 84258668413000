import { useInfiniteQuery } from "react-query";
import { StaticVariables } from "../../StaticVariables";
import { supabase } from "../../../config/supabaseClient";

export function useLoadSearchFeed(
  currentPage: any,
  username: any,
  user_id: any,
  queryTerm: any,

  postsPerPage: number,
  totalPages: number,
  feedCount: any,
  postIds: any[],

  showSocials: any[],

  contentOrder: any,
  isShopLinks: any,
  activeFilter?: any
  // isChange?:any
) {
  postsPerPage = 10;
  queryTerm = queryTerm && queryTerm.toLowerCase().replace(/\s+/g, " ").trim();

  async function getPaginatedFeed({ pageParam = 1 }) {
    let start = (currentPage - 1) * postsPerPage;
    let end = start + postsPerPage - 1;
    let data,
      error = null;
    if (isShopLinks) {
      if (queryTerm) {
        const connectingWords = StaticVariables().connectingWords;
        let words = queryTerm.split(" ");
        let singleWords: any = [];
        if (
          words.length === 1 &&
          !StaticVariables().SEARCH_EXCEPTIONS.includes(queryTerm)
        ) {
          const suffixRegex = /(s)$/i;
          if (suffixRegex.test(words[0])) {
            words = [`${words[0].replace(suffixRegex, "")}`];
          }
        }

        if (words.includes("shop") || words.includes("buy")) {
          contentOrder = "shoppable";
          words = words.filter(
            (word: any) => word !== "shop" && word !== "buy"
          );
        }

        let filteredWords = words.filter(
          (word: any) => !connectingWords.includes(word.toLowerCase())
        );

        const result = filteredWords.join(" ");
        const pgQuery = result.toLowerCase().replace(/\s+/g, " & ");

        const { data: searchResult = [], error: searchError } =
          await supabase.rpc(
            "searchfeedque",

            // "semanticcc_ftsspostidddoshop",
            {
              query_string: pgQuery,
              start_number: start,
              end_num: end,
              userid: user_id,
              username: username,
              socials: showSocials,

              idx: 0,
              show_ads: false,
              postids: postIds,
              content_order: contentOrder.split(" ")[0].toLowerCase(),
            }
          );

        data = [...searchResult];
      } else {
        let filteredArray = StaticVariables().TYPES;

        const { data: rpc, error: rpcError } = await supabase.rpc(
          "generalfeedshop",
          // "adssssgeneralfeeddtshop",
          {
            userid: user_id,
            start_number: start,
            end_num: end,
            filter: filteredArray,
            query_str: queryTerm ? queryTerm : "",
            user_name: username,
            socials: showSocials,
            idx: 0,
            show_ads: false,
          }
        );
        data = rpc;
        error = rpcError;
      }
    } else {
      if (queryTerm) {
        const connectingWords = StaticVariables().connectingWords;
        let words = queryTerm.split(" ");
        let singleWords: any = [];
        if (
          words.length === 1 &&
          !StaticVariables().SEARCH_EXCEPTIONS.includes(queryTerm)
        ) {
          const suffixRegex = /(s)$/i;
          if (suffixRegex.test(words[0])) {
            words = [`${words[0].replace(suffixRegex, "")}`];
          }
        }

        if (words.includes("shop") || words.includes("buy")) {
          contentOrder = "shoppable";
          words = words.filter(
            (word: any) => word !== "shop" && word !== "buy"
          );
        }

        let filteredWords = words.filter(
          (word: any) => !connectingWords.includes(word.toLowerCase())
        );

        const result = filteredWords.join(" ");
        const pgQuery = result.toLowerCase().replace(/\s+/g, " & ");

        const { data: searchResult = [], error: searchError } =
          await supabase.rpc(
            "searchfeedquerycont",
            // "semanticcc_ftsspostidddo",
            {
              query_string: pgQuery,
              start_number: start,
              end_num: end,
              userid: user_id,
              username: username,
              socials: showSocials,

              idx: 0,
              show_ads: false,
              postids: postIds,
              content_order: contentOrder.split(" ")[0].toLowerCase(),
            }
          );
        data = [...searchResult];
      } else {
        let filteredArray = StaticVariables().TYPES;

        const { data: rpc, error: rpcError } = await supabase.rpc(
          "generalfeedcontent",
          // "adssssgeneralfeeddtcont",
          {
            userid: user_id,
            start_number: start,
            end_num: end,
            filter: filteredArray,
            query_str: queryTerm ? queryTerm : "",
            user_name: username,
            socials: showSocials,
            idx: 0,
            show_ads: false,
          }
        );
        data = rpc;
        error = rpcError;
      }
    }
    const resultArray = [...data];

    if (resultArray && resultArray.length >= 0 && Array.isArray(resultArray)) {
      return resultArray;
    } else if (error) {
      throw new Error(error.message);
    } else {
      throw new Error("Failed to fetch posts");
    }
  }

  return useInfiniteQuery(
    [
      "paginatedSearchFeed",
      queryTerm,
      currentPage,
      user_id,
      isShopLinks,
      activeFilter,
    ],
    getPaginatedFeed,
    {
      getNextPageParam: (lastPage, pages) => {
        if (pages.length < totalPages) {
          return pages.length + 1;
        } else {
          return undefined;
        }
      },
      enabled: Boolean(totalPages),
    }
  );
}
