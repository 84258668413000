import { Modal, Input, Form, Button } from "antd";
import { useEffect } from "react";

export type EditType = "name" | "description";

interface ModalProps {
    visible: boolean;
    onClose: () => void;
    onSubmit: (values: { name: string; description: string }) => void;
    initialValue: { name: string; description: string; };
}

const EditBio: React.FC<ModalProps> = ({ visible, onClose, onSubmit, initialValue }) => {
    const [form] = Form.useForm();
    useEffect(() => {
        if (visible) {
            form.setFieldsValue(initialValue);
        }
    }, [visible, initialValue, form]);
    const handleOk = async () => {
        try {
            const values = await form.validateFields();
            onSubmit(values);
            form.resetFields();
            onClose();
        } catch (error) {
            console.error("Validation Failed:", error);
        }
    };

    const handleCancel = () => {
        form.resetFields();
        onClose();
    };

    return (
        <Modal
            title={`Edit Profile`}
            visible={visible}
            data-testid="edit-profile-modal"
            onOk={handleOk}
            onCancel={handleCancel}
            footer={[
                <Button key="cancel" onClick={handleCancel}>
                    Cancel
                </Button>,
                <Button key="submit" type="primary" onClick={handleOk}>
                    Save
                </Button>,
            ]}
        >
            <Form
                form={form}
                layout="vertical"
                initialValues={initialValue || { name: "", description: "" }}
            >
                <Form.Item
                    label="Name"
                    name="name"
                    rules={[{ required: true, message: "Please enter the name!" }]}
                >
                    <Input placeholder="Enter name" />
                </Form.Item>
                <Form.Item
                    label="Description"
                    name="description"
                >
                    <Input.TextArea placeholder="Enter description" rows={4} />
                </Form.Item>
            </Form>
        </Modal>
    );
};

export default EditBio