import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Form, Input, Button, message } from "antd";
import { supabase } from "../../config/supabaseClient";
import StayUpToDateSkeleton from "../DashboardView/StayUpToDate/StayUpToDateComponents/StayUpToDateSkeleton";
import { Rule } from "antd/es/form";

interface FieldConfig {
  [key: string]: boolean;
}

interface FieldsData {
  creator_mail: string;
  title_contact_text: string;
  button_contact_text: string;
}

const ContactUs = (props:any) => {
    const {userId,username}=props
    
  const location = useLocation();
  const [form] = Form.useForm();
  const user_id=location?.state?.userId || userId
  const user_name=location?.state?.username || username
  const [fieldConfig, setFieldConfig] = useState<FieldConfig>({});
  const [imageUrl, setImageUrl] = useState<string | null>(null);
  const [fieldsData, setFieldsData] = useState<FieldsData>({
    creator_mail: "",
    title_contact_text: "Contact Us",
    button_contact_text: "Submit",
  });

  useEffect(() => {
    if (user_id ) {
      const fetchContactConfig = async () => {
        const { data, error } = await supabase
          .from("contact_page")
          .select(
            "field_config, image_url, creator_mail, title_contact_text, button_contact_text"
          )
          .eq("user_id", user_id)
          .single();

        if (error) {
          message.error("Failed to fetch contact page configuration");
          return;
        }

        setFieldConfig(data.field_config.fields);
        setImageUrl(data.image_url);
        setFieldsData({
          creator_mail: data.creator_mail || "",
          title_contact_text: data.title_contact_text || "Contact Us",
          button_contact_text: data.button_contact_text || "Submit",
        });
      };
      fetchContactConfig();
    }
  }, [location?.state]);

  const handleFormSubmit = async (values: Record<string, any>) => {
    try {
      const { error: updateError } = await supabase
        .from("contact_page")
        .update({
          submitted_data: JSON.stringify(values, null, 2),
        })
        .eq("user_id", user_id);

      if (updateError) {
        throw updateError;
      }

      const dataSend = {
        email: fieldsData.creator_mail,
        creatorName:user_name ,
        values: values,
      };

      // const sendMailUrl = `http://localhost:3001/send-email`;
      const sendMailUrl = `https://dev.thesearchbubble.com/send-email`;

      await fetch(sendMailUrl, {
        method: "POST",
        body: JSON.stringify(dataSend),
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      });

      form.resetFields();
      message.success("Your submission has been sent successfully!");
    } catch (error) {
      message.error("Failed to submit your response");
    }
  };

  return (
    <div className="uptodate-wrapper">
      {fieldConfig && Object.keys(fieldConfig).length === 0 ? (
        <div className="loading-skeleton">
          <StayUpToDateSkeleton />
        </div>
      ) : (
        <div style={{ maxWidth: 600, margin: "0 auto" }} className="py-4">
          <h3
            className="title mb-4"
            style={{ color: "#000", alignItems: "start" }}
          >
            {fieldsData.title_contact_text}
          </h3>
          {imageUrl && (
            <img
              src={imageUrl}
              alt="Contact Page"
              style={{ width: "100%", marginBottom: "20px" }}
            />
          )}
          <Form form={form} layout="vertical" onFinish={handleFormSubmit}>
            {Object.entries(fieldConfig).map(([field, required]) => {
              const rules: Rule[] = [
                { required: required, message: `Please enter your ${field}` },
              ];

              if (field.toLowerCase() === "email") {
                rules.push({
                  type: "email",
                  message: "Please enter a valid email address",
                });
              }

              if (field.toLowerCase() === "phonenumber") {
                rules.push({
                  pattern: /^[0-9]{10}$/,
                  message: "Please enter a valid 10-digit phone number",
                });
              }

              return (
                <Form.Item
                  key={field}
                  name={field.toLowerCase()}
                  label={field}
                  rules={rules}
                >
                  {field.toLowerCase() === "comment" ? (
                    <Input.TextArea rows={4} />
                  ) : (
                    <Input />
                  )}
                </Form.Item>
              );
            })}
            <Button type="primary" htmlType="submit">
              {fieldsData.button_contact_text}
            </Button>
          </Form>
        </div>
      )}
    </div>
  );
};

export default ContactUs;
