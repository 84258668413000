import { Button } from "antd";
import React from "react";
import { Card } from "react-bootstrap";
import { BsHourglassSplit } from "react-icons/bs";
import "../StayUpToDateComponent/StayUpToDate.css";
import { getMediaSrc } from "../../Utils/StayUpToDate/RenderComponent";

const IndividualImagePostTag = React.memo((props: any) => {
  const {
    data,
    brand_color,
    title,
    redirectVariable,
    isLinks,
    isDashboard,
    type,
    isYoutube,
    isHasMediaUrl,
    productName,
    isCarousel,
    media_url,
  } = props;
  return (
    <Card>
      <a
        href={
          data?.shop_link && data?.shop_link !== ""
            ? data?.shop_link
            : data?.permalink
              ? data?.permalink
              : data?.table_url
        }
        target="_blank"
        onClick={(e) => {
          e.preventDefault();
          props.handleRedirect(
            data?.post_id || data?.table_id,

            getMediaSrc(type, data, isCarousel, media_url),
            title,
            type === "content" || type === "query-content"
              ? data?.content_title || data?.product_name
              : data?.shop_title || data?.product_name || data?.table_name
          );
          if (
            (data?.shop_link && data?.shop_link !== "") ||
            (data?.permalink && data?.permalink !== "") ||
            (data?.table_url && data?.table_url !== "")
          ) {
            window.open(
              data?.shop_link && data?.shop_link !== ""
                ? data?.shop_link
                : data.permalink
                  ? data.permalink
                  : data?.table_url,
              "_blank"
            );
          }
        }}
      >
        {!data?.media_url && !data?.table_image ? (
          data?.platform === "newsletter" || data?.platform === "linkedin" ? (
            <>
              <div
                className={"creator-feed-image-wrapper-links"}
                style={{
                  textAlign: "center",
                  height: "100%",
                  borderRadius: "1rem",
                  color: "#000",
                }}
              >
                <div className="newsletter-media-carousel ">
                  <h4 className="mt-7">
                    {new Intl.DateTimeFormat("en-US", {
                      year: "numeric",
                      day: "numeric",
                      month: "long",
                    }).format(new Date(data?.post_date))}
                  </h4>
                  <h4> {data?.platform === "newsletter" ? "Newsletter" : "LinkedIn Post"} </h4>
                </div>
                <>
                  {data.content_title ? (
                    <p className="product-name">
                      {data.content_title}
                      {/* {post?.product_name} */}
                    </p>
                  ) : (
                    <p className="product-name">
                      {productName}
                      {/* {post?.product_name} */}
                    </p>
                  )}
                </>
              </div>
            </>
          ) : (
            <div className={"creator-feed-card-links not-available"}>
              <BsHourglassSplit className="hourglassSplit" />
              <p>
                Work in progress: This content will be available in a few
                minutes.
              </p>
            </div>
          )
        ) : (
          <>
            <Card.Img
              src={getMediaSrc(type, data, isCarousel, media_url)}
              className={
                isYoutube
                  ? " youtube-feed-height card-image-individual"
                  : isHasMediaUrl
                    ? " not-available media-feed-height card-image-individual"
                    : "card-image-individual"
              }
              loading="eager"
              style={{ backgroundColor: brand_color && brand_color }}
            />

            {productName && <p className="product-name">{productName}</p>}

            {isLinks && (
              <Button className={"template-shop-btn-links-individual"}>
                Shop
              </Button>
            )}
          </>
        )}
      </a>
    </Card>
  );
});

export default IndividualImagePostTag;
