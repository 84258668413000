import { Button, List, Modal } from "antd";
import React, { useState } from "react";
import { FaArrowLeft } from "react-icons/fa";
import "../../../StayUpToDateControls.css";
import { addComponent } from "../../../../../../Utils/SupabaseServices/StayUpToDateDatabase";

const ThisWeekLinksComponents = (props: any) => {
  const {
    username,
    user_id,
    isThisWeekLinksComponentOpen,
    title,
    description,
  } = props;
  const [confirmLoading, setConfirmLoading] = useState(false);
  const getComponentArgs = (title: string) => {
    switch (title) {
      case "This Month's Shoppable Content":
        return { name: title, type: "This Month" };
      case "This Week's Content":
        return { name: title, type: "This Week Content" };
      default:
        return { name: "This Week's Shoppable Content", type: "This Week" };
    }
  };
  const handleOk = async () => {
    setConfirmLoading(true);
    let res;
    const { name, type } = getComponentArgs(title);

    res = await addComponent(
      name,
      "",
      username,
      user_id,
      "true",
      "Check it out!",
      "",
      type
    );
  
    if (res === "true") {
      props.refetch();
      props.setIsShoplinksModalOpen(false);
      props.setIsThisWeekLinksComponentOpen(false);
      setConfirmLoading(false);
    }
  };

  const handleCancel = () => {
    props.setIsShoplinksModalOpen(false);
    props.setIsThisWeekLinksComponentOpen(false);
  };
  const handlePrevious = () => {
    props.setIsThisWeekLinksComponentOpen(false);
    props.setIsShoplinksModalOpen(true);
  };

  return (
    <>
      <Modal
        title={
          <div className="modal-title">
            <button onClick={handlePrevious} className="newbutton">
              <FaArrowLeft />
            </button>
          </div>
        }
        open={isThisWeekLinksComponentOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        confirmLoading={confirmLoading}
        okText="Add"
        data-testid="thisweek-shop"
      >
        <h5 className="title mb-20">{title}</h5>
        <p className="text-center">
          {description}
        </p>
      </Modal>
    </>
  );
};

export default ThisWeekLinksComponents;
