import { Button, Input, List, Modal } from "antd";
import React, { useState } from "react";
import { FaArrowLeft } from "react-icons/fa";
import "../../../StayUpToDateControls.css";
import { addComponent } from "../../../../../../Utils/SupabaseServices/StayUpToDateDatabase";
import QueryFeedCheckboxComponent from "../../../../../../components/CheckboxComponent/QueryFeedCheckboxComponent";

const QueryLinksComponents = (props: any) => {
  const {
    username,
    user_id,
    isQueryLinksComponentOpen,
    isLinksEditModalOpen,
    type,
  } = props;
  const [name, setName] = useState("");
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const [postIds, setPostIds] = useState("");
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [query, setQuery] = useState("");
  const [defaultQuery,setDefaultQuery]=useState("")
  const handleInputChange = (value: string, setter: Function) => {
    setter(value);
    setIsButtonDisabled(!value);
  };
  const handleOk = async () => {
    setConfirmLoading(true);
    const res = await addComponent(
      name,
      "",
      username,
      user_id,
      "false",
      "Check it out!",
      query,
      name,
      "query-links",
      ""
      // postIds.toString()
    );
    if (res === "true") {
      props.refetch();

      props.setIsShoplinksModalOpen(false);
      props.setIsQueryLinksComponentOpen(false);
      setName("");
      setPostIds("");
      setErrorMsg("");
      setQuery("")
      setDefaultQuery("")
      setConfirmLoading(false);

    } else {
      setErrorMsg("Category with this name already exists");
      setConfirmLoading(false);

    }

  };

  const handleCancel = () => {
    props.setIsShoplinksModalOpen(false);
    props.setIsQueryLinksComponentOpen(false);
    setName("");
    setPostIds("");
    setErrorMsg("");
    setQuery("")
    setDefaultQuery("")

  };
  const handlePrevious = () => {
    props.setIsQueryLinksComponentOpen(false);
    props.setIsShoplinksModalOpen(true);
    setName("");
    setPostIds("");
    setErrorMsg("");
    setQuery("")
    setDefaultQuery("")

  };
  function handlePostIds(post_ids: any) {
    setPostIds(post_ids);
  }
  function handleQuery(term: any) {
    setQuery(term);
  }
  return (
    <>
      <Modal
        title={
          <div className="modal-title">
            <button
              onClick={handlePrevious}
              className="newbutton"
            >
              <FaArrowLeft />
            </button>
          </div>
        }
        open={isQueryLinksComponentOpen || isLinksEditModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        okText="Add"
        confirmLoading={confirmLoading}
        okButtonProps={{ disabled: !name }}
        data-testid="phrase-based"
      >
        <h5 className="title mb-15">
          Create a Word or Phrase Based Shoppable Content Carousel{" "}
        </h5>
        <label
           className="bold-label"
          >Carousel Title</label>
        <Input
          placeholder="Enter Title"
          id="phrase-based-title"
          value={name}
          onChange={(e: any) => {
            handleInputChange(e.target.value, setName);
            setErrorMsg("");
          }}
          className="mb-15"
        />
        {errorMsg && <p className="errorMsg">{errorMsg}</p>}

        <QueryFeedCheckboxComponent
          isShopLinks={true}
          username={username}
          user_id={user_id}
          handlePostIds={handlePostIds}
          componentName={name}
          post_ids={postIds}
          handleQuery={handleQuery}
          defaultQuery={defaultQuery}
          type="links"
          setDefaultQuery={setDefaultQuery}
        />
      </Modal>
    </>
  );
};

export default QueryLinksComponents;
