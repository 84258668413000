import React, { Fragment, useEffect, useState } from "react";
import { Button, Carousel, Input, Pagination, Space } from "antd";
import { useLoadSearchFeedCount } from "../../Utils/customHooks/UpToDateContent/useLoadSearchFeedCount";
import { StaticVariables } from "../../Utils/StaticVariables";
import { useLoadSearchFeed } from "../../Utils/customHooks/UpToDateContent/useLoadSearchFeed";
import SearchInput from "../SearchInput/SearchInput";
import "./FeedCheckboxComponent.css";
import { useLoadPostIdsData } from "../../Utils/customHooks/UpToDateContent/useLoadPostIdsData";
import Skeleton from "react-loading-skeleton";
import { supabase } from "../../config/supabaseClient";
import { HiCheck, HiPencil } from "react-icons/hi";
import VideoComponent from "../VideoComponent/VideoComponent";
import { UpdateCombinedText } from "../../Utils/SupabaseServices/ContentDatabase";

const POSTS_PER_PAGE = 10;

const contentOrder = "Default";
const FeedCheckboxComponent = (props: any) => {
  const {
    username,
    user_id,
    isShopLinks,
    componentName,
    post_ids,
    defaultQuery,
    setDefaultQuery,
    type,
    activeFilter, setActiveFilter
  } = props;

  const showSocials =
    type === "content"
      ? [
        "instagram",
        "youtube",
        "tiktok",
        "podcast",
        "pinterest",
        "newsletter",
        "twitter",
        "linkedin",
        "MISC-content",
        // "instaComment"
      ]
      : activeFilter === "both"
        ? StaticVariables().PLATFORMS
        : activeFilter === "content"
          ? [
            "instagram",
            "youtube",
            "tiktok",
            "podcast",
            "pinterest",
            "newsletter",
            "twitter",
            "linkedin",

            "MISC-content",
          ]
          : activeFilter === "products"
            ? ["LTK", "ShopMy", "Amazon", "MISC"]
            : StaticVariables().PLATFORMS;

  const [query, setQuery] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [postIds, setPostIds] = useState<any>();
  useEffect(() => {
    setPostIds(post_ids);
    setQuery(defaultQuery);
    if (defaultQuery === "") {
      refetch();
    }
  }, [post_ids, defaultQuery]);
  const { data: pages }: any = useLoadSearchFeedCount(
    username,
    user_id,
    query,
    POSTS_PER_PAGE,
    showSocials,
    isShopLinks,
    activeFilter
  );
  const {
    isLoading,
    data,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage,
    refetch,
  }: any = useLoadSearchFeed(
    currentPage,
    username,
    user_id,
    query,
    POSTS_PER_PAGE,
    pages?.totalPages || 0,
    pages?.feedCount,
    pages?.postIds,
    showSocials,
    contentOrder,
    isShopLinks,
    activeFilter
  );
  function handleFeed(postIds: any) {
    setPostIds(postIds);
    props.handlePostIds(postIds);
  }

  const FILTER_OPTIONS = [
    { key: "both", label: "Both" },
    { key: "content", label: "Content" },
    { key: "products", label: "Products" },
  ];

  const buttonStyle = (isActive: any) => ({
    backgroundColor: isActive ? "black" : "white",
    color: isActive ? "white" : "black",
  });

  return (
    <>
      <div className="margin-table">
        <AddedContentTable
          post_ids={postIds}
          username={username}
          user_id={user_id}
          setPostIds={setPostIds}
          componentName={componentName}
          handlePostIds={props.handlePostIds}
          handleFeed={handleFeed}
          type={type}
        />
        {type === "links" && (
          <div className="my-2">
            <Space>
              {FILTER_OPTIONS.map(({ key, label }) => (
                <Button
                  key={key}
                  onClick={() => {
                    setActiveFilter(key);
                    setCurrentPage(1)
                  }}
                  style={buttonStyle(activeFilter === key)}
                >
                  {label}
                </Button>
              ))}
            </Space>
          </div>
        )}
        <SearchInput
          placeholder={
            isShopLinks
              ? "Search all shoppable content and products"
              : "Search content"
          }
          id="search-feed"
          value={query}
          onChange={(e: any) => {
            e.preventDefault();
            const elem: any = document.getElementById("search-feed");
            const searchVal = e.target.value;
            setQuery(e.target.value);
            setDefaultQuery(e.target.value);
            setCurrentPage(1);
          }}
          handleKeyUp={(e: any) => {
            e.preventDefault();
            if (e.keyCode === 13) {
              const elem: any = document.getElementById("search-feed");
              const searchVal = e.target.value;
              if (searchVal.length === 0) {
                elem.blur();
                setQuery("");
                return;
              }
              elem.blur();
              setQuery(searchVal);
              setCurrentPage(1);
              setDefaultQuery(searchVal);
            }
          }}
          isActiveFilter={Boolean(query)}
          onSearchClick={() => {
            const elem: any = document.getElementById("search-feed");
            const searchVal = elem.value;
            if (searchVal.length === 0) {
              return;
            }
            elem.blur();
            setQuery(searchVal);
            setDefaultQuery(searchVal);
            setCurrentPage(1);
          }}
          onClearClick={() => {
            const elem: any = document.getElementById("search-feed");
            elem.value = "";
            setQuery("");
            setDefaultQuery("");
            setActiveFilter("both");

            refetch();
            setCurrentPage(1);
            setPostIds(postIds);
          }}
        />
      </div>

      <ContentTable
        username={username}
        user_id={user_id}
        data={data}
        handleFeed={handleFeed}
        postIds={postIds}
        query={query}
        isLoading={isLoading}
        isShopLinks={isShopLinks}
        type={type}
      />

      {pages?.feedCount > POSTS_PER_PAGE && (
        <Pagination
          className="pagination"
          current={currentPage}
          total={pages?.feedCount}
          size="small"
          showSizeChanger={false}
          showQuickJumper
          pageSize={POSTS_PER_PAGE}
          onChange={(page: number) => setCurrentPage(page)}
        />
      )}
    </>
  );
};

const AddedContentTable = (props: any) => {
  const { username, user_id, post_ids, componentName, handleFeed, type } =
    props;
  const [postIds, setPostIds] = useState(post_ids);
  useEffect(() => {
    setPostIds(post_ids);
  }, [post_ids]);
  const {
    isLoading: PostIdFeedLoading,
    data: PostIdData,

    refetch: PostIdDataRefetch,
  }: any = useLoadPostIdsData(
    username,
    user_id,

    postIds
  );

  const onMinus = (post_id: string) => {
    const isChecked = false;
    let updatedPostArray = postIds.filter((postId: any) => postId !== post_id);

    props.setPostIds(updatedPostArray);
    setPostIds(updatedPostArray);
    handleFeed(updatedPostArray);
  };
  const [data, setData] = useState<any>([]);
  const [value, setValue] = useState<string>();
  const [edit, setEdit] = useState(false);
  const [editIndex, setEditIndex] = useState("");

  const handleDone = async (post_id: string) => {
    if (type === "content" || type === "query-content") {
      const { data: user, error } = await supabase
        .from(`${username}`)
        .update({ content_title: value })
        .eq("post_id", post_id)
        .eq("user_id", user_id);
      const { data: newProduct, error: newProductErroor } = await supabase
        .from("product_bank")
        .update({ content_title: value })
        .eq("product_id", post_id)
        .eq("user_id", user_id);
    } else {
      const { data: user, error } = await supabase
        .from(`${username}`)
        .update({ shop_title: value, caption: value, product_name: value })
        .eq("post_id", post_id)
        .eq("user_id", user_id);
      const { data: newProduct, error: newProductErroor } = await supabase
        .from("product_bank")
        .update({ shop_title: value })
        .eq("product_id", post_id)
        .eq("user_id", user_id);
    }

    const { data: newContent, error: newErroor } = await supabase
      .from("content_new")
      .update({ product_name: value })
      .eq("post_id", post_id)
      .eq("user_id", user_id);
    UpdateCombinedText(username, user_id, post_id);
    setEdit(false);
    setEditIndex("");
    PostIdDataRefetch();
  };

  const SkeletonStatBox = () => {
    return (
      <div className="skeletonDiv">
        <Skeleton className="skeletonStyle" />
      </div>
    );
  };

  return (
    <>
      <div className="addedContent">
        <h5 className="addedContentTitle">
          {componentName ? componentName : "Carousel Title"}
        </h5>
        <div className="addedSvg">
          {PostIdData && PostIdData.length ? (
            PostIdData.map((post: any, index: any) => {
              return (
                <React.Fragment key={index}>
                  <div
                    key={index}
                    className="feed-tab-post-container feed-wrapper"
                  >
                    <div
                      className="feed-tab-media-wrapper"
                      style={
                        post?.platform === "newsletter" || post?.platform === "linkedin"
                          ? {
                            textAlign: "center",
                            height: "100%",
                            borderRadius: "1rem",
                          }
                          : {}
                      }
                    >
                      <Button
                        onClick={() => onMinus(post.post_id)}
                        className="carousel-content-remove-btn"
                      >
                        Remove
                      </Button>

                      {post?.platform === "newsletter" ? (
                        <div className="newsletter-media-carousel h-255">
                          <h4 className="mt-7">
                            {new Intl.DateTimeFormat("en-US", {
                              year: "numeric",
                              day: "numeric",
                              month: "long",
                            }).format(new Date(post.post_date))}
                          </h4>
                          <h4> Newsletter</h4>
                        </div>
                      ) : post?.platform === "linkedin" && !post.media_url ? (
                        <div className="newsletter-media-carousel h-255">
                          <h4 className="mt-7">
                            {new Intl.DateTimeFormat("en-US", {
                              year: "numeric",
                              day: "numeric",
                              month: "long",
                            }).format(new Date(post.post_date))}
                          </h4>
                          <h4>LinkedIn Post</h4>
                        </div>
                      ) : (
                        <>
                          {post.media_url ? (
                            <>
                              {post.media_url.split(",").length > 1 &&
                                post.media_url
                                  .split(",")
                                  .every((url: any) => url.trim() !== "") ? (
                                <Carousel
                                  dotPosition="bottom"
                                  className="feed-tab-media-carousel"
                                >
                                  {post.media_url
                                    .split(",")
                                    .map((media_url: any, index: any) => (
                                      <React.Fragment key={index}>
                                        {media_url.includes("video") ? (
                                          <div className="h-255">
                                            <VideoComponent
                                              media_url={media_url}
                                              thumbnail={
                                                post.thumbnail &&
                                                  post.thumbnail.split(",")
                                                    .length &&
                                                  post.thumbnail.split(",")[index]
                                                  ? post.thumbnail.split(",")[
                                                  index
                                                  ]
                                                  : "/assets/dummy_video.png"
                                              }
                                            />
                                          </div>
                                        ) : (
                                          <img
                                            className="feed-tab-media-check"
                                            src={media_url}
                                            alt="post"
                                          />
                                        )}
                                      </React.Fragment>
                                    ))}
                                </Carousel>
                              ) : (
                                <>
                                  {post.media_url.includes("video") ? (
                                    <div className="h-255">
                                      <VideoComponent
                                        media_url={post.media_url}
                                        thumbnail={post.thumbnail}
                                      />
                                    </div>
                                  ) : (
                                    <img
                                      className="feed-tab-media-check"
                                      src={
                                        post.media_url
                                          ? post.media_url
                                          : "/assets/dummy_video.png"
                                      }
                                      alt="post"
                                    />
                                  )}
                                </>
                              )}
                            </>
                          ) : (
                            <img
                              className="feed-tab-media-check"
                              src={
                                post.thumbnail
                                  ? post.thumbnail
                                  : "/assets/dummy_video.png"
                              }
                              alt="post"
                            />
                          )}
                        </>
                      )}
                    </div>

                    {edit === true && editIndex === index ? (
                      <>
                        <Input
                          value={value}
                          onChange={(e: any) => setValue(e.target.value)}
                        />{" "}
                        <HiCheck
                          onClick={() => {
                            handleDone(post.post_id);
                          }}
                        />
                      </>
                    ) : (
                      <p
                        style={{
                          textAlign: post.product_name !== "" ? "left" : "center",
                        }}
                      >
                        {type === "content" || type === "query-content"
                          ? post?.content_title
                            ? post?.content_title
                            : post?.product_name
                          : post?.shop_title
                            ? post?.shop_title
                            : post?.product_name}{" "}
                        <HiPencil
                          onClick={() => {
                            setValue(
                              type === "content" || type === "query-content"
                                ? post?.content_title
                                  ? post?.content_title
                                  : post?.product_name
                                : post?.shop_title
                                  ? post?.shop_title
                                  : post?.product_name
                            );
                            setEdit(true);
                            setEditIndex(index);
                          }}
                        />
                      </p>
                    )}
                  </div>
                </React.Fragment>
              )
            })
          ) : (
            <>
              <>
                <div className="skeletonLoader">
                  {[...Array(4)].map((_, index) => (
                    <SkeletonStatBox />
                  ))}
                </div>
              </>
            </>
          )}
        </div>
      </div>
    </>
  );
};

const ContentTable = (props: any) => {
  const { data, postIds, isLoading, query, isShopLinks, type } = props;
  const [checkedValues, setCheckedValues] = useState<any>([]);

  const onAdd = (post_id: any) => {
    if (!checkedValues.includes(post_id)) {
      const updatedCheckedValues = [post_id, ...checkedValues];
      setCheckedValues(updatedCheckedValues);
      props.handleFeed(updatedCheckedValues);
    }
  };

  const onMinus = (post_id: any) => {
    const updatedCheckedValues = checkedValues.filter(
      (postId: any) => postId !== post_id
    );
    setCheckedValues(updatedCheckedValues);
    props.handleFeed(updatedCheckedValues);
  };

  useEffect(() => {
    setCheckedValues(postIds);
  }, [postIds]);

  return (
    <>
      {!isLoading ? (
        <>
          {data?.pages ? (
            <div className="feed-tab-posts-container">
              {data.pages.map((group: any, pageIndex: number) => (
                <Fragment key={pageIndex}>
                  {group.map((image: any, index: number) => (
                    <div
                      key={index}
                      className="feed-tab-post-container feed-post-wrapper"
                    >
                      <div
                        className="feed-tab-media-wrapper"
                        style={
                          image?.platform === "newsletter" || image?.platform === "linkedin"
                            ? {
                              textAlign: "center",
                              height: "100%",
                              borderRadius: "1rem",
                            }
                            : {}
                        }
                      >
                        {Array.isArray(checkedValues) &&
                          checkedValues?.includes(image.post_id) &&
                          postIds.includes(image.post_id) ? (
                          <Button
                            onClick={() => onMinus(image.post_id)}
                            className="carousel-content-remove-btn"
                          >
                            Remove
                          </Button>
                        ) : (
                          <Button
                            onClick={() => onAdd(image.post_id)}
                            className="carousel-content-add-btn"
                          >
                            Add
                          </Button>
                        )}

                        {image?.platform === "newsletter" ? (
                          <div className="newsletter-media-carousel h-255">
                            <h4 className="mt-7">
                              {new Intl.DateTimeFormat("en-US", {
                                year: "numeric",
                                day: "numeric",
                                month: "long",
                              }).format(new Date(image.post_date))}
                            </h4>
                            <h4> Newsletter</h4>
                          </div>
                        ) : image?.platform === "linkedin" && !image.media_url ? (
                          <div className="newsletter-media-carousel h-255">
                            <h4 className="mt-7">
                              {new Intl.DateTimeFormat("en-US", {
                                year: "numeric",
                                day: "numeric",
                                month: "long",
                              }).format(new Date(image.post_date))}
                            </h4>
                            <h4>LinkedIn Post</h4>
                          </div>
                        ) : (
                          <>
                            {image.media_url ? (
                              <>
                                {image.media_url.split(",").length > 1 &&
                                  image.media_url
                                    .split(",")
                                    .every((url: any) => url.trim() !== "") ? (
                                  <Carousel
                                    dotPosition="bottom"
                                    className="feed-tab-media-carousel newsletter-media-carousel"
                                  >
                                    {image.media_url
                                      .split(",")
                                      .map((media_url: any, index: any) => (
                                        <React.Fragment key={index}>
                                          {media_url.includes("video") ? (
                                            <div className="h-255">
                                              <VideoComponent
                                                media_url={media_url}
                                                thumbnail={
                                                  image.thumbnail &&
                                                    image.thumbnail.split(",")
                                                      .length &&
                                                    image.thumbnail.split(",")[
                                                    index
                                                    ]
                                                    ? image.thumbnail.split(
                                                      ","
                                                    )[index]
                                                    : "/assets/dummy_video.png"
                                                }
                                              />
                                            </div>
                                          ) : (
                                            <img
                                              className="feed-tab-media-check"
                                              src={media_url}
                                              alt="post"
                                            />
                                          )}
                                        </React.Fragment>
                                      ))}
                                  </Carousel>
                                ) : (
                                  <>
                                    {image.media_url.includes("video") ? (
                                      <div className="h-255">
                                        <VideoComponent
                                          media_url={image.media_url}
                                          thumbnail={image.thumbnail}
                                        />
                                      </div>
                                    ) : (
                                      // </div>
                                      <img
                                        className="feed-tab-media-check"
                                        src={
                                          image.media_url
                                            ? image.media_url
                                            : "/assets/dummy_video.png"
                                        }
                                        alt="post"
                                      />
                                    )}
                                  </>
                                )}
                              </>
                            ) : (
                              <img
                                className="feed-tab-media-check"
                                src={
                                  image.thumbnail
                                    ? image.thumbnail
                                    : "/assets/dummy_video.png"
                                }
                                alt="post"
                              />
                            )}
                          </>
                        )}
                      </div>
                      <p
                        style={{
                          textAlign:
                            image.product_name !== "" ? "left" : "center",
                        }}
                      >
                        {type === "content" || type === "query-content"
                          ? image?.content_title
                            ? image?.content_title
                            : image?.product_name
                          : image?.shop_title
                            ? image?.shop_title
                            : image?.product_name}{" "}
                      </p>
                    </div>
                  ))}
                </Fragment>
              ))}
            </div>
          ) : (
            <>
              {isShopLinks ? (
                <p>No shoppable content present for '{query}'</p>
              ) : (
                <p>No content present for '{query}'</p>
              )}
            </>
          )}
        </>
      ) : (
        ""
      )}
    </>
  );
};

export default FeedCheckboxComponent;
