import { supabase } from "../../config/supabaseClient";
import { UpdateCombinedText } from "./ContentDatabase";

export async function getCategories(user_id: any) {
  let liveCategories: any = [];
  let stagingCategories: any = [];
  const { data } = await supabase
    .from("custom_categories")
    .select("*")
    .eq("user_id", user_id)
    .order("index");

  if (data && data.length) {
    data.map((category: any) => {
      if (category.page === "live") {
        liveCategories.push({
          name: category.category_name,
          value: category.value,
          queries: category.custom_queries,
        });
      } else if (category.page === "staging") {
        stagingCategories.push({
          name: category.category_name,
          value: category.value,
          queries: category.custom_queries,
        });
      }
    });

    return { liveCategories, stagingCategories };
  } else return false;
}
export async function getCategoriesCount(user_id: any) {
  const { data } = await supabase
    .from("custom_categories")
    .select("*")
    .eq("user_id", user_id)
    .order("index");

  if (data && data.length) {
    return true;
    // return { liveCategories, stagingCategories };
  } else return false;
}

export async function toggleCategoryValue(
  checked: any,
  categoryName: any,
  pageName: any,
  user_id: any
) {
  await supabase
    .from("custom_categories")
    .update({ value: checked })
    .eq("category_name", categoryName)
    .eq("page", pageName)
    .eq("user_id", user_id);
}
export async function addCategory(user_id: any, categoryName: any) {
  const { data } = await supabase
    .from("custom_categories")
    .select("*")
    .eq("page", "live")
    .eq("user_id", user_id);

  const { data: already } = await supabase
    .from("custom_categories")
    .select("*")
    .eq("page", "live")
    .eq("category_name", `${categoryName}`)
    .eq("user_id", user_id);

  if (already) {
    if (already.length > 0) {
      return false;
    } else {
      const { error } = await supabase.from("custom_categories").insert([
        {
          user_id,
          category_name: `${categoryName}`,
          value: true,
          page: "live",
          index: data ? data?.length : 0,
        },
        {
          user_id,
          category_name: `${categoryName}`,
          value: true,
          page: "staging",
          index: data ? data?.length : 0,
        },
      ]);
      return true;
    }
  }
}

export async function deleteCategory(user_id: any, category: any) {
  const { data } = await supabase
    .from("custom_categories")
    .select("*")
    .eq("user_id", user_id)
    .eq("category_name", category.name);
  const { data: all } = await supabase
    .from("custom_categories")
    .select("*")
    .eq("user_id", user_id)
    .order("index", { ascending: false });
  if (all) {
    for (let a of all) {
      if (a?.index > data?.[0].index) {
        const { data, error } = await supabase
          .from("custom_categories")
          .update({ index: a.index - 1 })
          .eq("user_id", user_id)
          .eq("category_name", a.category_name);
      }
    }
  }

  const { error } = await supabase
    .from("custom_categories")
    .delete()
    .eq("user_id", user_id)
    .eq("category_name", category.name);
}

export async function editCategory(
  user_id: any,
  category: any,
  newCategoryName: any,
  username: any
) {
  const { error } = await supabase
    .from("custom_categories")
    .update({ category_name: newCategoryName })
    .eq("user_id", user_id)
    .eq("category_name", category.name);
  handleReplaceOk(category, newCategoryName, username, user_id);
}

async function handleReplaceOk(
  oldTag: any,
  newTag: any,
  username: any,
  user_id: any
) {
  const { data, error }: any = await supabase
    .from("content_new")
    .select("*")
    .ilike("tagg", `%|${oldTag.name}|%`)
    .eq("user_id", user_id);
  for (let post of data) {
    const post_id = post.post_id;

    // Perform the update for each post
    const { data: ct } = await supabase
      .from("content_new")
      .select("*")
      .eq("post_id", post_id)
      .eq("user_id", user_id);

    // Replace oldTag with newTag in tagg
    // const updatedTagg = ct?.[0].tagg.replace(`|${oldTag}|`, `|${newTag}|`);
    const updatedTagg = ct?.[0].tagg.replace(
      new RegExp(`\\|${oldTag.name}\\|`, "g"),
      newTag.trim() ? `|${newTag.trim()}|` : ""
    );
    const { data: updateData, error: updateError } = await supabase
      .from(`${username}`)
      .update({
        tagg: updatedTagg,
      })
      .eq("post_id", post_id);

    await supabase
      .from("content_new")
      .update({
        tagg: updatedTagg,
      })
      .eq("post_id", post_id)
      .eq("user_id", user_id);
    UpdateCombinedText(username, user_id, post_id);
  }
}

export async function changeCustomCategoriesOrder(newData: any, user_id: any) {
  for (let [idx, i] of newData.entries()) {
    const { data } = await supabase
      .from("custom_categories")
      .update({ index: idx })
      .eq("category_name", i.name)
      .eq("user_id", user_id);
  }
}

export async function updateDefaultSubcategory(
  categoryName: any,
  defaultCategory: any,
  user_id: any
) {
  const { data: queries } = await supabase
    .from("custom_categories")
    .select("*")
    .eq("category_name", categoryName)
    .eq("user_id", user_id)
    .limit(1);

  const { data } = await supabase
    .from("custom_categories")
    .update({ default_query: defaultCategory })
    .eq("category_name", categoryName)
    .eq("user_id", user_id);
}

export async function updateExistingSubcategory(
  categoryName: any,
  defaultCategory: any,
  user_id: any,
  value: any
) {
  const { data, error } = await supabase
    .from("custom_categories")
    .update({ is_existing: value })
    .eq("default_query", defaultCategory)
    .eq("category_name", categoryName)
    .eq("user_id", user_id);
}
