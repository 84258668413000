import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { loginEmail } from "../../../Utils/SupabaseServices/UserDatabase";
import ErrorMsg from "../../../components/ErrorMsg/errorMsg";
import track, { TRACKING_TYPES } from "../../../Utils/SupabaseServices/Track";
import { Spin } from "antd";
import { useAuth } from "../../../hooks/useAuth";
import { supabase } from "../../../config/supabaseClient";

const UsernameLogin = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { user } = useAuth();
  const user_id = user?.id || "";
  const [signedUp, setSignedUp] = useState(false);
  const [username, setUsername] = useState<any>();
  const [password, setPassword] = useState<any>();

  const [error, setError] = useState<any>("");
  // const [phnWithCode, setPhnWithCode] = useState<any>("");
  const [resendOTP, setResendOTP] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    track(location.pathname, TRACKING_TYPES.PAGE, {
      pathname: location.pathname,
      user_id: user_id || "",
    });
  }, []);

  function SubmitButton(props: any) {
    return <button className="submit-btn">{props.name}</button>;
  }
  async function signUpWithUsername(e: any) {
    // const email = `${username}@gmail.com`;
    // const password = "12345";
    e.preventDefault();
    const { data, error } = await supabase
      .from("user")
      .select("email")
      .eq("username", username);
    const email = data?.[0]?.email;
    const signInData = await loginEmail(email, password);

    if (!signInData) {
      navigate("/404");
    } else if (signInData === "error") {
      setError("Invalid login credentials!");
    } else if (signInData) {
      navigate(`/dashboard/${signInData}`);
    }
  }

  return (
    <Spin spinning={loading}>
      <div className="auth-phone-signup-container">
        <form className="input-form" onSubmit={signUpWithUsername}>
          <div className="input-div">
            <input
              placeholder="Enter Username"
              onChange={(e: any) => {
                if (error) {
                  setError("");
                }
                setUsername(e.target.value);
              }}
              required
            />
          </div>
          <div className="input-div">
            {" "}
            <input
              type="password"
              placeholder="Password"
              onChange={(e: any) => setPassword(e.target.value)}
              required
            />
          </div>{" "}
          {error ? <ErrorMsg error={error} /> : ""}
          <SubmitButton name="Sign In" />
        </form>
      </div>
    </Spin>
  );
};

export default UsernameLogin;
