import React from 'react'
import IntegrationsBox from '../../../components/IntegrationsBox/IntegrationsBox'
import { useAuth } from '../../../hooks/useAuth';
import AccountSkeleton from '../../../components/skeleton/DashboardView/accountSkeleton';
import { StaticVariables } from '../../../Utils/StaticVariables';

function SocialIntegration(props: any) {
    const { username, userData, userLoading } = props;
    const { user: sessionUser } = useAuth();
    return (<>
        {userLoading ? (
            <AccountSkeleton />
        ) : (
            <div className="details">
                <h1 className="dashboard-mobile-hide">
                    {StaticVariables().PLATFORMS_INTEGRATION_TITLE}
                </h1>
                <div className="account-values-profiles">
                    <IntegrationsBox
                        subscription_type={userData?.subscription_type}
                        first_connected_platform={userData?.first_connected_platform}
                    />
                </div>
            </div>)}
    </>
    )
}

export default SocialIntegration