import React from "react";
import { Carousel, Row, Col } from "antd";
import { StaticCompnents } from "../../../../Utils/StaticComponent";
import "../../Feed/feedTab.css";

const FeedContentPost = (props: any) => {
  const { image } = props;
  const platformIcons: any = {
    ShopMy: StaticCompnents().ShopMyIcon,
    Amazon: StaticCompnents().AmazonIcon,
    LTK: StaticCompnents().LTKIcon,
    instagram: StaticCompnents().InstagramIcon,
    pinterest: StaticCompnents().PinterestIcon,
    twitter: StaticCompnents().TwitterIcon,
    tiktok: StaticCompnents().TiktokIcon,
    newsletter: StaticCompnents().NewsletterIcon,
    podcast: StaticCompnents().PodcastIcon,
    linkedin:StaticCompnents().LinkedinIcon
    // Add other platforms here
  };
  const renderPlatformIcon = (platform: any) => {
    const IconComponent = platformIcons[platform];

    if (IconComponent) {
      return <IconComponent />;
    }
    return null; // Return null if platform doesn't exist or is invalid
  };
  return (
    <div
      className="feed-tab-row-media-wrapper"
      style={
        image.platform === "newsletter" || image.platform === "linkedin"
          ? {
              backgroundColor: "#efeded",
              textAlign: "center",
              height: "255px",
            }
          : {}
      }
    >
      {image.platform === "newsletter" ? (
         <div
         style={{
           alignSelf: "center",
           backgroundColor: "#f5f5f5", // Light grey background
           display: "flex",
           alignItems: "center",
           justifyContent: "center",
           padding: "10px",
           height: "100%",
           color: "#000",
           flexDirection: "column", // Stack items vertically
           textAlign: "center", // Center text inside the div
         }}
       >
         <span className="date" style={{ display: "block" }}>
           {new Intl.DateTimeFormat("en-US", {
             year: "numeric",
             day: "numeric",
             month: "long",
           }).format(new Date(image.post_date))}
         </span>
         <span className="newsletter-label" style={{ display: "block" }}>
           Newsletter
         </span>
         {/* {post.caption} */}
       </div>
        // <div>
        //   <h4 className="mt-7">
        //     {new Intl.DateTimeFormat("en-US", {
        //       year: "numeric",
        //       day: "numeric",
        //       month: "long",
        //     }).format(new Date(image.post_date))}
        //   </h4>
        //   <h4> Newsletter</h4>
        // </div>
      ) : image.platform === "linkedin" ? (
        <div
        style={{
          alignSelf: "center",
          backgroundColor: "#f5f5f5", // Light grey background
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          padding: "10px",
          height: "100%",
          color: "#000",
          flexDirection: "column", // Stack items vertically
          textAlign: "center", // Center text inside the div
        }}
      >
        <span className="date" style={{ display: "block" }}>
          {new Intl.DateTimeFormat("en-US", {
            year: "numeric",
            day: "numeric",
            month: "long",
          }).format(new Date(image.post_date))}
        </span>
        <span className="newsletter-label" style={{ display: "block" }}>
          LinkedIn Post
        </span>
        {/* {post.caption} */}
      </div>
        // <div>
        //   <h4 className="mt-7">
        //     {new Intl.DateTimeFormat("en-US", {
        //       year: "numeric",
        //       day: "numeric",
        //       month: "long",
        //     }).format(new Date(image.post_date))}
        //   </h4>
        //   <h4> LinkedIn Post</h4>
        // </div>
      ) : (
        <>
          {image.media_url ? (
            <>
              {image.media_url.split(",").length > 1 &&
              image.media_url
                .split(",")
                .every((url: any) => url.trim() !== "") ? (
                <Carousel
                  dotPosition="bottom"
                  className="feed-tab-media-carousel-content"
                >
                  {image.media_url
                    .split(",")
                    .map((media_url: any, index: any) => (
                      <React.Fragment key={index}>
                        {media_url.includes("video") ? (
                          <video
                            className="feed-tab-media mh-350"
                            autoPlay
                            playsInline
                            muted
                            loop
                            poster={
                              image.thumbnail &&
                              image.thumbnail.split(",").length &&
                              image.thumbnail.split(",")[index]
                                ? image.thumbnail.split(",")[index]
                                : "/assets/dummy_video.png"
                            }
                          >
                            <source src={media_url} />
                          </video>
                        ) : (
                          <img
                            className="feed-tab-media mh-350"
                            src={media_url}
                            alt="post"
                          />
                        )}
                      </React.Fragment>
                    ))}
                </Carousel>
              ) : (
                <>
                  {image.media_url.includes("video") ? (
                    <video
                      className="feed-tab-media mh-350"
                      autoPlay
                      playsInline
                      muted
                      loop
                      poster={
                        image.thumbnail
                          ? image.thumbnail
                          : "/assets/dummy_video.png"
                      }
                    >
                      <source src={image.media_url} />
                    </video>
                  ) : (
                    <img
                      className="feed-tab-media mh-350"
                      src={image.media_url}
                      alt="post"
                    />
                  )}
                </>
              )}
            </>
          ) : (
            <img
              className="feed-tab-media"
              src={
                image.thumbnail ? image.thumbnail : "/assets/dummy_video.png"
              }
              alt="post"
            />
          )}
        </>
      )}
      <>
        <Row className="creator-feed-content">
          {new Date(image.post_date).getFullYear() > 2000 && (
            <Col className="creator-feed-content-date">
              {new Intl.DateTimeFormat("en-US", {
                year: "numeric",
                day: "numeric",
                month: "long",
              }).format(new Date(image.post_date))}
            </Col>
          )}
          {image.platform && (
            <Col className="creator-feed-content-icon">
              {renderPlatformIcon(image.platform)}
            </Col>
          )}
        </Row>
      </>
    </div>
  );
};

export default FeedContentPost;
