import { Button, Col, Pagination, Row, Tabs } from "antd";
import React, { useState } from "react";
import SearchInput from "../../../components/SearchInput/SearchInput";
import { useAuth } from "../../../hooks/useAuth";
import { useParams } from "react-router-dom";
import { useLoadProductBankPages } from "../../../Utils/customHooks/UpToDateContent/useLoadProductBankCount";
import { useLoadProductBankContent } from "../../../Utils/customHooks/UpToDateContent/useLoadProductBankConent";
import { ContentSkeleton } from "../../../components/skeleton/DashboardView/feedSkeleton";
import ProductBankSearchTable from "./ProductBankSearchTable";
import { StaticVariables } from "../../../Utils/StaticVariables";
import { useLoadProductBankPlatform } from "./useLoadProductBankPlatform";
import AddProductModal from "./AddProductModal";
import { useLoadLinkFilter } from "./useLoadLinkFilter";
import "react-loading-skeleton/dist/skeleton.css";
import Skeleton from "react-loading-skeleton";
import { PlusOutlined } from "@ant-design/icons";

const POSTS_PER_PAGE = 10;

const ProductBank = (props: any) => {
  const { user } = useAuth();
  const [addProductModalShow, setAddProductModalShow] = useState(false);
  const { username } = useParams();
  const user_id = user?.id || "";
  const [activeKey, setActiveKey] = useState<any>("Product Bank");
  const [isLimitError, setLimitError] = useState(false);
  const [query, setQuery] = useState<string | null>(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedProductFilter, setSelectedProductFilter] = useState("All");
  const [selectedProductLinkFilter, setSelectedProductLnikFilter] =
    useState("AllLinks");

  const {
    isLoading: pagesLoading,
    data: pages,
    refetch: refetchCount,
  } = useLoadProductBankPages(
    user_id || "",
    POSTS_PER_PAGE,
    query,
    username || "",
    selectedProductFilter,
    selectedProductLinkFilter
  );
  const { data, isLoading, isError, error, refetch } =
    useLoadProductBankContent(
      user_id || "",
      username || "",
      currentPage,
      POSTS_PER_PAGE,
      pages?.totalPages,
      query,
      selectedProductFilter,
      selectedProductLinkFilter
    );

  const handlePageDataChange = (page: any) => {
    setCurrentPage(page);
  };

  const handleProductFilter = (
    e: React.MouseEvent<HTMLElement, MouseEvent>,
    productFilter: string
  ) => {
    e.preventDefault();
    if (productFilter !== selectedProductFilter) {
      setSelectedProductFilter(productFilter);
      setSelectedProductLnikFilter("AllLinks");
      setCurrentPage(1);
    }
  };

  const handleProductLinkFilter = (e: any, linkFilter: string) => {
    e.preventDefault();
    // setSelectedProductFilter("All");

    if (linkFilter !== selectedProductLinkFilter) {
      setSelectedProductLnikFilter(linkFilter);
      setCurrentPage(1);
    }
  };
  const { isLoading: platformLoading, data: productFilterItems } =
    useLoadProductBankPlatform(
      username || "",
      user_id,
      selectedProductLinkFilter,
      query as string
    );

  const {
    isLoading: filterLoading,
    data: linkFilterItems,
    refetch: linkFilterRefetch,
  } = useLoadLinkFilter(username || "",user_id, selectedProductFilter, query as string);
  return (
    <div className="d-side">
      <div className="details">
        <h1 className="dashboard-mobile-hide mb-20px">
          {StaticVariables().PRODUCT_BANK}
        </h1>
        <div className="detail-margin">
          <Row gutter={16} align="middle" wrap={false}>
            {pages?.feedCount != 0 && (
          
              <Col>
                <Button
                  icon={<PlusOutlined />}
                  className="product-bank-add-btn-icon"
                  onClick={() => setAddProductModalShow(true)}
                  id="add-product-icon"
                />
              </Col>
            )}
            <Col flex="auto">
              <SearchInput
                placeholder={`Search all products`}
                id="search-feed"
                handleKeyUp={(e: any) => {
                  e.preventDefault();
                  if (e.keyCode === 13) {
                    const elem: any = document.getElementById("search-feed");
                    const searchVal = e.target.value;
                    if (searchVal.length === 0) {
                      elem.blur();
                      setCurrentPage(1);
                      setSelectedProductFilter("All");
                      setSelectedProductLnikFilter("AllLinks");
                      setQuery(null);
                      return;
                    }
                    elem.blur();
                    setQuery(searchVal);
                    setCurrentPage(1);
                    setSelectedProductFilter("All");
                    setSelectedProductLnikFilter("AllLinks");
                  }
                }}
                isActiveFilter={Boolean(query)}
                onSearchClick={() => {
                  const elem: any = document.getElementById("search-feed");
                  const searchVal = elem.value;
                  if (searchVal.length === 0) {
                    return;
                  }
                  elem.blur();
                  setQuery(searchVal);
                  setCurrentPage(1);
                  setSelectedProductFilter("All");
                  setSelectedProductLnikFilter("AllLinks");
                }}
                onClearClick={() => {
                  const elem: any = document.getElementById("search-feed");
                  elem.value = "";
                  setQuery(null);
                  setCurrentPage(1);
                  setSelectedProductFilter("All");
                  setSelectedProductLnikFilter("AllLinks");
                }}
              />
            </Col>
          </Row>{" "}
          <div className="filter-section">
            {platformLoading ? ( // Replace `isLoading` with your actual loading state variable
              <div className="segmented-container">
                {[...Array(4)].map((_, index) => (
                  <Skeleton
                    key={index}
                    height={40} // Adjust height to match the button size
                    width={100} // Adjust width to match the button size
                    borderRadius={10} // For round edges
                    style={{ marginBottom: "10px" }}
                  /> // Add spacing between skeletons/>
                ))}
              </div>
            ) : (
              <>
                {productFilterItems && productFilterItems.length > 1 && (
                  <p>Filter By Platform</p>
                )}
                <div className="segmented-container">
                  {productFilterItems &&
                    productFilterItems?.map(
                      (elem: { key: string; label: string }) => (
                        <React.Fragment key={elem.key}>
                          <Button
                            data-testid={elem.key}
                            onClick={(e) => handleProductFilter(e, elem.key)}
                            style={{
                              background:
                                selectedProductFilter === elem.key
                                  ? "#3b3b3b"
                                  : "white",
                              color:
                                selectedProductFilter === elem.key
                                  ? "white"
                                  : "black",
                            }}
                          >
                            {elem.label}
                          </Button>
                        </React.Fragment>
                      )
                    )}
                </div>
              </>
            )}
          </div>
          <div className="filter-section">
            {filterLoading ? ( // Replace `isLoading` with your actual loading state variable
              <div className="segmented-container">
                {[...Array(4)].map((_, index) => (
                  <Skeleton
                    key={index}
                    height={40} // Adjust height to match the button size
                    width={100} // Adjust width to match the button size
                    borderRadius={10} // For round edges
                    style={{ marginBottom: "10px" }}
                  /> // Add spacing between skeletons/>
                ))}
              </div>
            ) : (
              <>
                {linkFilterItems && linkFilterItems.length > 1 && (
                  <>
                    <p>Filter By Link Type</p>
                    <div className="segmented-container">
                      {linkFilterItems?.map(
                        (elem: { key: string; label: string }) => (
                          <React.Fragment key={elem.key}>
                            <Button
                              data-testid={elem.key}
                              onClick={(e) =>
                                handleProductLinkFilter(e, elem.key)
                              }
                              style={{
                                background:
                                  selectedProductLinkFilter === elem.key
                                    ? "#3b3b3b"
                                    : "white",
                                color:
                                  selectedProductLinkFilter === elem.key
                                    ? "white"
                                    : "black",
                              }}
                            >
                              {elem.label}
                            </Button>
                          </React.Fragment>
                        )
                      )}
                    </div>
                  </>
                )}
              </>
            )}
          </div>
        </div>

        {pagesLoading || isLoading ? (
          <ContentSkeleton />
        ) : (
          <>
            <ProductBankSearchTable
              username={username}
              user_id={user_id}
              activeKey={activeKey}
              data={data?.pages?.[0]}
              setLimitError={setLimitError}
              filter={query}
              refetchData={refetch}
              currentPage={currentPage}
              refetchCount={refetchCount}
              linkFilterRefetch={linkFilterRefetch}
              setSelectedProductLnikFilter={setSelectedProductLnikFilter}
            />
            {pages?.feedCount > POSTS_PER_PAGE && pages?.feedCount !== 0 ? (
              <Pagination
                showQuickJumper
                current={currentPage}
                pageSize={POSTS_PER_PAGE}
                total={pages?.feedCount || 0}
                onChange={handlePageDataChange}
                showSizeChanger={false}
                className="pagination-data-change"
              />
            ) : (
              ""
            )}
          </>
        )}
      </div>
      <AddProductModal
        username={username}
        user_id={user_id}
        addProductModalShow={addProductModalShow}
        setAddProductModalShow={setAddProductModalShow}
        refetchData={refetch}
        refetchCount={refetchCount}
        linkFilterRefetch={linkFilterRefetch}
      />
    </div>
  );
};

export default ProductBank;
