import { useInfiniteQuery } from "react-query";
import { supabase } from "../../config/supabaseClient";
import {
  extractKeywords,
  // fetchSearchResults,
  getAdLength,
  getEmbedding,
  getExtraNecIds,
  getQuerySuggestions,
  removeQualifiers,
} from "../SupabaseServices/ContentDatabase";
import { useRef, useState } from "react";
import { StaticVariables } from "../StaticVariables";
// import { useSearchData } from "./useLoadSearchbarData";

/**
 * Load content
 */

export function useLoadFeedContentEmbedding(
  username: any,
  user_id: any,
  queryTerm: any,
  category: any,
  showAds: any,
  filter: any,
  activeHighlights: any,
  postsPerPage: number,
  totalPages: number,
  feedCount: any,
  postIds: any[],
  openAiSearch: boolean,
  extraId: any[],
  trending: any,
  avgWords: any,
  showSocials: any[],
  threshold: any,
  contentOrder: any
) {
  postsPerPage = 10;
  queryTerm = queryTerm.toLowerCase().replace(/\s+/g, " ").trim();
  category = category && category.trim();
  trending = trending?.trim();
  // const isNecCalledRef = useRef(false);
  const isNecCalledRef = useRef({
    current: false,
    currentQueryTerm: "",
  });
  function binarySearch(posts: any, targetScore: any) {
    let left = 0;
    let right = posts.length - 1;
    let resultIndex = -1;

    while (left <= right) {
      const mid = Math.floor((left + right) / 2);

      if (posts[mid].similarity > targetScore) {
        // Update the resultIndex and search on the left side
        resultIndex = mid;
        left = mid + 1;
      } else {
        // Search on the right side
        right = mid - 1;
      }
    }

    return resultIndex;
  }
  const [adsIndex, setAdsIndex] = useState<any>(0);
  async function getPaginatedFeed({ pageParam = 1 }) {
    let start = ((pageParam || 1) - 1) * postsPerPage;
    let end = start + postsPerPage - 1;
    let data,
      error = null;
    if (queryTerm !== isNecCalledRef.current.currentQueryTerm) {
      // If the query term has changed, reset isNecCalledRef.current to false
      isNecCalledRef.current.current = false;
      isNecCalledRef.current.currentQueryTerm = queryTerm;
    }
    await getAdLength(user_id[0], queryTerm, filter, username).then(
      (adCount: any) => {
        if (adCount === 1) {
          setAdsIndex(0);
        } else {
          if (adsIndex + 1 >= adCount) {
            setAdsIndex(0);
          } else {
            // Check if end + 1 is a multiple of 10
            if ((end + 1) % 10 === 0) {
              setAdsIndex((prev: any) => prev + 1);
            }
          }
        }
      }
    );

    // await getAdLength(user_id[0], queryTerm, filter, username).then(
    //   (adCount: any) => {
    //     if (adCount === 1) {
    //       setAdsIndex(0);
    //     } else {
    //       if (adsIndex + 1 >= adCount) {
    //         setAdsIndex(0);
    //       } else {
    //         setAdsIndex((prev: any) => prev + 1);
    //       }
    //     }
    //   }
    // );

    // const pgQuery = queryTerm.toLowerCase().replace(/\s+/g, ' | ');
    // await extractKeywords(queryTerm);
    const filteredArray = filter.filter((item: any) => item !== "ALL");
    if (user_id && user_id.length === 1) {
      if (category && category === "searchbar") {
        const elementsCount = 5000;

        const connectingWords = StaticVariables().connectingWords;

        // Split the sentence into words
        let words = queryTerm.split(" ");
        if (words.includes("shop") || words.includes("buy")) {
          contentOrder = "shoppable";
          words = words.filter(
            (word: any) => word !== "shop" && word !== "buy"
          );
        }
        // Filter out connecting words
        let filteredWords = words.filter(
          (word: any) => !connectingWords.includes(word.toLowerCase())
        );

        // Join the filtered words back into a sentence

        const result = filteredWords.join(" ");
        const pgQuery = result.toLowerCase().replace(/\s+/g, " & ");
        const embedding = await getEmbedding(pgQuery);

        const { data: searchResult, error: searchError } = await supabase.rpc(
          // "semantic_sssearch",
          // "semanticc_multipleadssearch",
          "semanticcc_embedpostiddddto",
          {
            query_string: pgQuery,
            query_embedding: embedding,
            similarity_threshold: threshold,

            // match_count: elementsCount,
            start_number: start,
            end_num: end,
            userid: user_id[0],
            username: username,
            socials: showSocials,

            idx: adsIndex || 0,
            show_ads: (end + 1) % 10 === 0 ? true : false,
            postids: postIds,
            content_order: contentOrder.split(" ")[0].toLowerCase(), // true if end + 1 is a multiple of 10
          }
        );
        data = searchResult;
        error = searchError;
        // }
      }

      const resultArray = [...data];

      if (
        resultArray &&
        resultArray.length >= 0 &&
        Array.isArray(resultArray)
      ) {
        return resultArray;
      } else if (error) {
        throw new Error(error.message);
      } else {
        throw new Error("Failed to fetch posts");
      }
    }
  }

  category = category;
  return useInfiniteQuery(
    [
      "paginatedEmbeddingFeedPosts",
      queryTerm,
      filter,
      trending,
      category,
      user_id,
    ],
    getPaginatedFeed,
    {
      getNextPageParam: (lastPage, pages) => {
        if (pages.length < totalPages) {
          return pages.length + 1;
        } else {
          return undefined;
        }
      },
      enabled: Boolean(totalPages),

      // refetchOnWindowFocus: true,
    }
  );
}
