import { useInfiniteQuery, useQuery } from "react-query";
import { StaticVariables } from "../../StaticVariables";
import { supabase } from "../../../config/supabaseClient";
import { useState } from "react";

/**
 * Load content
 */

export function useLoadQueryFeed(
  username: any,
  user_id: any,
  queryTerm: any,

  showSocials: any[],

  contentOrder: any,
  isShopLinks: any,
  removedPostIds: any,
  name: any
) {
  const [cachedData, setCachedData] = useState([]);

  queryTerm = queryTerm && queryTerm.toLowerCase().replace(/\s+/g, " ").trim();

  async function getPaginatedFeed({ pageParam = 1 }) {
    let data,
      error: any = null;
    if (removedPostIds && removedPostIds !== "") {
      removedPostIds = Array.from(
        new Set(removedPostIds?.split(",").filter((elem: any) => elem !== ""))
      );
    }
    if (isShopLinks) {
      if (queryTerm) {
        const connectingWords = StaticVariables().connectingWords;

        let words = queryTerm.split(" ");
        let singleWords: any = [];
        if (
          words.length === 1 &&
          !StaticVariables().SEARCH_EXCEPTIONS.includes(queryTerm)
        ) {
          const suffixRegex = /(s)$/i;
          if (suffixRegex.test(words[0])) {
            words = [`${words[0].replace(suffixRegex, "")}`];
          }
        }

        if (words.includes("shop") || words.includes("buy")) {
          contentOrder = "shoppable";
          words = words.filter(
            (word: any) => word !== "shop" && word !== "buy"
          );
        }

        let filteredWords = words.filter(
          (word: any) => !connectingWords.includes(word.toLowerCase())
        );
        singleWords = filteredWords.map((word: any) => `%%${word}%%`);

        const result = filteredWords.join(" ");
        const pgQuery = result.toLowerCase().replace(/\s+/g, " & ");

        const { data: searchResult = [], error: searchError } =
          await supabase.rpc("getquerybasedcontenntshopp", {
            query_string: pgQuery,

            userid: user_id,
            username: username,
            socials: showSocials,

            content_order: contentOrder.split(" ")[0].toLowerCase(),

            single: [`%%${queryTerm}%%`],
          });
        // const { data: com = [], error } = await supabase.rpc(
        //   "get_productbankpostsearchalllsss",
        //   {
        //     query_string: result ? result : "",
        //     userid: user_id,
        //     query_tagg: result,
        //   }
        // );
        data = [...searchResult];
        // data = [...searchResult, ...com];
        //   data = [
        //     ...searchResult,
        //     ...com.filter(
        //         (comPost:any) => !searchResult.some((searchPost:any) => searchPost.post_id === comPost.post_id)
        //     )
        // ];
      }
    } else {
      if (queryTerm) {
        const connectingWords = StaticVariables().connectingWords;

        let words = queryTerm.split(" ");
        let singleWords: any = [];
        if (
          words.length === 1 &&
          !StaticVariables().SEARCH_EXCEPTIONS.includes(queryTerm)
        ) {
          const suffixRegex = /(s)$/i;
          if (suffixRegex.test(words[0])) {
            words = [`${words[0].replace(suffixRegex, "")}`];
          }
        }

        if (words.includes("shop") || words.includes("buy")) {
          contentOrder = "shoppable";
          words = words.filter(
            (word: any) => word !== "shop" && word !== "buy"
          );
        }

        let filteredWords = words.filter(
          (word: any) => !connectingWords.includes(word.toLowerCase())
        );
        singleWords = filteredWords.map((word: any) => `%%${word}%%`);

        const result = filteredWords.join(" ");
        const pgQuery = result.toLowerCase().replace(/\s+/g, " & ");
        const { data: searchResult, error: searchError } = await supabase.rpc(
          "getquerybasedcontenntt",
          {
            query_string: pgQuery,

            userid: user_id,
            username: username,
            socials: showSocials,

            content_order: contentOrder.split(" ")[0].toLowerCase(),

            single: [`%%${queryTerm}%%`],
          }
        );
        data = [...searchResult];
      }
    }
    let filteredArray;
    if (removedPostIds) {
      filteredArray = data?.filter(
        (obj: any) => !removedPostIds.includes(obj.post_id)
      );
    }
    const resultArray = filteredArray ? [...filteredArray] : [...(data || [])];
    if (resultArray && resultArray.length >= 0 && Array.isArray(resultArray)) {
      return resultArray;
    } else if (error) {
      throw new Error(error.message);
    } else {
      throw new Error("Failed to fetch posts");
    }
  }

  const queryResult = useQuery(
    ["paginatedQueryFeed", queryTerm, user_id, removedPostIds, name],
    getPaginatedFeed,
    {
      enabled: Boolean(queryTerm),

      cacheTime: Infinity,
      onSuccess: (data: any) => {
        setCachedData(data);
      },
      onError: () => {},
      initialData: () => cachedData,
    }
  );

  return queryResult;
}
