import React, {
  createContext,
  useState,
  useEffect,
  useContext,
  ReactNode,
} from "react";

type DomLoadedContextType = boolean;

const DomLoadedContext = createContext<DomLoadedContextType>(false);

interface DomLoadedProviderProps {
  children: ReactNode;
}

export const DomLoadedProvider: React.FC<DomLoadedProviderProps> = ({
  children,
}) => {
  const [domLoaded, setDomLoaded] = useState<boolean>(false);

  useEffect(() => {
    const handleDomContentLoaded = () => {
      setDomLoaded(true);
    };

    if (document.readyState === "complete") {
      handleDomContentLoaded();
    } else {
      window.addEventListener("load", handleDomContentLoaded);
    }

    return () => window.removeEventListener("load", handleDomContentLoaded);
  }, []);

  return (
    <DomLoadedContext.Provider value={domLoaded}>
      {children}
    </DomLoadedContext.Provider>
  );
};

export const useDomLoaded = (): DomLoadedContextType => {
  return useContext(DomLoadedContext);
};
