import dayjs from "dayjs";
import { supabase } from "../../config/supabaseClient";
import utc from "dayjs/plugin/utc"; // Import the UTC plugin

// Extend dayjs with the UTC plugin
dayjs.extend(utc);
type HandleFileUploadProps = {
  info: any;
  username: string;
  productName?: string;
  setFileList: (fileList: any[]) => void;
  setProductImage: (url: string) => void;
  storageName: string;
};

export const handleProductFileUpload = async ({
  info,
  username,
  productName,
  setFileList,
  setProductImage,
  storageName,
}: HandleFileUploadProps) => {
  const { file } = info;
  try {
    file.status = "uploading";
    // Handle file list update
    const fileListCopy = [...info.fileList].slice(-1);
    setFileList(fileListCopy);

    // Simulate uploading status
    // setTimeout(() => {
    //   file.status = "done";
    // }, 1000);

    const heading = productName || "new_product";
    const fileName = info.file.name;
    const mediaType = file.type.split("/")[0];
    const mediaExt = file.type.split("/")[1];

    // Sanitize file name
    const sanitizedFileName = fileName
      .replace(/['",;:/?|\_=\-\\]/g, "")
      .replace(/\s+/g, "-")
      .replace(/[^a-zA-Z0-9\-]/g, "");
    // const timestamp = Date.now();

    const timestamp = Date.now(); // Unique timestamp for every upload

    const post_id = `${sanitizedFileName}-${timestamp}`;
    // const parsedDate = dayjs(); // Assuming you have a valid date here
    // const formattedDate = `${parsedDate.format("ddd MMM DD YYYY")} ${parsedDate.hour() + 4
    //     }:${parsedDate.minute()
    //     }:${parsedDate.second()
    //     } GMT+0530`;

    // // Correctly format and parse the date using day.js
    // let pacificDateTime = new Date(formattedDate)

    // const post_date = pacificDateTime?.toISOString()?.slice(0, 19).replace("T", " ") +
    //     "-00:00";
    // const datePart = post_date.split(" ")[0];
    // // Upload file to Supabase
    const parsedDate = dayjs(); // Replace with your input date if needed

    // Convert to desired time zone and format
    const formattedDate = parsedDate
      .utcOffset(330)
      .format("ddd MMM DD YYYY HH:mm:ss [GMT+0530]");
    let pacificDateTime = new Date(formattedDate);
    const post_date =
      pacificDateTime?.toISOString()?.slice(0, 19).replace("T", " ") + "-00:00";
    const datePart = post_date.split(" ")[0];
    // Parse back to Day.js object for ISO handling
    // const pacificDateTime = dayjs(formattedDate, "ddd MMM DD YYYY HH:mm:ss [GMT+0530]");

    // // Format for SQL-style date (YYYY-MM-DD HH:mm:ss-00:00)
    // const post_date = pacificDateTime.format("YYYY-MM-DD HH:mm:ss") + "-00:00";
    // const datePart = formattedDate.toISOString().split(" ")[0];

    // Log results

    const { data, error } = await supabase.storage
      .from(`${storageName}`)
      .upload(
        `${username}/${datePart}/${mediaType}${post_id}.${mediaExt}`,
        file.originFileObj as File
      );

    if (error) {
      throw error;
    }
    file.status = "done";
    // Retrieve public URL
    const { data: publicUrlData } = await supabase.storage
      .from(`${storageName}`)
      .getPublicUrl(
        `${username}/${datePart}/${mediaType}${post_id}.${mediaExt}`
      );

    setProductImage(publicUrlData?.publicUrl || "");
    return publicUrlData
  } catch (error) {
    console.error("Upload Error:", error);
  }
};
