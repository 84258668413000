import React, { useEffect, useState } from "react";
import { Radio } from "antd";
import { updateCheckedType } from "../../../Utils/SupabaseServices/ControlsDatabase";
import { useLoadContentTypeCount } from "./useLoadContentTypeCount";

const ContentTypeRadio = (props: any) => {
  const { username, checkedType, user_id, page } = props;
  const typeList = ["All", "Feed", "Landing Page"];
  const { isLoading, data } = useLoadContentTypeCount(
    username,
    user_id,
    typeList,
    checkedType
  );
  const [selectedType, setSelectedType] = useState("");
  useEffect(() => {
    if (!selectedType) {
      if (
        data &&
        data.disabledTypes.length &&
        data.disabledTypes.includes(checkedType)
      ) {
        setSelectedType(data?.activeType);
      } else {
        setSelectedType(checkedType);
      }
    }
  }, [checkedType, data]);

  const handleChange = (e: any) => {
    const type = e.target.value;
    setSelectedType(type);

    updateCheckedType(user_id, type, page);
  };
  return (
    <div className="content-type-radios">
      <Radio.Group
        onChange={handleChange}
        value={selectedType}
        className="radioGroup"
      >
        {typeList.map((type, index) => (
          <Radio.Button
            key={type}
            value={type}
            className="radioButton"
            style={{
              marginRight: "10px",
              backgroundColor: selectedType === type ? "black" : "white",
              color: selectedType === type ? "white" : "black",
              ...(data &&
              data.disabledTypes.length &&
              data.disabledTypes.includes(type)
                ? {
                    backgroundColor: "#d9d9d9",
                    color: "#333",
                  }
                : {}),
            }}
            disabled={Boolean(
              data &&
                data.disabledTypes.length &&
                data.disabledTypes.includes(type)
            )}
          >
            {type}
          </Radio.Button>
        ))}
      </Radio.Group>
    </div>
  );
};

export default ContentTypeRadio;
