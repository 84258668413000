import { Button, Input, Modal, Popconfirm } from "antd";
import React, { useEffect, useState } from "react";
import { supabase } from "../../../config/supabaseClient";
import { UpdateCombinedText } from "../../../Utils/SupabaseServices/ContentDatabase";

const ProductBankEditModal = (props: any) => {
  const {
    isModalOpen,
    setIsModalOpen,
    data,
    user_id,
    refetchData,
    linkFilterRefetch,
    dataCount,
    setSelectedProductLnikFilter,
    username,
  } = props;
  const [name, setName] = useState("");
  const [url, setURL] = useState("");
  useEffect(() => {
    setName(data?.table_name);
    setURL(data?.table_url);
  }, [data,isModalOpen]);

  const handleOk = async () => {
    const { data: upd } = await supabase
      .from("product_bank")
      .update({ name: name, url: url })
      .eq("product_id", data.table_id)
      .eq("user_id", user_id);
    await supabase
      .from(`${username}`)
      .update({ caption: name, shop_link: url, product_name: name ,shop_title:name})
      .eq("post_id", data.table_id)
      .eq("user_id", user_id);
    UpdateCombinedText(username, user_id, data.table_id);

    refetchData();
    linkFilterRefetch();
    dataCount <= 1 && setSelectedProductLnikFilter("AllLinks");
    setIsModalOpen(false);
  };
  async function deletePost(post_id: any) {
    const { data: upd } = await supabase
      .from("product_bank")
      .delete()
      .eq("product_id", post_id)
      .eq("user_id", user_id);
    refetchData();
    linkFilterRefetch();
    setIsModalOpen(false);
  }
  const handleCancel = () => {
    setIsModalOpen(false);
    setName("")
    setURL("")
  };
  const handleInputChange = (value: any, setter: any) => {
    setter(value);
  };
  return (
    <>
      <Modal
        title="Edit Product"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        okButtonProps={{ disabled: !name || !url }}
        okText="Update"
        data-testid="edit-product-modal"
        className="edit-product-modal"
      >
        <label className="label-modal">Product Name</label>
        <Input
          className="mb-4"
          value={name}
          placeholder="Enter product name here"
          id="prouct-name-input"
          onChange={(e: any) => {
            handleInputChange(e.target.value, setName);
          }}
        />
        <label className="label-modal">Product URL</label>
        <Input
          className="mb-4"
          value={url}
          placeholder="Enter product url here"
          onChange={(e: any) => {
            handleInputChange(e.target.value, setURL);
          }}
        />

        <Popconfirm
          title="Are you sure you want to permanently delete this product?"
          onConfirm={() => deletePost(data?.table_id)}
          okText="Yes"
          cancelText="No"
          overlayStyle={{ maxWidth: "240px" }}
        ></Popconfirm>
      </Modal>
    </>
  );
};

export default ProductBankEditModal;
