import { useEffect, useState, Fragment, useCallback } from "react";
import { Button, Carousel } from "antd";
import VideoComponent from "../VideoComponent/VideoComponent";
import { debounce } from "lodash";

const AllContentTable = ({
  username,
  user_id,
  data,
  posts,
  isLoading,
  query,
  setAllPosts,
  componentName,
  setRemovePosts,
  handleFeed,
}: any) => {
  const [checkedValues, setCheckedValues] = useState(posts || []);

  useEffect(() => {
    setCheckedValues(posts);
  }, [posts]);

  // Debounce the state updates for checkedValues and setAllPosts
  const debouncedSetCheckedValues = useCallback(
    debounce((updatedCheckedValues: any) => {
      setCheckedValues(updatedCheckedValues);
      handleFeed(updatedCheckedValues);
    }, 300),
    []
  );

  const debouncedSetAllPosts = useCallback(
    debounce((updatedAllPosts: any) => {
      setAllPosts(updatedAllPosts);
    }, 300),
    []
  );

  const onAdd = useCallback(
    (post_id: any) => {
      if (!checkedValues.some((post: any) => post.table_id === post_id)) {
        const postData = data?.pages?.[0].find((post: any) => post.table_id === post_id);
        if (postData) {
          const updatedCheckedValues = [postData, ...checkedValues];
          debouncedSetCheckedValues(updatedCheckedValues);
          debouncedSetAllPosts((prev: any) => [...prev, postData]);
        }
      }
    },
    [checkedValues, data?.pages] // Dependencies
  );

  const onMinus = useCallback(
    (post_id: any) => {
      const postData = checkedValues.find((post: any) => post?.table_id === post_id);
      if (postData) {
        setRemovePosts((prev: any) => [...prev, postData]);
        const updatedCheckedValues = checkedValues.filter(
          (post: any) => post.table_id !== post_id
        );
        debouncedSetCheckedValues(updatedCheckedValues);
        debouncedSetAllPosts(updatedCheckedValues);
      }
    },
    [checkedValues, setRemovePosts] // Dependencies
  );

  return (
    <>
      {!isLoading && data?.pages ? (
        <div className="feed-tab-posts-container">
          {data.pages.map((group: any, pageIndex: any) => (
            <Fragment key={pageIndex}>
              {group.map((image: any, index: any) => (
                <div key={index} className="feed-tab-post-container post-width">
                  <div
                    className="feed-tab-media-wrapper"
                    style={
                      image.platform === "newsletter"
                        ? { backgroundColor: "#efeded", textAlign: "center" }
                        : {}
                    }
                  >
                    {checkedValues && checkedValues.length > 0 && checkedValues?.some(
                      (elem: any) => elem.table_id === image.table_id
                    ) && posts?.some((elem: any) => elem.table_id === image.table_id) ? (
                      <Button
                        onClick={() => onMinus(image.table_id)}
                        className="carousel-content-remove-btn"
                      >
                        Remove
                      </Button>
                    ) : (
                      <Button
                        onClick={() => onAdd(image.table_id)}
                        className="carousel-content-add-btn"
                      >
                        Add
                      </Button>
                    )}

                    {image.platform !== "newsletter" ? (
                      image.table_image ? (
                        image.table_image.includes(",") ? (
                          <Carousel dotPosition="bottom" className="feed-tab-media-carousel">
                            {image.table_image.split(",").map((media_url: any, idx: any) => (
                              <Fragment key={idx}>
                                {media_url.includes("video") ? (
                                  <div className="h-255">
                                    <VideoComponent
                                      media_url={media_url}
                                      thumbnail={image.thumbnail?.split(",")[idx] || "/assets/dummy_video.png"}
                                    />
                                  </div>
                                ) : (
                                  <img className="feed-tab-media-check" src={media_url} alt="post" />
                                )}
                              </Fragment>
                            ))}
                          </Carousel>
                        ) : image.table_image.includes("video") ? (
                          <div className="h-255">
                            <VideoComponent
                              media_url={image.table_image}
                              thumbnail={image.thumbnail || "/assets/dummy_video.png"}
                            />
                          </div>
                        ) : (
                          <img
                            className="feed-tab-media-check"
                            src={image.table_image || "/assets/dummy_video.png"}
                            alt="post"
                          />
                        )
                      ) : (
                        <img className="feed-tab-media-check" src={image.table_image} alt="post" />
                      )
                    ) : (
                      <div>
                        <h4 className="mt-7">
                          {new Intl.DateTimeFormat("en-US", {
                            year: "numeric",
                            day: "numeric",
                            month: "long",
                          }).format(new Date(image.post_date))}
                        </h4>
                        <h4>Newsletter</h4>
                      </div>
                    )}
                  </div>
                </div>
              ))}
            </Fragment>
          ))}
        </div>
      ) : (
        <p>No shoppable content present for '{query}'</p>
      )}
    </>
  );
};

export default AllContentTable;

