import React, { useCallback, useEffect, useRef, useState } from "react";
import CustomPagination from "../../CustomPagination";
import PuffLoader from "react-spinners/PuffLoader";
import "./YoutubeData.css";
import { useLoadTypeContentData } from "../../../../Utils/customHooks/UpToDateContent/useLoadTypeContent";
import { BsHourglassSplit, BsLink45Deg } from "react-icons/bs";
import Card from "react-bootstrap/esm/Card";
import DataSkeleton from "../DataSkeleton";
import { debounce } from "lodash";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { isNavigable } from "../../../../Utils/StayUpToDate/RenderComponent";
import track, { TRACKING_TYPES } from "../../../../Utils/SupabaseServices/Track";
import { isMobile } from "react-device-detect";
import { useDomLoaded } from "../../../../hooks/DomLoadedContext";

const YoutubeData = (props: any) => {
  let platform = "youtube";
  let type = "VIDEO";
  const itemsPerPage = 3;

  const { user_id, username, handleRedirect, brand_color, isHandleNavigate, component } = props;
  const { isLoading: YoutubeDataLoading, data: YoutubeData }: any =
    useLoadTypeContentData(username, user_id, platform, type);

  const [currentIndex, setCurrentIndex] = useState(0);
  const containerRef = useRef<any>(null);
  const isButtonClicked = useRef(false);
  const param = useParams()
  const location = useLocation()
  const domLoaded = useDomLoaded();
  const navigate = useNavigate()

  const handleScroll = useCallback(
    debounce(() => {
      if (!isButtonClicked.current) {
        if (containerRef.current) {
          const scrollLeft = containerRef.current.scrollLeft;
          const itemWidth = containerRef.current.scrollWidth / YoutubeData.length;
          const newCurrentIndex = Math.round(scrollLeft / itemWidth);
          setCurrentIndex(newCurrentIndex);
        }
      }
    }, 100),
    [YoutubeData]
  );

  useEffect(() => {
    if (containerRef.current) {
      const itemWidth = containerRef.current.scrollWidth / YoutubeData.length;
      const scrollPosition = itemWidth * currentIndex;
      containerRef.current.scrollTo({
        left: scrollPosition,
        behavior: "smooth",
      });
    }
  }, [currentIndex, YoutubeData]);

  useEffect(() => {
    if (containerRef.current) {
      containerRef.current.addEventListener("scroll", handleScroll);
      return () => {
        if (containerRef.current) {
          containerRef.current.removeEventListener("scroll", handleScroll);
        }
      };
    }
  }, [YoutubeData, handleScroll]);

  const handlePrevPage = () => {
    isButtonClicked.current = true;
    setCurrentIndex((prevIndex) => Math.max(prevIndex - 1, 0));
    setTimeout(() => {
      isButtonClicked.current = false;
    }, 500);
  };

  const handleNextPage = () => {
    isButtonClicked.current = true;
    setCurrentIndex((prevIndex) =>
      Math.min(prevIndex + 1, YoutubeData.length - itemsPerPage)
    );
    setTimeout(() => {
      isButtonClicked.current = false;
    }, 500);
  };

  const handleNavigate = (component: any) => {
    if (isNavigable(component)) {
      navigate(`/${username}/${component.unique_id}`, {
        state: { data: component },
      });

      track("Carousel Click", TRACKING_TYPES.EVENT, {
        pathname: location.pathname,
        details: {
          unique_id: component?.unique_id,
          type: component?.type,

          title: component.name,
          // name: name,
        },
        user_id: user_id,
        title: "Stay Up To Date",
      });
    }
  };
  const ImagePostTag = React.memo((props: any) => {
    const { data } = props;
    const {
      thumbnail,
      media_url,
      permalink,
      post_id,
      product_image,
      product_name,
      isYoutube
    } = data;
    return (
      <a
        href={permalink}
        target="_blank"
        onClick={(e) => {
          e.preventDefault();
          handleRedirect(
            post_id,
            media_url.includes("video")
              ? product_image
                ? product_image
                : thumbnail
              : product_image
                ? product_image
                : media_url,
            "Youtube Content",
            product_name
          );
          if ((permalink && permalink !== "")) {
            window.open(permalink,
              "_blank"
            );
          }
        }}
      >
        <div className="creator-feed-card-container">
          {!media_url ? (
            <div className="creator-feed-card-youtube not-available">
              <BsHourglassSplit
                className="hourglassSplit"
              />
              <p>
                Work in progress: This content will be available in a few
                minutes.
              </p>
            </div>
          ) : (
            <>
              <div className={isYoutube ? "creator-feed-image-wrapper-youtube youtube-feed-wrapper" : "creator-feed-image-wrapper-youtube"}>
                <Card.Img
                  variant="top"
                  src={
                    media_url.includes("video")
                      ? product_image
                        ? product_image
                        : thumbnail
                      : product_image
                        ? product_image
                        : media_url
                  }
                  className={isYoutube ? "creator-feed-card-youtube-image youtube-feed-height" : "creator-feed-card-youtube-image"}
                  loading="eager"
                  style={{ backgroundColor: brand_color && brand_color }}
                />
              </div>
              {product_name && product_image && (
                <h6
                  className="video-product-name"
                >
                  {product_name}
                </h6>
              )}
            </>
          )}
        </div>
      </a>
    );
  });
  return (
    <div>
      {YoutubeDataLoading || !domLoaded ? (
        <DataSkeleton />
      ) : (
        <>
          {YoutubeData && YoutubeData.length ? (
            <div>
              <div
                className="mainDiv"
              >
                <p
                  className="component-name" style={param?.id ? { cursor: "auto" } : {}} onClick={(e) => handleNavigate(component)}
                >
                  {" "}
                  YouTube

                </p>
                {!param?.id && <CustomPagination
                  totalItems={YoutubeData?.length}
                  currentIndex={currentIndex}
                  handlePrevPage={handlePrevPage}
                  handleNextPage={handleNextPage}
                />}
              </div>
              <div
                className={!param?.id ? "outer-container-reels" : ""}
                ref={containerRef}
              >
                <div
                  className={!param?.id ? "links-grid-container-reels" : isMobile ? "item-grid creator-card-grid-wrp" : "item-grid"}
                >
                  {YoutubeData &&
                    YoutubeData.map((link: any, index: number) => (
                      <div
                        key={index}
                        className={link.platform != "youtube"
                          ? "link-url"
                          : !link.product_image ? "link-url youtube-link-position" : "link-url"}
                      >
                        <ImagePostTag data={link} handleRedirect={handleRedirect} isYoutube={link.platform == "youtube" && !link.product_image ? true : false} />
                      </div>
                    ))}
                </div>
              </div>
            </div>
          ) : (
            ""
          )}
        </>
      )}
    </div>
  );
};

export default YoutubeData;
