import { useState } from "react";
import { supabase } from "../../config/supabaseClient";
import { useQuery } from "react-query";
import {
  // fetchSearchResultsCount,
  getEmbedding,
  getQuerySuggestions,
  removeQualifiers,
} from "../SupabaseServices/ContentDatabase";
import { StaticVariables } from "../StaticVariables";
// import { useSearchData } from "./useLoadSearchbarData";

/**
 *  Use this hook to control feed pagination
 * @param userId
 * @param index - "active" || "archive"
 * @param postsPerPage
 * @returns
 */
export function useLoadFirstEmbedding(
  username: any,
  user_id: any[],
  queryTerm: any,
  category: any,
  postsPerPage: number,
  filter: any[],
  activeHighlights: any,
  openAiSearch: boolean,
  extraId: any[],
  trending: any,
  showSocials: any[],
  fts_total: any
) {
  postsPerPage = 10;
  queryTerm = queryTerm.toLowerCase().replace(/\s+/g, " ").trim();
  category = category && category.trim();
  trending = trending?.trim();
  async function getCount() {
    let threshold: any = 0.8;
    let postIds: any = [];
    const filteredArray = filter.filter((item: any) => item !== "ALL");
    if (user_id && user_id.length === 1) {
      if (category && category === "searchbar") {
        const elementsCount = 5000;
        // const embedding = await getEmbedding(queryTerm);
        const connectingWords = StaticVariables().connectingWords


        // Split the sentence into words
        let words = queryTerm.split(" ");
        if (
          words.length === 1 &&
          !StaticVariables().SEARCH_EXCEPTIONS.includes(queryTerm)
        ) {
          const suffixRegex = /(s)$/i;
          if (suffixRegex.test(words[0])) {
           
            words=[`${words[0].replace(suffixRegex, "")}`];
          }
        }
        if(words.includes("shop") || words.includes("buy")){

          words = words.filter((word:any) => word !== "shop" && word !== "buy");
        }
        // Filter out connecting words
        const filteredWords = words.filter(
          (word: any) => !connectingWords.includes(word.toLowerCase())
        );

        // Join the filtered words back into a sentence
        const result = filteredWords.join(" ");
        const pgQuery = result.toLowerCase().replace(/\s+/g, " & ");

        const embedding = await getEmbedding(pgQuery);
        const { data: countResult, error: countError } = await supabase.rpc(
     
          "semantic_ssearchcountembedpostidsimfirst",
          {
            query_string: pgQuery,
            query_embedding: embedding,
            similarity_threshold: StaticVariables().THRESHOLD,

            // match_count: elementsCount,
            userid: user_id[0],
            username: username,
            socials: showSocials,
            // filter:filter
          }
        );
        // let resCount=countResult.length;

        // postIds = countResult.map((item:any)=> item.post_id);

        // if (countResult?.[0]?.count === 0) {
        //   resCount = 0;
        // } else {
        //   resCount = countResult?.[0]?.count ;
        // }
        // feedCount = resCount;

        // if (feedCount) {
        
        if (countResult && countResult[0]) {
          if (fts_total > 30) {
            threshold = countResult?.[0].similarity - 0.01;
          } else {
            if (countResult?.[0].similarity > 0.9) {
              threshold = countResult?.[0].similarity - 0.0600;
            }else if(countResult?.[0].similarity <=0.845){
              threshold = countResult?.[0].similarity - 0.0100;

            } else {
              threshold = countResult?.[0].similarity - 0.0250;
            }
          }
          threshold = threshold.toString().split(".");
          threshold = parseFloat(
            threshold[0] + "." + threshold[1].substring(0, 4)
          );
        }

        return {
          // totalPages: Math.ceil(feedCount / postsPerPage),
          first:
            countResult && countResult[0] ? countResult?.[0].similarity : 0.9,
          threshold: threshold ? threshold : 0.8,
        };
        // } else {
        //   return 0;
        // }
      }
    }
  }

  return useQuery(
    ["getFirstEmbeddding", filter, queryTerm, trending, category],
    getCount,
    {
      enabled: Boolean(fts_total >= 0),
      // refetchOnWindowFocus: true,
    }
  );
}
