import { useInfiniteQuery } from "react-query";
import { supabase } from "../../config/supabaseClient";
import {
  extractKeywords,
  // fetchSearchResults,
  getAdLength,
  getEmbedding,
  getExtraNecIds,
  getQuerySuggestions,
  removeQualifiers,
} from "../SupabaseServices/ContentDatabase";
import { useRef, useState } from "react";
import dayjs from "dayjs";
import { StaticVariables } from "../StaticVariables";

/**
 * Load content
 */

export function useLoadFeedContent(
  username: any,
  user_id: any,
  queryTerm: any,
  category: any,
  showAds: any,
  filter: any,
  activeHighlights: any,
  postsPerPage: number,
  totalPages: number,
  feedCount: any,
  postIds: any[],
  openAiSearch: boolean,
  extraId: any[],
  trending: any,
  avgWords: any,
  showSocials: any[],
  checkedType: any,
  defaultCategory: any,
  contentOrder: any
) {
  queryTerm = queryTerm.toLowerCase().replace(/\s+/g, " ").trim();
  category = category && category.trim();
  trending = trending?.trim();
  const isNecCalledRef = useRef({
    current: false,
    currentQueryTerm: "",
  });
  const [adsIndex, setAdsIndex] = useState<any>(0);
  async function getPaginatedFeed({ pageParam = 1 }) {
    let start = ((pageParam || 1) - 1) * postsPerPage;
    let end = start + postsPerPage - 1;
    let data,
      error = null;
    if (queryTerm !== isNecCalledRef.current.currentQueryTerm) {
      // If the query term has changed, reset isNecCalledRef.current to false
      isNecCalledRef.current.current = false;
      isNecCalledRef.current.currentQueryTerm = queryTerm;
    }

    await getAdLength(user_id[0], queryTerm, filter, username).then(
      (adCount: any) => {
        if (adCount === 1) {
          if ((end + 1) % 10 === 0) {
            setAdsIndex(0);
          }
        } else {
          if (adsIndex + 1 >= adCount) {
            if ((end + 1) % 10 === 0) {
              setAdsIndex(0);
            }
          } else {
            // Check if end + 1 is a multiple of 10
            if ((end + 1) % 10 === 0) {
              setAdsIndex((prev: any) => prev + 1);
            }
          }
        }
      }
    );

    const filteredArray = filter.filter((item: any) => item !== checkedType);
    if (user_id && user_id.length === 1) {
      if (category) {
        if (defaultCategory === queryTerm) {
          queryTerm = "";
        }
        if (
          category === checkedType ||
          category === "Top Hashtags" ||
          category === "Popular Searches" ||
          category === "Content Type" ||
          category === "My Links"
        ) {
          if (queryTerm) {
            const { data: rpc, error: rpcError } = await supabase.rpc(
              "adssssgeneralfeeddt",
              {
                userid: user_id[0],
                start_number: start,
                end_num: end,
                filter: filteredArray,
                query_str: queryTerm,
                user_name: username,
                socials: showSocials,
                idx: Number(adsIndex),
                show_ads: (end + 1) % 10 === 0 ? true : false, // true if end + 1 is a multiple of 10
              }
            );
            data = rpc;
            error = rpcError;
          } else {
            if (checkedType === "Feed") {
              const { data: rpc, error: rpcError } = await supabase.rpc(
                "adssssgeneralfeeddt",
                {
                  userid: user_id[0],
                  start_number: start,
                  end_num: end,
                  filter: ["FEED", "REELS"],
                  query_str: queryTerm,
                  user_name: username,
                  socials: showSocials,
                  idx: adsIndex || 0,
                  show_ads: (end + 1) % 10 === 0 ? true : false, // true if end + 1 is a multiple of 10
                }
              );
              data = rpc;
              error = rpcError;
            } else if (checkedType === "Featured") {
              const { data: rpc, error: rpcError } = await supabase.rpc(
                "adsfeaturedfeeddt",
                {
                  userid: user_id[0],
                  start_number: start,
                  end_num: end,
                  filter: filteredArray,
                  query_str: queryTerm,
                  user_name: username,
                  socials: showSocials,
                  idx: adsIndex || 0,
                  show_ads: (end + 1) % 10 === 0 ? true : false, // true if end + 1 is a multiple of 10
                }
              );
              data = rpc;
              error = rpcError;
            } else {
              const { data: rpc, error: rpcError } = await supabase.rpc(
                "adssssgeneralfeeddt",
                {
                  userid: user_id[0],
                  start_number: start,
                  end_num: end,
                  filter: filteredArray,
                  query_str: queryTerm,
                  user_name: username,
                  socials: showSocials,
                  idx: adsIndex || 0,
                  show_ads: (end + 1) % 10 === 0 ? true : false, // true if end + 1 is a multiple of 10
                }
              );
              data = rpc;
              error = rpcError;
            }
          }
        } else if (category === "searchbar") {
          const elementsCount = 5000;

          // const embedding = await getEmbedding(queryTerm);

          // await getEmbedding(queryTerm);
          const connectingWords = StaticVariables().connectingWords;

          // Split the sentence into words
          let words = queryTerm.split(" ");
          let singleWords: any = [];
          if (
            words.length === 1 &&
            !StaticVariables().SEARCH_EXCEPTIONS.includes(queryTerm)
          ) {
            const suffixRegex = /(s)$/i;
            if (suffixRegex.test(words[0])) {
              words = [`${words[0].replace(suffixRegex, "")}`];
            }
          }

          if (words.includes("shop") || words.includes("buy")) {
            contentOrder = "shoppable";
            words = words.filter(
              (word: any) => word !== "shop" && word !== "buy"
            );
          }
          // Filter out connecting words
          let filteredWords = words.filter(
            (word: any) => !connectingWords.includes(word.toLowerCase())
          );

          if (words.length <= 2) {
            // Join the filtered words back into a sentence
            const result = filteredWords.join(" ");
            const pgQuery = result.toLowerCase().replace(/\s+/g, " & ");

            const { data: searchResult, error: searchError } =
              await supabase.rpc("semanticcc_ftsspostidddo", {
                query_string: pgQuery,
                start_number: start,
                end_num: end,
                userid: user_id[0],
                username: username,
                socials: showSocials,

                idx: adsIndex || 0,
                show_ads: (end + 1) % 10 === 0 ? true : false,
                postids: postIds,
                content_order: contentOrder.split(" ")[0].toLowerCase(),
              });
            // const { data: com = [], error } = await supabase.rpc(
            //   "get_productbankpostsearchhhhtypeeeii",
            //   {
            //     query_string: queryTerm ? queryTerm : "",
            //     userid: user_id[0],

            //     start_number: start,
            //     end_number: end,
            //     query_tagg: queryTerm,
            //     index_type: "active",
            //   }
            // );
            data = [...searchResult]
            // data = [...searchResult, ...com];
            // data = [...searchResult, ...com].sort(
            //   (a, b) =>
            //     new Date(b.post_date).getTime() -
            //     new Date(a.post_date).getTime()
            // );
          //   data = [
          //     ...searchResult,
          //     ...com.filter(
          //         (comPost:any) => !searchResult.some((searchPost:any) => searchPost.post_id === comPost.post_id)
          //     )
          // ].sort(
          //     (a, b) =>
          //       new Date(b.post_date).getTime() -
          //       new Date(a.post_date).getTime()
          //   );
          } else {
            data = [];
          }
          // }
        } else if (category === "Instagram Highlights") {
          const { data: rpc, error: rpcError } = await supabase.rpc(
            "get_highlightsadsfeedddt",
            {
              userid: user_id[0],
              start_number: start,
              end_num: end,
              filter: ["HIGHLIGHTS"],
              query_str: queryTerm,
              user_name: username,
              idx: adsIndex || 0,
              show_ads: (end + 1) % 10 === 0 ? true : false, // true if end + 1 is a multiple of 10

              // extra_ids: extraId,
            }
          );
          data = rpc;
          error = rpcError;
        } else if (category.includes("Shoppable")) {
          const { data: rpc, error: rpcError } = await supabase.rpc(
            // "get_generalffffeed",
            // "get_adsssssgeneralffffeed",

            "adssssgeneralfeeddshopt",
            {
              userid: user_id[0],
              start_number: start,
              end_num: end,
              filter: filteredArray,
              query_str: queryTerm,
              user_name: username,
              socials: showSocials,
              idx: adsIndex || 0,
              show_ads: (end + 1) % 10 === 0 ? true : false, // true if end + 1 is a multiple of 10

              // extra_ids: extraId,
            }
          );
          data = rpc;
          error = rpcError;
        } else if (category.includes("Yesterday's Content")) {
          // if (category.includes("Yesterday")) {
          const today = dayjs().format("YYYY-MM-DD");

          // Get yesterday's date
          const yesterday = dayjs().subtract(1, "day").format("YYYY-MM-DD");

          if (queryTerm) {
            if (queryTerm === "all") {
              const { data: rpc, error: rpcError } = await supabase.rpc(
                "get_storiesadsfeedddt",
                {
                  userid: user_id[0],
                  start_number: start,
                  end_num: end,
                  filter: filteredArray,
                  query_str: "",
                  user_name: username,
                  idx: adsIndex || 0,
                  show_ads: (end + 1) % 10 === 0 ? true : false,
                  start_date: yesterday,
                  end_date: today, // true if end + 1 is a multiple of 10
                }
              );
              data = rpc;
              error = rpcError;
            } else if (queryTerm.includes("shoppable")) {
              const { data: rpc, error: rpcError } = await supabase.rpc(
                "get_storiesadsfeedddshopt",
                {
                  userid: user_id[0],
                  start_number: start,
                  end_num: end,
                  filter: filteredArray,
                  query_str: "",
                  user_name: username,
                  idx: adsIndex || 0,
                  show_ads: (end + 1) % 10 === 0 ? true : false, // true if end + 1 is a multiple of 10
                  start_date: yesterday,
                  end_date: today,
                }
              );
              data = rpc;
              error = rpcError;
            }
          } else {
            const { data: rpc, error: rpcError } = await supabase.rpc(
              "get_storiesadsfeedddt",
              {
                userid: user_id[0],
                start_number: start,
                end_num: end,
                filter: filteredArray,
                query_str: "",
                user_name: username,
                idx: adsIndex || 0,
                show_ads: (end + 1) % 10 === 0 ? true : false,
                start_date: yesterday,
                end_date: today, // true if end + 1 is a multiple of 10

                // extra_ids: extraId,
              }
            );
            data = rpc;
            error = rpcError;
          }
        } else if (category.includes("Last 7")) {
          const today = dayjs().format("YYYY-MM-DD");

          const sevenDayDate = dayjs().subtract(7, "day").format("YYYY-MM-DD");

          if (queryTerm) {
            if (queryTerm === "all") {
              const { data: rpc, error: rpcError } = await supabase.rpc(
                "get_storiesadsfeedddt",
                {
                  userid: user_id[0],
                  start_number: start,
                  end_num: end,
                  filter: filteredArray,
                  query_str: "",
                  user_name: username,
                  idx: adsIndex || 0,
                  show_ads: (end + 1) % 10 === 0 ? true : false, // true if end + 1 is a multiple of 10
                  start_date: sevenDayDate,
                  end_date: today,
                }
              );
              data = rpc;
              error = rpcError;
            } else if (queryTerm.includes("shoppable")) {
              const { data: rpc, error: rpcError } = await supabase.rpc(
                "get_storiesadsfeedddshopt",
                {
                  userid: user_id[0],
                  start_number: start,
                  end_num: end,
                  filter: filteredArray,
                  query_str: "",
                  user_name: username,
                  idx: adsIndex || 0,
                  show_ads: (end + 1) % 10 === 0 ? true : false, // true if end + 1 is a multiple of 10
                  start_date: sevenDayDate,
                  end_date: today,
                }
              );
              data = rpc;
              error = rpcError;
            }
          } else {
            const { data: rpc, error: rpcError } = await supabase.rpc(
              "get_storiesadsfeedddt",
              {
                userid: user_id[0],
                start_number: start,
                end_num: end,
                filter: filteredArray,
                query_str: queryTerm,
                user_name: username,
                idx: adsIndex || 0,
                show_ads: (end + 1) % 10 === 0 ? true : false, // true if end + 1 is a multiple of 10
                start_date: sevenDayDate,
                end_date: today,
                // extra_ids: extraId,
              }
            );
            data = rpc;
            error = rpcError;
          }
        } else {
          if (queryTerm) {
            const { data: rpc, error: rpcError } = await supabase.rpc(
              "get_categoryadsfffeeddt",
              {
                userid: user_id[0],
                start_number: start,
                end_num: end,
                filter: filteredArray,
                query_str: `|${queryTerm}|`,
                user_name: username,
                category: category,
                idx: adsIndex || 0,
                show_ads: (end + 1) % 10 === 0 ? true : false, // true if end + 1 is a multiple of 10

                // extra_ids: extraId,
              }
            );
            data = rpc;
            error = rpcError;
          } else {
            const { data: rpc, error: rpcError } = await supabase.rpc(
              "get_highlightsadsfeedddt",
              {
                userid: user_id[0],
                start_number: start,
                end_num: end,
                filter: filteredArray,
                query_str: category,
                user_name: username,
                idx: adsIndex || 0,
                show_ads: (end + 1) % 10 === 0 ? true : false, // true if end + 1 is a multiple of 10

                // extra_ids: extraId,
              }
            );
            data = rpc;
            error = rpcError;
          }
        }
      } else {
        if (trending === checkedType) {
          // const searchQueryStr: any = await getQuerySuggestions(
          //   queryTerm,
          //   openAiSearch,
          //   extraId,
          //   category,
          //   activeHighlights
          // );
          // const { data: rpc, error: rpcError } = await supabase.rpc(
          //   // "get_generalffffeed",
          //   // "get_adsssssgeneralffffeed",
          //   "adsgeneralfeed",
          //   {
          //     userid: user_id[0],
          //     start_number: start,
          //     end_num: end,
          //     filter: filteredArray,
          //     query_str: queryTerm,
          //     user_name: username,
          //     socials: showSocials,
          //     // extra_ids: extraId,
          //   }
          // );
          if (checkedType === "Feed") {
            const { data: rpc, error: rpcError } = await supabase.rpc(
              // "get_generalffffeed",
              // "get_adsssssgeneralffffeed",

              "adssssgeneralfeeddt",
              {
                userid: user_id[0],
                start_number: start,
                end_num: end,
                filter: ["FEED", "REELS"],
                query_str: queryTerm,
                user_name: username,
                socials: showSocials,
                idx: adsIndex || 0,
                show_ads: (end + 1) % 10 === 0 ? true : false, // true if end + 1 is a multiple of 10

                // extra_ids: extraId,
              }
            );
            data = rpc;
            error = rpcError;
          } else if (checkedType === "Featured") {
            const { data: rpc, error: rpcError } = await supabase.rpc(
              // "get_generalffffeed",
              // "get_adsssssgeneralffffeed",

              "adsfeaturedfeeddt",
              {
                userid: user_id[0],
                start_number: start,
                end_num: end,
                filter: filteredArray,
                query_str: queryTerm,
                user_name: username,
                socials: showSocials,
                idx: adsIndex || 0,
                show_ads: (end + 1) % 10 === 0 ? true : false, // true if end + 1 is a multiple of 10

                // extra_ids: extraId,
              }
            );
            data = rpc;
            error = rpcError;
          } else {
            const { data: rpc, error: rpcError } = await supabase.rpc(
              // "get_generalffffeed",
              // "get_adsssssgeneralffffeed",

              "adssssgeneralfeeddt",
              {
                userid: user_id[0],
                start_number: start,
                end_num: end,
                filter: filteredArray,
                query_str: queryTerm,
                user_name: username,
                socials: showSocials,
                idx: adsIndex || 0,
                show_ads: (end + 1) % 10 === 0 ? true : false, // true if end + 1 is a multiple of 10

                // extra_ids: extraId,
              }
            );
            data = rpc;
            error = rpcError;
          }
        } else if (trending === "Most Liked") {
          const { data: content, error: contentError } = await supabase.rpc(
            "get_likesorderedfeed",
            {
              userid: user_id[0],
              start_number: start,
              end_num: end,
              filter: filteredArray,
              query_str: queryTerm,
              extra_ids: extraId,
            }
          );
          data = content;
          error = contentError;
        } else if (trending === "Most Commented") {
          const { data: content, error: contentError } = await supabase.rpc(
            "get_commentsorderedfeed",
            {
              userid: user_id[0],
              start_number: start,
              end_num: end,
              filter: filteredArray,
              query_str: queryTerm,
              extra_ids: extraId,
            }
          );
          data = content;
          error = contentError;
        } else if (trending === "Most Reached") {
          const { data: content, error: contentError } = await supabase.rpc(
            "get_reachorderedfeed",
            {
              userid: user_id[0],
              start_number: start,
              end_num: end,
              filter: filteredArray,
              query_str: queryTerm,
              extra_ids: extraId,
            }
          );
          data = content;
          error = contentError;
        } else if (trending === "Most Engaged") {
          const { data: content, error: contentError } = await supabase.rpc(
            "get_engagementorderedfeed",
            {
              userid: user_id[0],
              start_number: start,
              end_num: end,
              filter: filteredArray,
              query_str: queryTerm,
              extra_ids: extraId,
            }
          );
          data = content;
          error = contentError;
        } else if (trending === "Most Viewed") {
          const { data: content, error: contentError } = await supabase.rpc(
            "get_impressionorderedfeed",
            {
              userid: user_id[0],
              start_number: start,
              end_num: end,
              filter: filteredArray,
              query_str: queryTerm,
              extra_ids: extraId,
            }
          );
          data = content;
          error = contentError;
        }
      }
    } else {
      //-- for global page ----------
      const searchQueryStr: any = await getQuerySuggestions(
        queryTerm,
        openAiSearch,
        extraId,
        category,
        activeHighlights
      );
      let { data: content, error: contentError }: any = await supabase
        .from("content_new")
        .select("*")
        .in("user_id", user_id)
        .in("type", filter)
        .or(searchQueryStr.query)
        .ilike("index", "%active%")
        .order("post_date", { ascending: false })
        .range(start, end);
      data = content;
      error = contentError;
    }

    // const ids = data && data.map((item: any) => item.id);
    // const { data: comments }: any = await supabase
    //   .from("comments")
    //   .select("*")
    //   .in("content_id", ids)
    //   .in("user_id", user_id);

    // data = data.map((post: any) => {
    //   const postComments = comments.filter(
    //     (item: any) => item.content_id === post.id
    //   );

    //   return {
    //     ...post,
    //     comments: postComments,
    //   };
    // });
    // let { data: ads }: any = await supabase
    //   .from("content_new")
    //   .select("*")
    //   .eq("user_id", user_id)
    //   .ilike("index", "%ad%")
    //   .order("ads_order", { ascending: true });

    const resultArray = [...data];
    // if (showAds === true) {
    //   ads.map((ad: any) => {
    //     ad.showAsAd = true;
    //   });

    //   if (ads.length) {
    //     if (data.length === postsPerPage) {
    //       let insertIndex = 10;
    //       resultArray.splice(insertIndex, 0, ads[adsIndex]);
    //       insertIndex += 11; // Increment by 11 to account for the inserted element
    //     }
    //   }
    // }

    if (resultArray && resultArray.length >= 0 && Array.isArray(resultArray)) {
      return resultArray;
    } else if (error) {
      throw new Error(error.message);
    } else {
      throw new Error("Failed to fetch posts");
    }
  }

  category = category;
  return useInfiniteQuery(
    ["paginatedFeedPosts", queryTerm, filter, trending, category, user_id],
    getPaginatedFeed,
    {
      getNextPageParam: (lastPage, pages) => {
        if (pages.length < totalPages) {
          return pages.length + 1;
        } else {
          return undefined;
        }
      },
      enabled: Boolean(totalPages),

      // refetchOnWindowFocus: true,
    }
  );
}
