import React, { useEffect, useState } from "react";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import "./AdditionalCarousel.css";
import { useLocation, useNavigate } from "react-router-dom";
import { isNavigable } from "../../../Utils/StayUpToDate/RenderComponent";
import dayjs from "dayjs";
import useGraphicalAnalytics from "../../../Screens/DashboardView/Analytics/Overview/useGraphicalAnalytics";
import track, { TRACKING_TYPES } from "../../../Utils/SupabaseServices/Track";

const AdditionalCarousels = (props: any) => {
  const { username, components, componentId, isLocalLoading, user_id } = props;
  const navigate = useNavigate();
  const location=useLocation()
  const [loading, setLoading] = useState(true);
  const [startDate, setStartDate] = useState(
    dayjs().subtract(7, "day").format("YYYY-MM-DD")
  );
  const endDate = dayjs();
  const [isYesterday, setIsYesteday] = useState(false);
  const [daysNumber, setDays] = useState<any>(7);
  const { carouselClicksAnalytics, loadingCarouselClicksAnalytics } =
    useGraphicalAnalytics(user_id, startDate, endDate, isYesterday, daysNumber);
  const [filteredAndSortedData, setFilteredAndSortedData] = useState<any[]>([]);

  useEffect(() => {
    const calculateAndSortTotalSum = (data: any) => {
      return Object.keys(data)
        .map((key) => {
          const items = data[key];
          const totalSum = Array.isArray(items)
            ? items.reduce(
                (sum, oneKey) =>
                  sum +
                  (oneKey?.count || 0) +
                  (oneKey.id && oneKey.idCount ? oneKey.idCount : 0),
                0
              )
            : 0;
          return { key, totalSum };
        })
        .sort((a, b) => b.totalSum - a.totalSum);
    };
  
    if (carouselClicksAnalytics) {
      const timer = setTimeout(() => {
        const result = calculateAndSortTotalSum(carouselClicksAnalytics);
  
        const filteredData = components
          .filter(isNavigable)
          .filter((elem: any) => elem.unique_id !== componentId)
          .filter((elem: any) => !elem.isEmpty)
          .filter((elem: any) => elem.status === "active")
          .sort((a: any, b: any) => {
            const indexA = result.findIndex((res) => res.key === a.name);
            const indexB = result.findIndex((res) => res.key === b.name);
  
            // If not found, assign Infinity to place items at the end
            const effectiveIndexA = indexA === -1 ? Infinity : indexA;
            const effectiveIndexB = indexB === -1 ? Infinity : indexB;
  
            return effectiveIndexA - effectiveIndexB;
          })
          .slice(0, 6); // Take top 6 items after sorting
  
        setFilteredAndSortedData(filteredData);
        setLoading(false); // Mark loading complete after processing
      }, 1500); // Simulated loading time
  
      return () => clearTimeout(timer); // Cleanup timer on unmount
    }
  }, [carouselClicksAnalytics, components, componentId]);
  
  const handleNavigate = (component: any) => {
    if (isNavigable(component)) {
      navigate(`/${username}/${component.unique_id}`, {
        state: { data: component },
      });

      track("Carousel Click", TRACKING_TYPES.EVENT, {
        pathname: location.pathname,
        details: {
          unique_id: component?.unique_id,
          type: component?.type,
          title:component?.name,
        },
        user_id: user_id,
        title: "Stay Up To Date",
      });
      track(`/${username}`, TRACKING_TYPES.PAGE, {
        pathname:`/${username}`,
        details: {
          unique_id: component?.unique_id,
          type: component?.type,
          title:component?.name,
        },
        user_id: user_id,
        title: "Stay Up To Date",
      });
    }
  };
  return (
    <>
      <div className="mt-5">
        {filteredAndSortedData && filteredAndSortedData.length && <p className="component-name-individual mb-2">More from @{username}</p>}
        <div className="wrapper-div">
          {loading || isLocalLoading || loadingCarouselClicksAnalytics ? (
            <>
              {Array(4)
                .fill(0)
                .map((_, index) => (
                  <div key={index} className="product-card">
                    <Skeleton height={180} />
                  </div>
                ))}
            </>
          ) : (
            filteredAndSortedData.map((elem: any, index: number) => (
              <div
                key={elem.id}
                className="product-card"
                onClick={() => handleNavigate(elem)}
              >
                <div
                  className="product-image"
                  style={{
                    backgroundImage: `url("${elem.card_image.split(",")[0]}")`
                    // backgroundImage: `url(${elem.card_image.split(",")[0]})`,
                  }}
                >
                  <p className="product-text">{elem.name}</p>
                </div>
              </div>
            ))
          )}
        </div>
      </div>
    </>
  );
};

export default AdditionalCarousels;
