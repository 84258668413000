import { Modal, Radio } from "antd";
import React, { useState } from "react";
import { FaArrowLeft } from "react-icons/fa";
import { addComponent } from "../../../../../Utils/SupabaseServices/StayUpToDateDatabase";

declare global {
  interface Window {
    fd: any;
  }
}

export const NewsletterTabComponent = ({
  isNewsletterModalOpen,
  user_id,
  username,
  flowdeskId,
  refetch,
  setIsNewsletterModalOpen,
  StayUpToDateComponents,
  setIsModalOpen,
  flowdeskPopupId,
  isFlowdeskOn,
  isFlowdeskPopupOn,
}: any) => {
  const [value, setValue] = useState("Inline");
  const [confirmLoading, setConfirmLoading] = useState(false);

  const handleOk = async () => {
    setConfirmLoading(true);
    if (value === "Both") {
      await addComponent(
        "Join my list",

        `${flowdeskId},Inline`,

        username,
        user_id,
        "false",
        "Check it out!",
        "",
        `Inline Newsletter`,
        "Inline"
      );
      await addComponent(
        "Popup Newsletter",
        `${flowdeskPopupId},Popup`,
        username,
        user_id,
        "false",
        "Check it out!",
        "",
        `Popup Newsletter`,
        "Popup"
      );
      // if (res === "true") {
      refetch();
      setIsNewsletterModalOpen(false);
      setIsModalOpen(false);
      // }
    } else {
      const res = await addComponent(
        value === "Inline" ? "Join my list" : "Popup Newsletter",
        value === "Inline"
          ? `${flowdeskId},${value}`
          : `${flowdeskPopupId},${value}`,
        username,
        user_id,
        "false",
        "Check it out!",
        "",
        `${value} Newsletter`,
        value
      );
      if (res === "true") {
        refetch();
        setIsNewsletterModalOpen(false);
        setIsModalOpen(false);
      }
    }
    setConfirmLoading(false);
  };

  const handleCancel = () => {
    setIsNewsletterModalOpen(false);
    setIsModalOpen(false);
  };

  const handlePrevious = () => {
    setIsModalOpen(true);
    setIsNewsletterModalOpen(false);
  };
  return (
    <Modal
      title={
        <button onClick={handlePrevious} className="newbutton">
          <FaArrowLeft />
        </button>
      }
      open={isNewsletterModalOpen}
      onOk={handleOk}
      onCancel={handleCancel}
      confirmLoading={confirmLoading}
      okText="Add"
    >
      <div>
        <h5 className="title mb-15">Flodesk Integration</h5>
        {/* <h5 className="title mb-15">Collect Contacts</h5> */}
        <p style={{ textAlign: "center" }}>Select the template you want to be visible to the followers.</p>
        <Radio.Group
          onChange={(e) => setValue(e.target.value)}
          value={value}
          style={{ display: "flex", justifyContent: "center", marginTop: "4%" }}
        >
          <Radio value="Inline" disabled={!(isFlowdeskOn && flowdeskId)}>
            Inline
          </Radio>
          <Radio value="Popup" disabled={!(isFlowdeskPopupOn && flowdeskPopupId)}>
            Popup
          </Radio>
          <Radio value="Both" disabled={!(isFlowdeskOn && flowdeskId && isFlowdeskPopupOn && flowdeskPopupId)}>
            Both
          </Radio>
        </Radio.Group>

        {/* Conditionally rendered text based on the selected value */}
        <div
          style={{
            marginTop: "15px",
            display: "flex",
            justifyContent: "justify",
          }}
        >
          {value === "Inline" && (
            <p style={{ textAlign: "center" }}>
              This option will embed an inline newsletter on your page. Your
              followers can sign up without any popups.
            </p>
          )}
          {value === "Popup" && (
            <p style={{ textAlign: "center" }}>
              This option will display a popup form on your page. It will grab
              your followers' attention with a modal they can fill out.
            </p>
          )}
          {value === "Both" && (
            <p style={{ textAlign: "center" }}>
              This option will display both an inline and a popup form on your
              page.
            </p>
          )}
        </div>
      </div>
    </Modal>
  );
};
