import { useState, useEffect } from "react";
import { useLoadSearchBarTags } from "../../Utils/customHooks/useLoadSearchBarTags";

export const useRotatingPlaceholder = (
  username:any,
  searchBarText: string = "Search here...",
  intervalTime: number = 5000
): string => {
      const { isLoading, data } = useLoadSearchBarTags(username);

  const [allPlaceholders, setAllPlaceholders] = useState<string[]>([]);
  const [placeholderIndex, setPlaceholderIndex] = useState(0);

  useEffect(() => {
    if (data) {
      const tags = data.split(",");
      const placeholders = [
        searchBarText,
        ...tags.map((tag:any) => `Search "${tag.trim()}"`),
      ];
      setAllPlaceholders(placeholders);
    } else {
      setAllPlaceholders([searchBarText]);
    }
  }, [data, searchBarText]);

  useEffect(() => {
    const interval = setInterval(() => {
      setPlaceholderIndex(
        (prevIndex) => (prevIndex + 1) % allPlaceholders.length
      );
    }, intervalTime);

    return () => clearInterval(interval);
  }, [allPlaceholders, intervalTime]);

  return allPlaceholders[placeholderIndex];
};
