import "../../SubComponents.css";
import { useLocation } from "react-router-dom";
import { useLoadYesterdayLinks } from "../../../../../../Utils/customHooks/UpToDateContent/useLoadYesterdayLinks";
import track, {
  TRACKING_TYPES,
} from "../../../../../../Utils/SupabaseServices/Track";
import EditLinksWrapper from "./EditLinksWrapper";
import ImagePostTag from "../../../../../../components/GeneralPostTag/ImagePostTag";
import ImagePostCarouselTag from "../../../../../../components/GeneralPostTag/ImagePostCarouselTag";
import VideoPostTag from "../../../../../../components/GeneralPostTag/VideoPostTag";
import DataSkeleton from "../../../../../../components/StayUpToDateComponent/UpToDateComponents/DataSkeleton";
import { useDomLoaded } from "../../../../../../hooks/DomLoadedContext";
const LinksWrapper = (props: any) => {
  const {
    user_id,
    username,
    numberOfDays,
    title,
    isLinks,
    isEdit,
    setIsEdit,
    isEditModalOpen,
    setIsEditModalOpen,
    refetch,
    component,
    setSelectedComponent,
    selectedComponent,
    brand_color,
  } = props;
  const domLoaded = useDomLoaded();
  const {
    isLoading: yesterdayLinksLoading,
    data: yesterdayLinksData,
    refetch: refetchLinks,
  }: any = useLoadYesterdayLinks(
    username,
    user_id,
    numberOfDays,
    component?.post_ids,
    component?.removed_postids,
    title,
    false
  );
  const location = useLocation();
  function handleShop(postId: string, media_url: string) {
    track("Redirect to shop link", TRACKING_TYPES.EVENT, {
      pathname: location.pathname,
      details: { post_id: postId, media_url: media_url },
      user_id: user_id,
      title: "Stay Up To Date",
    });
    // updateRefClick(postId, user_id);
  }

  return (
    <>
      <div>
        {yesterdayLinksLoading || !domLoaded ? (
          <>
            <div className="outer-container-subcomponents">
              <DataSkeleton dashboard={true} />{" "}
            </div>
          </>
        ) : (
          <div className="outer-container-subcomponents">
            {yesterdayLinksData && yesterdayLinksData.length ? (
              <div className="links-grid-container-subcomponents">
                {yesterdayLinksData &&
                  yesterdayLinksData.map((link: any) => (
                    <>
                      {
                        link?.product_image ? <ImagePostTag
                          data={link}
                          handleRedirect={handleShop}
                          title={title}
                          brand_color={brand_color}
                          isLinks={true}
                          isDashboard={true}
                          isHasMediaUrl={
                            link.type === "FEED" ? true : false
                          }
                        /> :
                          link.media_url ? (
                            link.media_url.split(",").length > 1 &&
                              link.media_url
                                .split(",")
                                .every((url: any) => url.trim() !== "") ? (
                              <>
                                <ImagePostCarouselTag
                                  post={link}
                                  handleRedirect={handleShop}
                                  title={title}
                                  brand_color={brand_color}
                                  isLinks={false}
                                  isDashboard={true}
                                  isHasMediaUrl={
                                    link.type === "FEED" ? true : false
                                  }
                                />
                              </>
                            ) : (
                              <>
                                {/* {link.media_url.includes("video") ? (
                              <>
                                <VideoPostTag
                                  post={link}
                                  handleRedirect={handleShop}
                                  title={title}
                                  brand_color={brand_color}
                                  isLinks={false}
                                  isDashboard={true}
                                />
                              </>
                            ) : (
                              <>
                                <ImagePostTag
                                  data={link}
                                  handleRedirect={handleShop}
                                  title={title}
                                  brand_color={brand_color}
                                  isLinks={true}
                                  isDashboard={true}
                                />
                              </>
                            )} */}
                                {link.product_image && link.product_image !== "" ? (
                                  <>
                                    <ImagePostTag
                                      data={link}
                                      handleRedirect={handleShop}
                                      title={title}
                                      brand_color={brand_color}
                                      isLinks={true}
                                      isDashboard={true}
                                      isHasMediaUrl={
                                        link.type === "FEED" ? true : false
                                      }
                                    />
                                  </>
                                ) : (
                                  <>
                                    {link.media_url.includes("video") ? (
                                      <>
                                        <VideoPostTag
                                          post={link}
                                          handleRedirect={handleShop}
                                          title={title}
                                          brand_color={brand_color}
                                          isLinks={true}
                                          isDashboard={true}
                                          thumbnail={link.thumbnail}
                                          isHasMediaUrl={
                                            link.type === "FEED" ? true : false
                                          }
                                        />
                                      </>
                                    ) : (
                                      <>
                                        <ImagePostTag
                                          data={link}
                                          handleRedirect={handleShop}
                                          title={title}
                                          brand_color={brand_color}
                                          isLinks={true}
                                          isDashboard={true}
                                        />
                                      </>
                                    )}
                                  </>
                                )}
                              </>
                            )
                          ) : (
                            <>
                              <ImagePostTag
                                data={link}
                                handleRedirect={handleShop}
                                title={title}
                                brand_color={brand_color}
                                isLinks={true}
                                isDashboard={true}
                                isHasMediaUrl={
                                  link.type === "FEED" ? true : false
                                }
                              />
                            </>
                          )}
                    </>
                  ))}
              </div>
            ) : (
              <div className="outer-container-subcomponents">
                <p>No content at this time</p>
              </div>
            )}
          </div>
        )}
      </div>

      <EditLinksWrapper
        isEdit={isEdit}
        setIsEdit={setIsEdit}
        isEditModalOpen={isEditModalOpen}
        setIsEditModalOpen={setIsEditModalOpen}
        title={title}
        refetch={refetch}
        component={component}
        setSelectedComponent={setSelectedComponent}
        selectedComponent={selectedComponent}
        refetchLinks={refetchLinks}
      />
    </>
  );
};

export default LinksWrapper;
