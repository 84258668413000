import { BsFillPlayFill, BsLink45Deg, BsX } from "react-icons/bs";
import { Post } from "../../FeedPost";
import track, {
  TRACKING_TYPES,
} from "../../../../Utils/SupabaseServices/Track";
import { useLocation } from "react-router-dom";
import "./TwitterPost.css";
import Caption from "../Caption";
import { useState } from "react";
import { Button, Modal } from "antd";
import React from "react";
import { useAuth } from "../../../../hooks/useAuth";
import { RiTwitterLine } from "react-icons/ri";
import "../CommentsPost/CommentsPost.css";
import "../PostCarousel.css";

export type TwitterPostType = Post & {
  platform: "twitter";
  metadata: {
    to: string;
    from: string;
    description: string;
    threadId: string;
  };
};

export default function TwitterPost({
  post,
  showCaption,
  shoppableContent,
  user_id,
  showAsAd
}: {
  post: TwitterPostType;
  showCaption: any;
  isGlobalView: any;
  shoppableContent: any;
  user_id:any;
  showAsAd:any
}) {
  // const { user } = useAuth();
  // const user_id = user?.id || "";

  const location = useLocation();
  const { post_date, post_id, permalink } = post;
  function handleRedirect(postId: string) {
    track("Redirect to original post", TRACKING_TYPES.EVENT, {
      pathname: location.pathname,
      details: postId,
      user_id: user_id,
    });
  }
  const RefTag = ({ showAsAd, post_date, permalink, post_id }: any) => {
    return (
      <div
        className="creator-feed-content-info creator-feed-display"
      >
        {showAsAd !== true ? (
          <>
            {new Date(post_date).getFullYear() > 2000 ? (
              <p className="creator-feed-content-date">
                {new Intl.DateTimeFormat("en-US", {
                  year: "numeric",
                  day: "numeric",
                  month: "long",
                }).format(new Date(post_date))}
              </p>
            ) : (
              ""
            )}
            <div
              className="creator-feed-post-links display-flex"
            >
              {permalink ? (
                <>
                  <a href={permalink} target="_blank" rel="noreferrer">
                    <BsLink45Deg
                      className="bs-link"
                      onMouseEnter={(e: any) => {
                        e.target.style.color = "#888";
                      }}
                      onMouseLeave={(e: any) => {
                        e.target.style.color = "#000";
                      }}
                      onClick={() => handleRedirect(post_id)}
                    />
                  </a>
                </>
              ) : (
                ""
              )}
              {permalink ? (
                <a
                  // style={{ textDecoration: "none" }}
                  href={permalink}
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    src="/assets/icons8-twitterx-30.png"
                    className="social-img"
                    onMouseEnter={(e: any) => {
                      e.target.style.color = "#888";
                    }}
                    onMouseLeave={(e: any) => {
                      e.target.style.color = "#000";
                    }}
                    onClick={() => handleRedirect(post_id)}
                  />
                </a>
              ) : (
                <img
                  src="/assets/icons8-twitterx-30.png"
                  className="insta-height"
                />
              )}
            </div>
          </>
        ) : (
          <>
            <p className="creator-feed-content-date">Sponsored</p>
            <div
              className="creator-feed-post-links display-flex"
            >
              {permalink ? (
                <a href={permalink} target="_blank" rel="noreferrer">
                  <BsLink45Deg
                    className="bs-link"
                    onMouseEnter={(e: any) => {
                      e.target.style.color = "#888";
                    }}
                    onMouseLeave={(e: any) => {
                      e.target.style.color = "#000";
                    }}
                    onClick={() => handleRedirect(post_id)}
                  />
                </a>
              ) : (
                ""
              )}
            </div>
          </>
        )}
      </div>
    );
  };
  const convertToClickable = (text: string) => {
    const urlPattern = /(https?:\/\/\S+)/g;

    const phonePattern = /\b\d{10}\b/g;

    const processedPhoneNumbers = new Set<string>();

    const textWithUrls = text?.replace(
      urlPattern,
      (url, index) =>
        `<a key=${index} href="${url}" target="_blank" rel="noopener noreferrer">${url}</a>`
    );

    const textWithClickablePhoneNumbers = textWithUrls?.replace(
      phonePattern,
      (phoneNumber, index) => {
        if (!processedPhoneNumbers.has(phoneNumber)) {
          processedPhoneNumbers.add(phoneNumber);
          return `<a key=${index} href="tel:${phoneNumber}">${phoneNumber}</a>`;
        } else {
          return phoneNumber;
        }
      }
    );

    return React.createElement("div", {
      dangerouslySetInnerHTML: { __html: textWithClickablePhoneNumbers },
    });
  };
  return (
    <div>
      <div className="twitter-post-img-wrp">
        {post.caption ? (
          <div className="post-comment">
            <p
              className="post-caption"
            >
              {convertToClickable(post.caption)}
            </p>
          </div>
        ) : (
          <>
            <div className="twitter-post-img">
              <div className="twitter-post-img-date">
                <span className="date">
                  {new Intl.DateTimeFormat("en-US", {
                    year: "numeric",
                    day: "numeric",
                    month: "long",
                  }).format(new Date(post_date))}
                </span>
                <br />
                <span className="twitter-label">Twitter</span>
              </div>
            </div>
          </>
        )}
      </div>

      <RefTag
        showAsAd={showAsAd}
        post_date={post_date}
        permalink={permalink}
        post_id={post_id}
      />
    </div>
  );
}
