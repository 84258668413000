import { useEffect, useState } from "react";
import {  useLocation, useParams } from "react-router-dom";
import Header from "../../components/Header/header";
import ImageSidebar from "../../components/Header/ImageSidebar";
import { TabTitle } from "../../Utils/GeneralFunctions";
import NoUser from "../../ErrorPages/NoUser";
import track, { TRACKING_TYPES } from "../../Utils/SupabaseServices/Track";
import CopyToClipboard from "../../components/CopyToClipboard/CopyToClipboard";
import ProfileSkeleton from "../../components/skeleton/Views/ProfileSkeleton";
import FeedControls from "../../components/FeedPage/FeedControls/FeedControls";
import { useLoadCompletePage } from "../../Utils/customHooks/useLoadCompletePage";

const FollowerView = () => {
  const { username } = useParams();
  const page = "follower";

  const location = useLocation();

  const { search } = location;
  const queryParams = new URLSearchParams(search);

  const utmSource = queryParams.get("utm_source");
  if (utmSource) {
    track(location.pathname, TRACKING_TYPES.PAGE, {
      pathname: location.pathname,
      details: { source: username },
      // user_id: "",
    });
  }

  const indexPages: any = location?.state?.indexPages;

  if (location.state) {
    TabTitle("Follower View • Bubble");
  } else {
    TabTitle(`${username} • Bubble `);
  }

  const { isLoading, data, error }: any = useLoadCompletePage(username, page);
  useEffect(() => {
    const route = location.pathname;
    let title: any;
    if (route.includes("follower")) {
      // updateFollowerCount(username);
      title = "Follower";
    } else {
      // updateLiveCount(username);
      title = "Live";
    }
    if (!isLoading) {
      track(location.pathname, TRACKING_TYPES.PAGE, {
        pathname: location.pathname,
        title: title,
        user_id: data?.combinedRes?.userData?.user_id,
      });
    }
  }, [isLoading, data?.combinedRes]);
  return (
    <>
      {isLoading ? (
        // <></>
        <ProfileSkeleton />
      ) : data?.combinedRes?.userData?.name && data?.combinedRes?.userData?.user_id && username ? (
        <>
          <div id="app-wrapper" className="inex-1">
            {location.pathname.includes("/follower") ? (
              <>
                <Header
                  extraComponent={
                    <div
                      className="header-component"
                    >
                      <CopyToClipboard
                        textToCopy={`https://www.searchbubble.com/${username}`}
                      />
                      <ImageSidebar
                        username={username as string}
                        isFollower={true}
                        pictureUrl={data?.combinedRes?.userData?.picture}
                      />
                    </div>
                  }
                />
              </>
            ) : (
              ""
              // <LiveMenu username={username as string} />
            )}
          </div>

          <FeedControls
            indexPages={indexPages}
            userDetails={data?.combinedRes?.userData}
            username={username}
            page="follower"
            name={data?.combinedRes?.userData?.name}
            userId={data?.combinedRes?.userData?.user_id}
            data={data?.combinedRes}
            hashtagFilters={data?.hashtagFilterArray}
            avgWords={data?.combinedRes?.userData?.avg_words}
            liveView={location.pathname.includes("/follower") ? false : true}
          />
        </>
      ) : (
        <NoUser />
      )}
    </>
  );
};

export default FollowerView;
