import React, { useEffect, useState, Fragment } from "react";
import { Button, Carousel, Skeleton, Input } from "antd";
import { HiCheck, HiPencil } from "react-icons/hi";

import VideoComponent from "../VideoComponent/VideoComponent";
import { supabase } from "../../config/supabaseClient";
import { getPostTitle } from "../../Utils/HelperFunction/HelperFunction";
import { UpdateCombinedText } from "../../Utils/SupabaseServices/ContentDatabase";

interface Post {
  table_id: any;
  table_image?: any;
  thumbnail?: any;
  table_name?: any;
  shop_title?: any;
}

interface AddedContentTableProps {

  username: any;
  user_id: any;
  posts: Post[];
  setAllPosts: any;
  componentName: any;
  handleFeed: (posts: Post[]) => void;
  refetch: () => void;
  setRemovePosts: any;
  setPostIds: any;
  setIsChange: any;
}

const AddedContentTable = ({
  // defaultPosts,
  username,
  user_id,
  posts,
  setAllPosts,
  componentName,
  handleFeed,
  refetch,
  setRemovePosts,
  setPostIds,
  setIsChange,initial
}:any) => {
  const [checkedValues, setCheckedValues] = useState<Post[]>([]);
  const [value, setValue] = useState<string>("");
  const [edit, setEdit] = useState(false);
  const [editIndex, setEditIndex] = useState<number | null>(null);
  useEffect(() => {
    setCheckedValues(posts);
  }, [posts,initial]);

  const onMinus = (post_id: string) => {
    const updatedPostArray = checkedValues.filter((post) => post.table_id !== post_id);
    setRemovePosts((prev: any) => [...(prev || []), checkedValues.find((post) => post.table_id === post_id)!]);
    setPostIds(updatedPostArray);
    setCheckedValues(updatedPostArray);
    handleFeed(updatedPostArray);
    setAllPosts(updatedPostArray);
  };

  const handleDone = async (post_id: string) => {
    const { data: user, error } = await supabase
      .from("product_bank")
      .select("*")
      .eq("product_id", post_id)
      .eq("user_id", user_id);

    await supabase
      .from("product_bank")
      .update({ shop_title: value })
      .eq("product_id", post_id)
      .eq("user_id", user_id);
      await supabase
      .from(`${username}`)
      .update({ shop_title: value,caption:value,product_name:value })
      .eq("post_id", post_id)
      .eq("user_id", user_id);
      UpdateCombinedText(
        username,
        user_id,
        post_id
      )
    // setIsChange(true)
    const postData = checkedValues.find((post) => post.table_id === post_id);
    if (postData) {
      postData.shop_title = value;
      setEdit(false);
      setEditIndex(null);
    }
    refetch()

  };
  const SkeletonStatBox = () => (
    <div className="skeletonDiv">
      <Skeleton className="skeletonStyle" />
    </div>
  );
  return (
    <div className="addedContent">
      <h5 className="addedContentTitle">{componentName || "Carousel Title"}</h5>
      <div className="addedSvg">
        {checkedValues && checkedValues.length > 0 ? (
          checkedValues.map((post, index) => (
            <Fragment key={index}>
              <div className="feed-tab-post-container added-post">
                <div className="feed-tab-media-wrapper">
                  <Button onClick={() => onMinus(post.table_id)} className="carousel-content-remove-btn">
                    Remove
                  </Button>
                  {post.table_image ? (
                    post.table_image.includes(",") ? (
                      <Carousel dotPosition="bottom" className="feed-tab-media-carousel">
                        {post.table_image.split(",").map((media_url: any, idx: any) => (
                          <Fragment key={idx}>
                            {media_url.includes("video") ? (
                              <div className="h-255">
                                <VideoComponent
                                  media_url={media_url}
                                  thumbnail={
                                    post.thumbnail?.split(",")[idx] || "/assets/dummy_video.png"
                                  }
                                />
                              </div>
                            ) : (
                              <img className="feed-tab-media-check" src={media_url} alt="post" />
                            )}
                          </Fragment>
                        ))}
                      </Carousel>
                    ) : post.table_image.includes("video") ? (
                      <div className="h-255">
                        <VideoComponent media_url={post.table_image} thumbnail={post.thumbnail || "/assets/dummy_video.png"} />
                      </div>
                    ) : (
                      <img className="feed-tab-media-check" src={post.table_image} alt="post" />
                    )
                  ) : (
                    <img className="feed-tab-media-check" src={post.table_image} alt="post" />
                 )} 
                </div>
                {edit && editIndex === index ? (
                  <>
                    <Input value={value} onChange={(e) => setValue(e.target.value)} />
                    <HiCheck onClick={() => handleDone(post.table_id)} />
                  </>
                ) : (
                  <p style={{ textAlign: post.table_name ? "left" : "center" }}>
                    {post?.shop_title ? post?.shop_title : post?.table_name}{" "}
                    <HiPencil
                      onClick={() => {
                        setValue(post?.shop_title ? post?.shop_title : post?.table_name);
                        setEdit(true);
                        setEditIndex(index);
                      }}
                    />
                  </p>
                )}
              </div>
            </Fragment>
          ))
        ) : (
          <div className="feed-tab-post">
            {[...Array(4)].map((_, idx) => (
              <SkeletonStatBox key={idx} />
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default AddedContentTable;
