import { Button, Input, List, Modal } from "antd";
import React, { useEffect, useState } from "react";
import "../../StayUpToDateControls.css";
import { updateComponent } from "../../../../../Utils/SupabaseServices/StayUpToDateDatabase";
import EditFeedComponent from "./EditFeedComponent";

const EditNewContent = (props: any) => {
  const {
    username,
    user_id,
    isContentEditModalOpen,
    type,
    component,
    refetch,
    initial,
    setInitial,
  } = props;
  const [name, setName] = useState(component.name || "");
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const [postIds, setPostIds] = useState("");
  const [errorMsg, setErrorMsg] = useState("");
  const [defaultQuery, setDefaultQuery] = useState("");
  const [isChange, setIsChange] = useState(false);
  const [activeFilter, setActiveFilter] = useState("both");

  useEffect(() => {
    // if (isContentEditModalOpen) {
    setInitial((prev: any) => !prev);
    // }
  }, [isContentEditModalOpen]);
  useEffect(() => {
    setName(component?.name);
    setPostIds(component?.post_ids);
    setDefaultQuery(component?.query);
  }, [component]);

  const handleInputChange = (value: string, setter: Function) => {
    setter(value);
    setIsButtonDisabled(!value);
  };
  const handleOk = async () => {
    setConfirmLoading(true);
    const res = await updateComponent(
      name,
      component,
      username,
      user_id,
      defaultQuery,
      "content",
      postIds.toString(),
      ""
    );
    if (res === "true") {
      props.refetch();

      props.setIsContentEditModalOpen(false);
      props.setSelectedComponent("");
      setConfirmLoading(false);
      setInitial((prev: any) => !prev);

      // props.setIsCustomContentComponentOpen(false);
      setName("");
      setPostIds("");
      setErrorMsg("");
      setDefaultQuery("");
      setIsChange(false);
      setActiveFilter("both")

    } else {
      setErrorMsg("Category with this name already exist.");
      setConfirmLoading(false);
    }
    props.refetch();
  };

  const handleCancel = () => {
    setInitial((prev: any) => !prev);

    props.setIsContentEditModalOpen(false);
    props.setSelectedComponent("");
    props.refetch();
    setName("");
    setPostIds("");
    setErrorMsg("");
    setDefaultQuery("");
    setActiveFilter("both")

  };
  function handlePostIds(post_ids: any) {
    setPostIds(post_ids);
  }
  return (
    <>
      <Modal
        open={isContentEditModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        okText="Update"
        confirmLoading={confirmLoading}
        okButtonProps={{ disabled: !name }}
        data-testid="edit-content-carousel"
      >
        <div>
          <h5>Editing {name}</h5>
          <label className="bold-label">Carousel Title</label>
          <Input
            placeholder="Enter Title"
            value={name}
            onChange={(e: any) => {
              handleInputChange(e.target.value, setName);
              setErrorMsg("");
            }}
            id="edit-carousel-title"
            className="mb-20"
          />
          {errorMsg && <p className="errorMsg">{errorMsg}</p>}

          <EditFeedComponent
            username={username}
            user_id={user_id}
            component={component}
            defaultQuery={defaultQuery}
            initial={initial}
            setInitial={setInitial}
            isModalOpen={isContentEditModalOpen} // Pass modal open state here
            handleFeed={handlePostIds}
            type="content"
            activeFilter={activeFilter}
            setActiveFilter={setActiveFilter}
            setDefaultQuery={setDefaultQuery}
          />
        </div>
        {/* <p>Your component will be ready within 24 hours!</p> */}
      </Modal>
    </>
  );
};

export default EditNewContent;
