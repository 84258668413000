import { BsFillPlayFill, BsLink45Deg, BsPinterest } from "react-icons/bs";
import { Post } from "../../FeedPost";
import track, {
  TRACKING_TYPES,
} from "../../../../Utils/SupabaseServices/Track";
import { useLocation } from "react-router-dom";
import "./ProductPost.css";
import Caption from "../Caption";
import CreatorFeedContentInfo from "../../CreatorFeedInfoComponent";
import { FaAmazon } from "react-icons/fa";
import { StaticCompnents } from "../../../../Utils/StaticComponent";

export type ProductPostType = Post & {
  username: string;
  showAsAd: any;
  platform: "pinterest";
  metadata: {
    description: string;
  };
  user_id:any
};

export default function ProductPost({
  post,
  showCaption,
  shoppableContent,
  username,
  isGlobalView,
  user_id,showAsAd
}: // showAsAd
{
  post: ProductPostType;
  showCaption: any;
  isGlobalView: any;
  shoppableContent: any;
  username: string;
  showAsAd: any;
  user_id:any
}) {
  const location = useLocation();
  const {
    // showAsAd,
    post_date,
    post_id,
    permalink,
    shop_link,
    media_url,
    tagged_link,
    caption,
    platform,
  } = post;
  function handleRedirect(postId: string) {
    track("Redirect to original post", TRACKING_TYPES.EVENT, {
      pathname: location.pathname,
      details: postId,
    });
  }
  const handleShop = (postId: any, mediaUrl: any,user_id:any) => {
    track("Redirect to shop link", TRACKING_TYPES.EVENT, {
      pathname: window.location.pathname,
      details: { post_id: postId, media_url: mediaUrl },
      user_id: user_id,
    });
  };
  const platformIcons: any = {
    ShopMy: StaticCompnents().ShopMyIcon,
    Amazon: StaticCompnents().AmazonIcon,
    LTK: StaticCompnents().LTKIcon,
    // Add other platforms here
  };
  const PlatformIcon = platformIcons[platform] || ""; // Default to Instagram icon if platform is not found
  return (
    <div>
      <div className="product-post-img-wrp">
        <img
          src={media_url}
          alt={caption}
          className="product-post-img"
        />
      </div>

      <CreatorFeedContentInfo
        post_date={post_date}
        permalink={permalink}
        shop_link={shop_link}
        tagged_link={tagged_link}
        post_id={post_id}
        media_url={media_url}
        shoppableContent={shoppableContent}
        isMobile={false}
        isGlobalView={isGlobalView}
        username={username}
        showAsAd={showAsAd}
        PlatformIcon={PlatformIcon}
        showCaption={showCaption}
        user_id={user_id}
      />
     {showCaption   && caption && <Caption caption={caption} />}
    </div>
  );
}
