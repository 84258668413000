import React, { useState } from "react";
import { BsLink45Deg, BsFillCheckCircleFill } from "react-icons/bs";
import { Tooltip } from "antd"; // Use any tooltip library or your custom tooltip component.
import { isNavigable } from "../../Utils/StayUpToDate/RenderComponent"; // Update the path to the correct file where isNavigable is defined

const CopyComponent = (props: any) => {
  const {
    username,
    user_id,
    component,
    buttonText = "Copy Link",
    description = "Click to copy the link for this item",
  } = props;
  const [copied, setCopied] = useState(false);

  // Function to handle the copy action
  const copyToClipboard = () => {
    const url = `https://searchbubble.com/${username}/${component.unique_id}`; // Replace with your actual URL logic
    navigator.clipboard
      .writeText(url)
      .then(() => {
        setCopied(true);
        setTimeout(() => setCopied(false), 2000); // Reset tooltip after 2 seconds
      })
      .catch((err) => {
        console.error("Failed to copy URL: ", err);
      });
  };

  // Check if the component is navigable
  const canNavigate = isNavigable(component);

  if (!canNavigate) {
    return null; // Don't render anything if not navigable
  }

  return (
    <Tooltip
      placement="bottom"
      open={copied}
      title={
        copied ? (
          <div className="tooltip-style">
            <BsFillCheckCircleFill />
            <p className="m-0">Copied!</p>
          </div>
        ) : (
          <p className="m-0">{description}</p>
        )
      }
    >
      <div
        className="copy-clipboard-btn-wrapper"
        onClick={copyToClipboard}
        id="copy-link"
        style={{
          display: "inline-flex",
          alignItems: "center",
          cursor: "pointer",
        }}
      >
        <BsLink45Deg size={18} />
      </div>
    </Tooltip>
  );
};

export default CopyComponent;
