import React, {
  useEffect,
  useState,
  useRef,
  useCallback,
  useLayoutEffect,
} from "react";
import { debounce } from "lodash";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import { isMobile } from "react-device-detect";
import { isNavigable } from "../../../Utils/StayUpToDate/RenderComponent";
import track, { TRACKING_TYPES } from "../../../Utils/SupabaseServices/Track";
import DataSkeleton from "../UpToDateComponents/DataSkeleton";
import RenderIndividualUI from "./RenderIndividualUI";
import { useLoadTypeContentData } from "../../../Utils/customHooks/UpToDateContent/useLoadTypeContent";
import { useDomLoaded } from "../../../hooks/DomLoadedContext";

const YesterdayIndividualLinks = (props: any) => {
  const itemsPerPage = isMobile ? 1.5 : 3;
  const {
    user_id,
    username,
    numberOfDays,
    title,
    isLinks,
    handleShop,
    postIds,
    brand_color,
    removedPostIds,
    isHandleNavigate,
    handleRedirect,
    isLoading,
    data,
    component,
    platform,
    type,
    componentType,
  } = props;
  const [isLocalLoading, setLocalLoading] = useState(true);
  const navigate = useNavigate();
  const location = useLocation();
  const domLoaded = useDomLoaded();

  const [allData, setAllData] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const containerRef = useRef<any>(null);
  const isButtonClicked = useRef(false);

  const { isLoading: ReelsDataLoading, data: ReelsData }: any =
    useLoadTypeContentData(username, user_id, platform, type);

  useEffect(() => {
    // Simulate loading time and set data
    setLocalLoading(true);
    const timer = setTimeout(() => {
      setAllData(ReelsData && ReelsData.length > 0 ? ReelsData : data);
      setLocalLoading(false);
    }, 1000); // Adjust the delay as needed

    return () => clearTimeout(timer); // Clean up timer
  }, [ReelsData, data]);

  const handleScroll = useCallback(
    debounce(() => {
      if (!isButtonClicked.current) {
        if (containerRef.current) {
          const scrollLeft = containerRef.current.scrollLeft;
          const itemWidth = containerRef.current.scrollWidth / data.length;
          const newCurrentIndex = Math.round(scrollLeft / itemWidth);
          setCurrentIndex(newCurrentIndex);
        }
      }
    }, 100),
    [data]
  );
  useEffect(() => {
    if (containerRef.current) {
      const itemWidth = containerRef.current.scrollWidth / data.length;
      const scrollPosition = itemWidth * currentIndex;
      containerRef.current.scrollTo({
        left: scrollPosition,
        behavior: "smooth",
      });
    }
  }, [currentIndex, data]);

  useEffect(() => {
    if (containerRef.current) {
      containerRef.current.addEventListener("scroll", handleScroll);
      return () => {
        if (containerRef.current) {
          containerRef.current.removeEventListener("scroll", handleScroll);
        }
      };
    }
  }, [data, handleScroll]);

  const handlePrevPage = () => {
    isButtonClicked.current = true;
    setCurrentIndex((prevIndex) => Math.max(prevIndex - 1, 0));
    setTimeout(() => {
      isButtonClicked.current = false;
    }, 500);
  };

  const handleNextPage = () => {
    isButtonClicked.current = true;
    setCurrentIndex((prevIndex) =>
      Math.min(prevIndex + 1, data.length - itemsPerPage)
    );
    setTimeout(() => {
      isButtonClicked.current = false;
    }, 500);
  };

  const handleNavigate = (component: any) => {
    if (isNavigable(component)) {
      navigate(`/${username}/${component.unique_id}`, {
        state: { data: component },
      });
    }
  };

  return (
    <>
      {isLocalLoading || !domLoaded ? (
        <DataSkeleton />
      ) : (
        <>
          <RenderIndividualUI
            data={allData}
            currentIndex={currentIndex}
            handleNavigate={handleNavigate}
            component={component}
            handleNextPage={handleNextPage}
            handlePrevPage={handlePrevPage}
            containerRef={containerRef}
            handleShop={
              handleRedirect == undefined ? handleShop : handleRedirect
            }
            brand_color={brand_color}
            title={title}
            type={type}
            isLinks={isLinks}
            componentType={componentType}
          />
        </>
      )}
    </>
  );
};

export default YesterdayIndividualLinks;
