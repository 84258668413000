import { Button } from "antd";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import track, {
  TRACKING_TYPES,
} from "../../../../../Utils/SupabaseServices/Track";
import { updateRefClick } from "../../../../../Utils/SupabaseServices/ContentDatabase";
import { BannerSkeletonTemplateWrapper } from "../BannerSkeletonTemplateWrapper";

const HighlightWrapper = (props: any) => {
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, []);
  const { username, user_id, data, brand_color, cta } = props;
  const location = useLocation();

  const details = data?.details;
  let view = "";
  if (details) {
    const parts = details.split(",");

    const lastPart = parts[parts.length - 1]?.trim();
    if (lastPart === "left" || lastPart === "right") {
      view = lastPart;
    }
  }
  function handleRedirect(postId: string, media_url: string) {
    track("Redirect to original post", TRACKING_TYPES.EVENT, {
      pathname: location.pathname,
      details: { post_id: postId, media_url: media_url },
      user_id: user_id,
      title: "Stay Up To Date",
    });
    updateRefClick(postId, user_id);
  }

  function handleShop(postId: string, media_url: string) {
    track("Redirect to shop link", TRACKING_TYPES.EVENT, {
      pathname: location.pathname,
      details: { post_id: postId, media_url: media_url },
      user_id: user_id,
      title: "Stay Up To Date",
    });
    // updateRefClick(postId, user_id);
  }
  return (
    <>
      {loading ? (
        <BannerSkeletonTemplateWrapper />
      ) : (
        <div
          className="outter-section-subcomponent"
          style={{
            backgroundColor: brand_color && `${brand_color}`,
          }}
        >
          <a
            href={
              data?.details?.split(",")[1]?.startsWith("http")
                ? data.details.split(",")[1]
                : `http://${data.details.split(",")[1]}`
            }
            className="highlight-link-tag"
            target="_blank"
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation(); // Add this line to stop the event propagation

              const url = data?.details?.split(",")[1];
              if (url && url !== "") {
                const validUrl = url.startsWith("http") ? url : `http://${url}`;
                window.open(validUrl, "_blank");
              }
              // window.open(data.details.split(",")[1], "_blank");
            }}
          >
            <div
              className="highlight-data-section-subcomponent"
              style={
                data && data.details && data.details.split(",")[0]
                  ? view === "right"
                    ? { flexDirection: "row-reverse" }
                    : {}
                  : { minHeight: "250px", display: "flex" }
              }
            >
              {data && data.details && data.details.split(",")[0] && (
                <div
                  className="highlight-media-subcomponent"
                  style={
                    view === "right"
                      ? { marginLeft: "3%", marginRight: "0" }
                      : { marginRight: "3%" }
                  }
                >
                  {data && data.details ? (
                    <>
                      {data.details.split(",")[0].includes("video") ? (
                        <video
                          controls
                          className="highlight-video-subcomponent"
                        >
                          <source
                            src={data.details.split(",")[0]}
                            type="video/mp4"
                          />
                          Your browser does not support the video tag.
                        </video>
                      ) : (
                        <img
                          src={data.details.split(",")[0]}
                          alt="Media"
                          className="highlight-image-subcomponent"
                        />
                      )}
                    </>
                  ) : (
                    <span>No media available</span>
                  )}
                </div>
              )}
              <div
                className="highlight-details-subcomponent"
                style={
                  data && data.details && data.details.split(",")[0]
                    ? {}
                    : { width: "80%" }
                }
              >
                <div className="highlight-name-subcomponent">{data.name}</div>

                <div className="text-center">
                  <Button className="highlight-shop-button-subcomponent">
                    {cta}
                  </Button>
                </div>
                {/* </a> */}
              </div>
            </div>
          </a>
        </div>
      )}
    </>
  );
};

export default HighlightWrapper;
