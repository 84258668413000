import { Input, Modal } from "antd";
import { useEffect, useState } from "react";
import "../../StayUpToDateControls.css";
import { updateComponent } from "../../../../../Utils/SupabaseServices/StayUpToDateDatabase";
import EditProductBankCheckboxComponent from "./EditProductBankCheckBoxComponent";
import { useLoadCustomProduct } from "../../../../../Utils/customHooks/UpToDateContent/useLoadCustomProduct";
import {
  removeProductBankQuery,
  updateProductBankQuery,
} from "../../../../../Utils/SupabaseServices/ProductBankDatabase";

const EditProductBank = ({
  username,
  user_id,
  isProductBankEditModalOpen,
  setIsProductBankEditModalOpen,
  type,
  component,
  refetch,
  setSelectedComponent,
  initial,
  setInitial,
}: any) => {
  const [name, setName] = useState(component.name || "");
  const [postIds, setPostIds] = useState("");
  const [removePosts, setRemovePosts] = useState([]);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [defaultQuery, setDefaultQuery] = useState("");
  const [defaultAllPosts, setDefaultAllPosts] = useState([]);
  const [isChange, setIsChange] = useState(false);
  useEffect(() => {
    if (isProductBankEditModalOpen) {
      setInitial((prev: any) => !prev);
    }
  }, [isProductBankEditModalOpen]);
  const {
    isLoading: CustomProductLoading,
    data: CustomProduct,
    refetch: refetchCustomProduct,
  } = useLoadCustomProduct(user_id, username, component?.name, isChange);

  useEffect(() => {
    setName(component?.name || "");
    setPostIds(component?.post_ids || "");
    setDefaultQuery(component?.query || "");
  }, [component]);

  useEffect(() => {
    setDefaultAllPosts(CustomProduct);
  }, [CustomProduct]);

  const handleInputChange = (value: any, setter: any) => {
    setter(value);
    setErrorMsg("");
  };

  const handleOk = async () => {
    setConfirmLoading(true);
    const tableIds = removePosts.map((item: any) => item.table_id);

    if (removePosts.length > 0) {
      await Promise.all(
        removePosts.map((post: any) =>
          removeProductBankQuery(post.table_id, user_id, post, component?.name)
        )
      );
    }

    await updateProductBankQuery(postIds, user_id, component?.name,username);

    const res = await updateComponent(
      name,
      component,
      username,
      user_id,
      defaultQuery,
      "product-bank",
      "",
      "",
      tableIds.toString()
    );
    if (res === "true") {
      refetch();
      refetchCustomProduct();
      resetForm();
      setInitial((prev: any) => !prev);
    } else {
      setErrorMsg("Category with this name already exists");
    }

    setIsChange(false);
    setConfirmLoading(false);
  };

  const handleCancel = () => {
    setInitial((prev: any) => !prev);

    refetch();
    refetchCustomProduct();
    resetForm();
  };

  const resetForm = () => {
    setName("");
    setPostIds("");
    setErrorMsg("");
    setRemovePosts([]);
    setDefaultQuery("");
    setDefaultAllPosts([]);
    setSelectedComponent("");
    setInitial((prev: any) => !prev);

    setIsProductBankEditModalOpen(false);
  };

  return (
    <Modal
      open={isProductBankEditModalOpen}
      onOk={handleOk}
      onCancel={handleCancel}
      okText="Update"
      confirmLoading={confirmLoading}
      okButtonProps={{ disabled: !name }}
    >
      <div className="mt-1">
        <h5 className="editTitle">Editing {name}</h5>
        <Input
          placeholder="Enter Carousel Title"
          value={name}
          onChange={(e) => handleInputChange(e.target.value, setName)}
          className="mb-15"
        />
        {errorMsg && <p className="errorMsg">{errorMsg}</p>}
        <EditProductBankCheckboxComponent
          username={username}
          user_id={user_id}
          handlePostIds={setPostIds}
          className="ant-checkbox-wrapper"
          componentName={name}
          defaultQuery={defaultQuery}
          setDefaultQuery={setDefaultQuery}
          defaultAllPosts={defaultAllPosts}
          refetchCustomProduct={refetch}
          setRemovePosts={setRemovePosts}
          removePosts={removePosts}
          setIsChange={setIsChange}
          isChange={isChange}
          initial={initial}
          setInitial={setInitial}
        />
      </div>
    </Modal>
  );
};

export default EditProductBank;
