import { useEffect, useState } from "react";
import { useAuth } from "../../hooks/useAuth";
import { Modal, Spin } from "antd";
import "./IntegrationsBox.css";
import { useLoadIntegrations } from "./useLoadIntegrations";
import IntegrationPlatforms from "./IntegrationPlatforms";
import {
  updatePlatformToggle,
} from "../../Utils/SupabaseServices/UserDatabase";

import YoutubeIntegrationModal from "./PlatformIntegrationModal/YoutubeIntegrationModal";
import InstagramIntegrationModal from "./PlatformIntegrationModal/InstagramIntegrationModal";
import PodcastIntegrationModal from "./PlatformIntegrationModal/PodcastIntegrationModal";
import NewsletterIntegrationModal from "./PlatformIntegrationModal/NewsletterIntegrationModal";
import TiktokIntegrationModal from "./PlatformIntegrationModal/TiktokIntegrationModal";
import PinterestIntegrationModal from "./PlatformIntegrationModal/PinterestIntegrationModal";
import TwitterIntegrationModal from "./PlatformIntegrationModal/TwitterIntegrationModal";
import FlowdeskIntegrationModal from "./PlatformIntegrationModal/FlowdeskIntegrationModal";
import FlowdeskPopupIntegrationModal from "./PlatformIntegrationModal/FlowdeskPopupIntegrationModal";
import FilloutIntegrationModal from "./PlatformIntegrationModal/FilloutIntegrationModal";
import LinkedInModal from "./PlatformIntegrationModal/LinkedInModal";
// import PlatformModals from "./PlatformModals";

export default function IntegrationsBox({ subscription_type, first_connected_platform }: any) {
  const { user } = useAuth();
  const { isLoading, isFetching, data, refetch } = useLoadIntegrations(user?.id as string);

  const [modalState, setModalState] = useState({
    currentPlatform: "",
    isVisible: false,
  });

  const [platformToggles, setPlatformToggles] = useState<Record<string, boolean>>({});
  const [showConfirmation, setShowConfirmation] = useState({
    platformKey: "",
    platformToggles: false
  });

  const platforms = [
    { name: "Instagram", key: "instagram", modalKey: "InstagramModal" },
    { name: "Youtube", key: "youtube", modalKey: "YoutubeModal" },
    { name: "Podcast", key: "podcast", modalKey: "PodcastModal" },
    { name: "Newsletter", key: "newsletter", modalKey: "NewsletterModal" },
    { name: "Tiktok", key: "tiktok", modalKey: "TiktokModal" },
    // { name: "Pinterest", key: "pinterest", modalKey: "PinterestModal" },
    // { name: "Twitter", key: "twitter", modalKey: "TwitterModal" },
     { name: "LinkedIn", key: "linkedin", modalKey: "LinkedInModal" },
    { name: "Flodesk Inline", key: "flowdesk", modalKey: "FlowdeskModal" },
    { name: "Flodesk Popup", key: "flowdeskpopup", modalKey: "FlowdeskPopupModal" },
    { name: "Fillout", key: "fillout", modalKey: "FilloutModal" },
  ];

  const handleToggle = async (platformKey: string, checked: boolean) => {
    // setPlatformToggles((prevState) => ({
    //   ...prevState,
    //   [platformKey]: checked,
    // }));
    // await updatePlatformToggle(user?.id, platformKey, checked);
    if (checked == false) {
      setShowConfirmation({
        platformKey,
        platformToggles: !checked
      })
    } else {
      handleOffPlatformToggle(platformKey, checked)
    }

    // refetch();
  };



  const handleOffCancel = () => {
    setShowConfirmation({
      platformToggles: false,
      platformKey: "",
    })
  };

  const handleOffPlatformToggle = async (platformKey: string, checked: boolean) => {
    setPlatformToggles((prevState) => ({
      ...prevState,
      [platformKey]: checked,
    }));
    setShowConfirmation({
      platformToggles: false,
      platformKey: "",
    })
    await updatePlatformToggle(user?.id, platformKey, checked);
  }

  const handleModalOpen = (platformKey: string) => {
    setModalState({ currentPlatform: platformKey, isVisible: true });
  };

  const handleModalClose = () => {
    setModalState({ currentPlatform: "", isVisible: false });
  };

  useEffect(() => {
    if (data) {
      const initialToggles = platforms.reduce((acc, platform) => {
        acc[platform.key] = Boolean(data[`is_${platform.key}_on`]);
        return acc;
      }, {} as Record<string, boolean>);
      setPlatformToggles(initialToggles);
    }
  }, [data]);

  const handleConnected = (platformKey: string) => {
    switch (platformKey) {
      case "tiktok":
      case "twitter":
      case "fillout":
      case "pinterest":
      case "flowdesk":
      case "flowdeskpopup":
        return `${platformKey}_user`;

      case "instagram":
        return "instagram_username";

      case "youtube":
        return "youtube_channel_id";

      case "podcast":
        return "podcast_link";

      case "newsletter":
        return "newsletter_email";

      default:
        return `${platformKey}_user`;
    }
  };

  const platformNames = platforms.find((platform) => platform.key === showConfirmation.platformKey)?.name|| 'this platform';


  return (
    <>
      <div className="account-social-content-connect">
        {isLoading || isFetching ? (
          <div className="spin-integration">
            <Spin />
          </div>
        ) : (
          <>
            {platforms.map(({ name, key, modalKey }) => {
              const isConnected = handleConnected(key)
              return (
                <IntegrationPlatforms
                  key={key}
                  platformName={name}
                  isConnected={Boolean(data?.[`${isConnected}`])}
                  isProcessing={Boolean(data?.[`show_${key}`])}
                  // isPlatformEnabled={Boolean(data?.[`is_${key}_on`])}
                  isPlatformEnabled={platformToggles[key]}
                  onTogglePlatform={(checked: any) => handleToggle(key, checked)}
                  onConnectPlatform={() => handleModalOpen(modalKey)}
                  subscription_type={subscription_type}
                  first_connected_platform={first_connected_platform}
                />
              )
            })}
          </>)}
      </div>
      <PlatformModals
        modalState={modalState}
        onClose={handleModalClose}
        userId={user?.id}
        data={data}
        refetch={refetch}
      />
      <Modal
        title={ ""}
        open={showConfirmation.platformToggles}
        onOk={async () => handleOffPlatformToggle(showConfirmation.platformKey, false)}
        okText="Confirm"
        onCancel={handleOffCancel}
        data-testid="turn-off-integration"
      >
        <p className="unlock-text">
          {`Are you sure you want to turn off the ${platformNames}?`}
        </p>
      </Modal>

    </>
  );
}


const PlatformModals = ({ modalState, onClose, userId, data, refetch }: any) => {
  const { currentPlatform, isVisible } = modalState;

  const modalMap: any = {
    InstagramModal: InstagramIntegrationModal,
    YoutubeModal: YoutubeIntegrationModal,
    PodcastModal: PodcastIntegrationModal,
    NewsletterModal: NewsletterIntegrationModal,
    TiktokModal: TiktokIntegrationModal,
    PinterestModal: PinterestIntegrationModal,
    TwitterModal: TwitterIntegrationModal,
    FlowdeskModal: FlowdeskIntegrationModal,
    FlowdeskPopupModal: FlowdeskPopupIntegrationModal,
    FilloutModal: FilloutIntegrationModal,
    LinkedInModal: LinkedInModal
  };

  const SelectedModal = modalMap[currentPlatform];
  const props = {
    [`show${currentPlatform}`]: { isVisible }
  }
  return SelectedModal ? (
    <SelectedModal
      {...props}
      onClose={onClose}
      user_id={userId}
      data={data}
      refetchData={refetch}
    />
  ) : null;
};

